import { Alert, Button } from "@mui/material";

const PayoutResultComponent = ({ payoutResult }) => {

    const handleViewBill = () => {
        let url = `https://app.qbo.intuit.com/app/bill?txnId=${payoutResult.payout.qboBillId}`;
        window.open(url, "_blank");
    }

    console.log(payoutResult);

    if (payoutResult.status === "error") {
        return (
            <Alert severity="error">
                {payoutResult.message}
            </Alert>
        );
    }

    if (payoutResult.status === "success") {
        if (payoutResult.payout.recipient.paymentType === "Check") {
            return (
                <Alert
                    severity="success"
                    action={
                        <Button color="inherit" size="small" onClick={handleViewBill}>
                            View Bill
                        </Button>
                    }
                >
                    Payout created for {payoutResult.payout.recipient.recipientName}
                </Alert>
            );
        } else if (payoutResult.payout.recipient.paymentType === "Inksoft Gift Card") {
            return (
                <Alert
                    severity="success"
                >
                    Payout created for {payoutResult.payout.recipient.recipientName}. Gift Code: {payoutResult.payout.inksoftGiftCardCode}
                </Alert>
            );
        } else if (payoutResult.payout.recipient.paymentType === "Manual") {
            return (
                <Alert
                    severity="success"
                >
                    Payout created for {payoutResult.payout.recipient.recipientName}.
                </Alert>
            );
        }
    }
}

export default PayoutResultComponent;