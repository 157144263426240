import { TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, TableHead } from "@mui/material";

function SalesOrderTable(props) {
    let { jobId, salesOrders, selected, setSelected } = props;

    const handleSelect = (soNum) => {
        if (selected.includes(soNum)) {
            setSelected([...selected.filter(so => so !== soNum)]);
        } else {
            setSelected([...selected, soNum])
        }
    };

    const handleSelectAll = () => {
        console.log("hsa");
        if (selected.length === salesOrders.length) {
            setSelected([]);
        } else {
            setSelected(salesOrders.map(so => so.number));
        }
    };

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={selected.length === salesOrders.length}
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                        <TableCell>SO Num</TableCell>
                        <TableCell>Business Name</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Paid</TableCell>
                        <TableCell align="right">Balance Due</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {salesOrders.map((so, index) => {
                        const isItemSelected = selected.includes(so.number);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                onClick={(event) => handleSelect(so.number)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                >
                                    {jobId + "-" + so.number}
                                </TableCell>
                                <TableCell>{so.sold_to.business_name}</TableCell>
                                <TableCell>{so.sold_to.name}</TableCell>
                                <TableCell align="right">${so.total_value.toFixed(2)}</TableCell>
                                <TableCell align="right">${so.payments_value.toFixed(2)}</TableCell>
                                <TableCell align="right">${so.balance_due.toFixed(2)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SalesOrderTable;
