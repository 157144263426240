import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import ArtworkImage from "../Decoration/ArtworkImage";

const ArtworkVariationSelector = ({ artworkVariations, selectedArtworkVariationRef, label, onUpdate }) => {  

    return (
        <Autocomplete
            value={selectedArtworkVariationRef ?? null}
            getOptionLabel={(option) => `${option?.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={artworkVariations ?? (selectedArtworkVariationRef ? [selectedArtworkVariationRef] : [])}
            filterOptions={(x) => x}
            onChange={(_, value) => {
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate({
                        "id": value.id,
                        "name": value.name,
                        "thumbnailUrls": value.thumbnailUrls
                    });
                }
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    <Box
                        sx={{
                            width: "128px",
                            height: "128px",
                            mr: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {option.imageUrl &&                                
                            <ArtworkImage
                                imageUrl={option.thumbnailUrls[0]}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                        }
                    </Box>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => {
                return (
                    <TextField 
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                    artworkVariations !== null &&
                                    selectedArtworkVariationRef != null &&
                                    <ArtworkImage 
                                        style={{
                                            maxWidth: "32px",
                                            maxHeight: "32px"
                                        }}
                                        imageUrl={selectedArtworkVariationRef?.thumbnailUrls?.[0]}
                                    />
                            )
                        }}
                    />
                );
            }}
        />
    );
}

export default ArtworkVariationSelector;