import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Card, CardContent, Checkbox, Container, Stack, TextField, Typography } from "@mui/material";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function CAReportPage({ db }) {
    let { domainId } = useParams();
    let [combinedReport, setCombinedReport] = React.useState(null);
    let [reports, setReports] = React.useState(null);

    React.useEffect(() => {
        let reportQuery = query(collection(db, "domains", domainId, "services", "advance", "caReports"), orderBy("creationDate", "desc"));
        const unsubscribe = onSnapshot(reportQuery, (querySnapshot) => {
            let newReports = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
            console.log(newReports);
            setReports(newReports);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db, domainId]);

    React.useEffect(() => {
        console.log(combinedReport);
    }, [combinedReport]);


    const combineObjects = (list) => {
        const result = {};
      
        for (const obj of list) {
          for (const key in obj) {
            if (typeof obj[key] === 'string') {
              // String: Append 
              result[key] = (result[key] || '') + obj[key];
            } else if (typeof obj[key] === 'number') {
               // Number: Add
               result[key] = (result[key] || 0) + obj[key];
            } else if (typeof obj[key] === 'object') {
              // Object: Recursively combine
              result[key] = combineObjects([result[key] || {}, obj[key]]);
            }
          }
        }
        return result;
      }
      
    const calculateMargin = (report) => {
        return (report.totalWebstoreProductRevenue - report.totalWebstoreProductCost - report.totalInboundShippingCost - report.totalWebstoreDecorationCost - report.totalCommissionCost) / report.totalWebstoreProductRevenue;
    }

    return (
        <Container>
            <Stack
                direction="column"
                gap={2}
                alignItems={"center"}
                margin={2}
            >
                <Card>
                    <CardContent>
                        <Autocomplete
                            multiple
                            id="report-selector"
                            options={reports ?? []}
                            disableCloseOnSelect
                            va
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Reports" placeholder="Favorites" />
                            )}
                            onChange={(_, value) => {
                                setCombinedReport(combineObjects(value));
                            }}
                        />
                    </CardContent>
                </Card>
                {
                    combinedReport !== null &&
                    <>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Summary</Typography>
                                <Typography variant="body1">Webstore Product Revenue: {USDollar.format(combinedReport.totalWebstoreProductRevenue)}</Typography>
                                <Typography variant="body1">Other Product Revenue: {USDollar.format(combinedReport.totalOtherProductRevenue)}</Typography>
                                <Typography variant="body1">Total Revenue: {USDollar.format(combinedReport.totalRevenue)}</Typography>
                                <Typography variant="body1">Total Cost: {USDollar.format(combinedReport.totalCost)}</Typography>
                                <Typography variant="body1">Overall Margin: {((combinedReport.totalRevenue - combinedReport.totalCost) / combinedReport.totalRevenue * 100).toFixed(2)}</Typography>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Revenue by Account</Typography>
                                {
                                    Object.keys(combinedReport.accountRevenueMap ?? {}).sort((a, b) => combinedReport.accountRevenueMap[b] - combinedReport.accountRevenueMap[a]).filter(k => combinedReport.accountRevenueMap[k] !== 0).map((key) => 
                                        <Typography variant="body1">{key}: {USDollar.format(combinedReport.accountRevenueMap[key])}</Typography>
                                    )
                                }
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Cost by Vendor</Typography>
                                <Stack
                                    direction="row"
                                    gap={2}
                                    alignItems={"center"}
                                    flexWrap={"wrap"}
                                >
                                    {
                                        Object.keys(combinedReport.vendorCostMap ?? {}).sort((a, b) => combinedReport.vendorCostMap[b].productCost - combinedReport.vendorCostMap[a].productCost).filter(k => combinedReport.vendorCostMap[k].productCost !== 0).map((key) => 
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography variant="h6">{key}</Typography>
                                                    <Typography variant="body1">Product Cost: {USDollar.format(combinedReport.vendorCostMap[key].productCost)}</Typography>
                                                    <Typography variant="body1">Est. Inbound Shipping: {USDollar.format(combinedReport.vendorCostMap[key].estInboundShippingCost)}</Typography>
                                                    <Typography variant="body1">Actual Inbound Shipping: {USDollar.format(combinedReport.vendorCostMap[key].inboundShippingCost)}</Typography>
                                                    <Typography variant="body1">Net: {USDollar.format(combinedReport.vendorCostMap[key].estInboundShippingCost - combinedReport.vendorCostMap[key].inboundShippingCost)}</Typography>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Shipping</Typography>
                                <Typography variant="body1">Revenue: {USDollar.format(combinedReport.totalShippingRevenue)}</Typography>
                                <Typography variant="body1">Cost: {USDollar.format(combinedReport.totalShippingCost)}</Typography>
                                <Typography variant="body1">Subsidy: {USDollar.format(combinedReport.totalShippingSubsidy)}</Typography>
                                <Typography variant="body1">Net Shipping: {USDollar.format(combinedReport.totalShippingRevenue + combinedReport.totalShippingSubsidy - combinedReport.totalShippingCost)}</Typography>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Shipping by Store</Typography>
                                <Stack
                                    direction="row"
                                    gap={2}
                                    alignItems={"center"}
                                    flexWrap={"wrap"}
                                >
                                    {
                                        Object.keys(combinedReport.storeShippingMap ?? {}).sort((a, b) => combinedReport.storeShippingMap[b].revenue - combinedReport.storeShippingMap[a].revenue).map((key) => 
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography variant="h6">{key}</Typography>
                                                    <Typography variant="body1">Revenue: {USDollar.format(combinedReport.storeShippingMap[key].revenue)}</Typography>
                                                    <Typography variant="body1">Cost: {USDollar.format(combinedReport.storeShippingMap[key].cost)}</Typography>
                                                    {
                                                        combinedReport.storeShippingMap[key].subsidy !== 0 &&
                                                        <Typography variant="body1">Subsidy: {USDollar.format(combinedReport.storeShippingMap[key].subsidy)}</Typography>

                                                    }
                                                    <Typography variant="body1">Net: {USDollar.format(combinedReport.storeShippingMap[key].revenue + combinedReport.storeShippingMap[key].subsidy - combinedReport.storeShippingMap[key].cost)}</Typography>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Transfers</Typography>
                                <Typography variant="body1">Est. Transfer Cost: {USDollar.format(combinedReport.totalEstTransferCost)}</Typography>
                                <Typography variant="body1">Transfers Cost: {USDollar.format(combinedReport.totalTransferCost)}</Typography>
                                <Typography variant="body1">Net: {USDollar.format(combinedReport.totalEstTransferCost - combinedReport.totalTransferCost)}</Typography>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Decoration</Typography>
                                {
                                    Object.keys(combinedReport.decorationCostMap ?? {}).sort((a, b) => combinedReport.decorationCostMap[b] - combinedReport.decorationCostMap[a]).filter(k => combinedReport.decorationCostMap[k] !== 0).map((key) => 
                                        <Typography variant="body1">{key}: {USDollar.format(combinedReport.decorationCostMap[key])}</Typography>
                                    )
                                }
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Webstore Margin</Typography>
                                <Typography variant="body1">Product Revenue: {USDollar.format(combinedReport.totalWebstoreProductRevenue)}</Typography>
                                <Typography variant="body1">Product Cost: {USDollar.format(combinedReport.totalWebstoreProductCost)}</Typography>
                                <Typography variant="body1">Inbound Shipping Cost: {USDollar.format(combinedReport.totalInboundShippingCost)}</Typography>
                                <Typography variant="body1">Decoration Cost: {USDollar.format(combinedReport.totalWebstoreDecorationCost)}</Typography>
                                <Typography variant="body1">Commission: {USDollar.format(combinedReport.totalCommissionCost)}</Typography>
                                <Typography variant="body1">Est. Cost: {USDollar.format(combinedReport.totalEstCost)}</Typography>
                                <Typography variant="body1">Actual Cost: {USDollar.format(combinedReport.totalWebstoreProductCost + combinedReport.totalInboundShippingCost + combinedReport.totalWebstoreDecorationCost + combinedReport.totalCommissionCost)}</Typography>
                                <Typography variant="body1">Est. Margin: {((combinedReport.totalWebstoreProductRevenue - combinedReport.totalEstCost) / combinedReport.totalWebstoreProductRevenue * 100).toFixed(2)}%</Typography>
                                <Typography variant="body1">Actual Margin: {(calculateMargin(combinedReport) * 100).toFixed(2)}%</Typography>
                            </CardContent>
                        </Card>
                    </>
                }
            </Stack>
        </Container>
    );
}

export default CAReportPage;