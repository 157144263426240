import React from "react";
import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

function GeneralAdvanceSettingsCard({
    advanceSettings,
    onSave
}) {
    let [adminUserId, setAdminUserId] = React.useState("");
    let [defaultWebstoreOrderKanbanStatus, setDefaultWebstoreOrderKanbanStatus] = React.useState("");
    let [taxAdjustmentProductId, setTaxAdjustmentProductId] = React.useState("");
    let [ignoreVendorNameMatches, setIgnoreVendorNameMatches] = React.useState([]);

    let [mainAuthMode, setMainAuthMode] = React.useState("Token");
    let [orderAuthMode, setOrderAuthMode] = React.useState("Token");
    let [shippingAuthMode, setShippingAuthMode] = React.useState("Token");

    const getValue = (value) => {
        if (value === "") {
            return null;
        } else {
            return value;
        }
    }

    const handleSave = () => {
        onSave({
            adminUserId: getValue(adminUserId),
            defaultWebstoreOrderKanbanStatus: getValue(defaultWebstoreOrderKanbanStatus),
            taxAdjustmentProductId: getValue(taxAdjustmentProductId),
            ignoreVendorNameMatches,
            mainAuthMode,
            orderAuthMode,
            shippingAuthMode
        });
    }

    React.useEffect(() => {
        setAdminUserId(advanceSettings.adminUserId);
        setDefaultWebstoreOrderKanbanStatus(advanceSettings.defaultWebstoreOrderKanbanStatus);
        setTaxAdjustmentProductId(advanceSettings.taxAdjustmentProductId);
        setIgnoreVendorNameMatches(advanceSettings.ignoreVendorNameMatches ?? []);
        setMainAuthMode(advanceSettings.mainAuthMode ?? "Token");
        setOrderAuthMode(advanceSettings.shippingAuthMode ?? "Token");
        setShippingAuthMode(advanceSettings.shippingAuthMode ?? "Token");
    }, [advanceSettings]);

    return (
        <Card sx={{width: "500px"}}>
            <CardContent>
                <Stack
                    gap={2}
                >
                    <Typography variant="h6" sx={{mb: 2}}>General Settings</Typography>
                    <TextField
                        label="Admin User ID"
                        variant="outlined"
                        value={adminUserId}
                        onChange={(event) => setAdminUserId(event.target.value)}
                    />
                    <TextField
                        label="Default Webstore Order Kanban Status"
                        variant="outlined"
                        value={defaultWebstoreOrderKanbanStatus}
                        onChange={(event) => setDefaultWebstoreOrderKanbanStatus(event.target.value)}
                    />
                    <TextField
                        label="Tax Adjustment Product ID"
                        variant="outlined"
                        value={taxAdjustmentProductId}
                        onChange={(event) => setTaxAdjustmentProductId(event.target.value)}
                    />
                    <MuiChipsInput required label={"Ignore Vendor Name Matches"} value={ignoreVendorNameMatches} onChange={setIgnoreVendorNameMatches} />
                    <FormControl>
                        <InputLabel>Main Auth Mode</InputLabel>
                        <Select value={mainAuthMode} onChange={(e) => setMainAuthMode(e.target.value)} label="Main Auth Mode">
                            <MenuItem value="Token">Token</MenuItem>
                            <MenuItem value="User">User</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Order Auth Mode</InputLabel>
                        <Select value={orderAuthMode} onChange={(e) => setOrderAuthMode(e.target.value)} label="Order Auth Mode">
                            <MenuItem value="Token">Token</MenuItem>
                            <MenuItem value="User">User</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Shipping Auth Mode</InputLabel>
                        <Select value={shippingAuthMode} onChange={(e) => setShippingAuthMode(e.target.value)} label="Shipping Auth Mode">
                            <MenuItem value="Token">Token</MenuItem>
                            <MenuItem value="User">User</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant={"contained"} onClick={handleSave}>
                        Save
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default GeneralAdvanceSettingsCard;