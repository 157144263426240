import axios from "axios";
import { getAuth } from "firebase/auth";
import { SERVICE_URL } from "../App";

async function getJobs(domainId, storeId, clientId, status) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/syncore/getJobs`,
        {
            params: {
                "domainId": domainId,
                "storeId": storeId,
                "clientId": clientId,
                "status": status
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data;
    return data.data.jobs;
}

async function getSalesOrders(domainId, jobId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/syncore/getSalesOrders`,
        {
            params: {
                "domainId": domainId,
                "jobId": jobId
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data;
    return data.data.salesOrders;
}

async function getSuppliers(domainId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/syncore/getSuppliers`,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                "domainId": domainId
            }
        }
    );
    let data = response.data;
    return data["data"]["suppliers"];
};

export { getJobs, getSuppliers, getSalesOrders };