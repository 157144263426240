import React from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FormControl, IconButton, InputLabel, Box, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, OutlinedInput, Chip } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";

function StyleDialog(props) {
  const { onClose, styles, selectedValue, open, inksoftStoreUrl, supData, handleUpdate } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    let url = `https://logotologist.com/${inksoftStoreUrl}/shop-admin/edit-product/${value.productId}`;
    if (value.type === "legacy")
        url = `https://logotologist.com/logotology_designer/Config/ManageEditProduct?product_id=${value.productId}`
    window.open(url, "_blank");
    onClose(value);
  };
  return (
    <Dialog onClose={handleClose} maxWidth="md" fullWidth open={open}>
      <DialogTitle>Style List</DialogTitle>
        <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Style Name</TableCell>
                <TableCell>Side</TableCell>
                <TableCell>Option</TableCell>
                <TableCell>Edit</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {styles.map((style) => (
                <TableRow
                    key={style.styleId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row" sx={{textAlign: "center"}}>
                        <img style={{maxWidth: "64px", maxHeight: "64px"}} src={style.imageUrl || style.blankUrl} />
                    </TableCell>
                    <TableCell>{style.productName}</TableCell>
                    <TableCell>{style.styleName}</TableCell>
                    <TableCell>{(style.side ||  "N/A").toUpperCase()}</TableCell>
                    <TableCell>
                    <Box sx={{ minWidth: 120 }}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="options-label">Options</InputLabel>
                        <Select
                            labelId="options-label"
                            id="options"
                            multiple
                            value={supData.overrides[style.sku + "-" + style.styleName + "-" + style.side] || ((supData.active && style.type !== "legacy") ? [0] : [])}
                            onChange={(event) => handleUpdate(style, event.target.value)}
                            input={<OutlinedInput id="options-chip" label="Options" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={"Option " + (value + 1)} />
                                ))}
                                </Box>
                            )}
                            >
                            {supData.active ? supData.options.map((option, idx) => (
                                <MenuItem key={idx} value={idx}>Option {idx + 1}</MenuItem>
                            )) : []}
                        </Select>
                    </FormControl>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <IconButton aria-label="delete" onClick={() => handleListItemClick(style)}>
                            <ExitToApp />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Dialog>
  );
}

export default StyleDialog;