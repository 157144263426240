import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { Box, CircularProgress, Alert, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { LinesTable } from './LinesTable';
import { SERVICE_URL } from '../App';
import { useParams } from 'react-router-dom';
import * as SyncoreManager from '../managers/SyncoreManager';

function ImportDialog(props) {
    let { open, handleClose, orders, syncoreStoreData } = props;
    let { domainId } = useParams();
    let [jobs, setJobs] = React.useState([]);
    let [jobIdx, setJobIdx] = React.useState("new");
    let [orderPreviews, setOrderPreviews] = React.useState([]);
    let [jobsLoading, setJobsLoading] = React.useState(false);
    let [useDefaultContact, setUseDefaultContact] = React.useState(orders.length > 1);
    let [previewsLoading, setPreviewsLoading] = React.useState(false);
    let [error, setError] = React.useState(null);

    React.useEffect(() => {
        const getJobs = async () => {
            try {
                setJobsLoading(true);
                const jobs = await SyncoreManager.getJobs(domainId, syncoreStoreData["storeId"], syncoreStoreData["defaultClientId"], "Pending");
                setJobs(jobs);
            } catch (e) {
                console.log(e);
                setError(e.toString());
            } finally {
                setJobsLoading(false);
            }
        };
        const getOrderPreviews = async () => {
            try {
                setPreviewsLoading(true);
                const auth = getAuth();
                const token = await auth.currentUser.getIdToken();
                let response = await axios.get(
                    `${SERVICE_URL}/syncore/getInksoftImportPreview`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        params: {
                            "domainId": domainId,
                            "guids": JSON.stringify(orders.map(o => o.guid)),
                        },
                    }
                );
                let data = response.data;
                setOrderPreviews(data["data"]["orders"]);
            } catch (e) {
                console.log(e);
                setError(e.toString());
            } finally {
                setPreviewsLoading(false);
            }
        };
        if (open) {
            getJobs();
            getOrderPreviews();
        }
    }, [syncoreStoreData, open]);

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose(false, null, null)} fullWidth maxWidth={"md"}>
                <DialogTitle>Import Inksoft Orders</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the job to import this Inksoft order into. If creating a new job, choose to use the default contact for this store in order to be able to import future orders into the same job.
                    </DialogContentText>
                    {
                        error !== null && <Alert severity="warning">{error}</Alert>
                    }
                    {
                        jobs.length > 0 &&
                        <FormControl fullWidth sx={{marginTop: 4, marginBottom: 2}}>
                            <InputLabel id="job-select-label">Job</InputLabel>
                            <Select
                                labelId="job-select-label"
                                id="job-select"
                                value={jobIdx}
                                label="Job"
                                onChange={(event) => setJobIdx(event.target.value)}
                            >
                                <MenuItem value={"new"}>Create New Job</MenuItem>
                                {
                                    jobs.map((job, idx) => <MenuItem key={idx} value={idx}>{job.id}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        jobIdx === "new" &&
                        <FormGroup sx={{marginBottom: 2}}>
                            <FormControlLabel control={<Switch checked={useDefaultContact} onChange={(_, checked) => setUseDefaultContact(checked)} disabled={orders.length > 1} />} label="Use Default Contact" />
                        </FormGroup>
                    }
                    {
                        jobIdx === "new" && !useDefaultContact &&
                        <Alert severity="info" sx={{marginBottom: 2}}>Default contact must be used in order to add future orders to this job.</Alert>
                    }
                    {
                        (previewsLoading || jobsLoading) &&
                        <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <CircularProgress />
                        </Box> 
                    }
                    <Stack
                        gap={1}
                    >
                        {
                            orderPreviews.map((op, idx) =>
                                <LinesTable key={idx} orderId={op.orderId} nestedLines={op.nestedLines} />
                            )
                        }
                    </Stack>
                    {/* {
                        orderPreviews.map((op, idx) => 
                            <LineItemsPreviewTree key={idx} orderId={op.orderId} nestedLines={op.nestedLines} />
                        )
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false, null, null)}>Cancel</Button>
                    <Button onClick={() => handleClose(true, jobIdx === "new" ? null : jobs[jobIdx].id, useDefaultContact ? syncoreStoreData["defaultClientId"] : null)}>Import</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImportDialog;