import React, { useMemo } from "react";
import * as StoreManager from "../managers/StoreManager";
import { debounce } from "lodash";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const InksoftStoreSelector = ({ domainId, selectedStoreRef, label, onUpdate }) => {
    const [stores, setStores] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    
    const getStores = async () => {
        try {
            setLoading(true);
            setError(null);
            const stores = await StoreManager.getStores(domainId);
            setStores(stores.map((s) => ({
                "id": s.storeId,
                "name": s.name,
                "uri": s.uri
            })));
        } catch (e) {
            console.log(e);
            setError(e.toString());
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setStores(null);
    }, [domainId]);

    React.useEffect(() => {
        if (selectedStoreRef == null) {
            setStores(null);
        }
    }, [selectedStoreRef]);

    return (
        <Autocomplete
            value={selectedStoreRef ?? null}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={stores ?? (selectedStoreRef ? [selectedStoreRef] : [])}
            loading={loading}
            getOptionKey={(option) => option.id}
            filterOptions={(options, state) => options.filter((o) => o.name.toLowerCase().includes(state.inputValue?.toLowerCase()))}
            onChange={(_, value) => {
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate(value);
                }
            }}
            onFocus={() => {
                if (stores === null)
                    getStores();
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default InksoftStoreSelector;