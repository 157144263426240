import { useParams, useSearchParams } from "react-router-dom";
import { Alert, AlertTitle, Autocomplete, Box, Button, Card, CardContent, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import StoreSelector from "../StoreSelector";
import axios from "axios";
import { SERVICE_URL } from "../App";
import { getAuth } from "firebase/auth";

function StoreAuditPage(props) {
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    let [storePrefixes, setStorePrefixes] = React.useState([]);
    let [targetMargin, setTargetMargin] = React.useState("42");
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState(null);
    let [issues, setIssues] = React.useState(null);

    const handleStoreChange = (event) => {
        let newParams = new URLSearchParams(
            searchParams.toString()
        );
        newParams.set("inksoftStoreUrl", event.target.value);
        setSearchParams(newParams);
        setIssues(null);
        setError(null);
    }

    const runAudit = async () => {
        setLoading(true);
        setIssues(null);
        setError(null);
        try {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            let formData = new FormData();
            formData.append("storeUrl", searchParams.get("inksoftStoreUrl"));
            formData.append("storePrefixes", JSON.stringify(storePrefixes));
            formData.append("targetMargin", parseInt(targetMargin) / 100);
            let response = await axios.post(
                SERVICE_URL + "/inksoft/runStoreAudit",
                formData,
                {
                    params: {
                        "domainId": domainId,
                    },
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            setError(null)
            setIssues(response.data.data.issues)
        } catch (error) {
            console.error(error);
            setError(error.response?.data?.message ?? error.toString());
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container>
            <Stack
                alignItems={"center"}
            >
                <StoreSelector value={searchParams.get("inksoftStoreUrl") ?? ""} handleStoreChange={handleStoreChange}/>
                <Card sx={{margin: 1}}>
                    <CardContent>
                        <Stack>
                            <Typography gutterBottom textAlign="center" variant="h5" component="div">
                                Audit Parameters
                            </Typography>
                            <Autocomplete 
                                sx={{width: 300, mb: 2}}
                                freeSolo
                                multiple
                                autoSelect
                                options={storePrefixes}
                                renderInput={(params) => <TextField {...params} label="Store Prefix" />}
                                onChange={(_, value) => setStorePrefixes(value)}
                            />
                            <TextField sx={{mb: 2}} label="Target Margin" value={targetMargin} onChange={(e) => setTargetMargin(e.target.value)} />
                            <Button variant="contained" onClick={() => runAudit()}>Run Audit</Button>
                        </Stack>
                    </CardContent>
                </Card>
                { error &&
                    <Alert severity="error">{error}</Alert>
                }
                { loading &&
                    <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress />
                    </Box>
                }
                {
                    issues &&
                    <Card sx={{margin: 1}}>
                        <CardContent>
                            <Typography variant="h5">Issues</Typography>
                            {
                                issues.map((i, idx) => 
                                    <Alert sx={{margin: 1}} key={idx} severity={i.severity.toLowerCase()}>
                                        {
                                            i.issueType === "PRODUCT" && i.style && i.size &&
                                            <AlertTitle>{i.sku} - {i.style} - {i.size}</AlertTitle>
                                        }
                                        {
                                            i.issueType === "PRODUCT" && i.style && !i.size &&
                                            <AlertTitle>{i.sku} - {i.style}</AlertTitle>
                                        }
                                        {
                                            i.issueType === "PRODUCT" && !i.style && !i.size &&
                                            <AlertTitle>{i.sku}</AlertTitle>
                                        }
                                        {i.issueDescription}
                                    </Alert>
                                )
                            }
                        </CardContent>
                    </Card>
                }
                {
                    issues && issues.length === 0 &&
                    <Alert severity="success">
                        No issues found
                    </Alert>
                }
            </Stack>
        </Container>
    );
}

export default StoreAuditPage;