import { Card, CardContent, Grid, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AccountSelector from "../Components/AccountSelector";
import ArtworkVariationSelector from "../Components/ArtworkVariationSelector";
import ArtworkSelector from "../Components/ArtworkSelector";
import { Delete } from "@mui/icons-material";

function DecorationUpdateRow({ domainId, accountRef, artwork, artworkVariationRef, cost, onUpdate, onDelete }) {

    const handleUpdate = (field) => (data) => {
        onUpdate({
            accountRef,
            artwork,
            artworkVariationRef,
            [field]: data
        });
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={4}>
                        <AccountSelector 
                            domainId={domainId}
                            selectedAccountRef={accountRef}
                            label="Artwork Account"
                            onUpdate={handleUpdate("accountRef")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ArtworkSelector 
                            domainId={domainId}
                            accountId={accountRef?.id}
                            selectedArtworkRef={artwork?.ref}
                            label="Artwork"
                            onUpdate={handleUpdate("artwork")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ArtworkVariationSelector 
                            domainId={domainId}
                            artworkVariations={artwork?.variations}
                            selectedArtworkVariationRef={artworkVariationRef}
                            label="Artwork Variation"
                            onUpdate={handleUpdate("artworkVariationRef")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            label="Cost"
                            type="number"
                            value={cost}
                            onChange={(e) => handleUpdate("cost")(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => onDelete()}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default DecorationUpdateRow;