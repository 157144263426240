import axios from "axios";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { SERVICE_URL } from "../App";
import dayjs from "dayjs";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "payments"));
    return serviceDoc.data();
}

function getSyncorePaymentIntents(domainId, setSyncorePaymentIntents) {
    const db = getFirestore();
    let date = dayjs().startOf('day');
    const q = query(collection(db, "domains", domainId, "services", "payments", "syncorePaymentIntents"), where("dateCreated", ">=", date.toDate()), orderBy("dateCreated", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newSyncorePaymentIntents = querySnapshot.docs.map((doc) => {return doc.data()});
        setSyncorePaymentIntents(newSyncorePaymentIntents);
    }, (error) => console.log(error));
    return unsubscribe;
}

async function captureSyncorePayment(domainId, jobId, soNums, readerId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("soNums", JSON.stringify(soNums));
    formData.append("readerId", readerId);
    await axios.post(
        `${SERVICE_URL}/payments/captureSyncorePayment`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}

async function captureManualPayment(domainId, amount, description, readerId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("amount", amount);
    formData.append("description", description);
    formData.append("readerId", readerId);
    await axios.post(
        `${SERVICE_URL}/payments/captureManualPayment`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}

async function clearStripeReader(domainId, readerId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("readerId", readerId);
    await axios.post(
        `${SERVICE_URL}/payments/clearStripeReader`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}

export { getSyncorePaymentIntents, getServiceDoc, captureSyncorePayment, clearStripeReader, captureManualPayment };
