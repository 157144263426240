import React from "react";
import * as AdvanceManager from "../managers/AdvanceManager";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const ContactSelector = ({ domainId, accountId, selectedContactRef, label, onUpdate }) => {
    const [contacts, setContacts] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    
    const getAccountContacts = async () => {
        try {
            setLoading(true);
            setError(null);
            const contacts = await AdvanceManager.getAccountContacts(domainId, accountId);
            setContacts(contacts.map((c) => ({...c, name: `${c.firstName} ${c.lastName}`})));
        } catch (e) {
            console.log(e);
            setError(e.toString());
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setContacts(null);
    }, [domainId, accountId]);

    React.useEffect(() => {
        if (selectedContactRef == null) {
            setContacts(null);
        }
    }, [selectedContactRef]);

    return (
        <Autocomplete
            value={selectedContactRef ?? null}
            getOptionLabel={(option) => `${option?.name} (${option?.email})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={contacts ?? (selectedContactRef ? [selectedContactRef] : [])}
            loading={loading}
            filterOptions={(x) => x}
            onChange={(_, value) => {
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate({
                        "id": value.id,
                        "name": value.name,
                        "email": value.email
                    });
                }
            }}
            onFocus={() => {
                if (contacts === null)
                    getAccountContacts();
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default ContactSelector;