import axios from "axios";
import { getAuth } from "firebase/auth";
import { SERVICE_URL } from "../App";

async function getOpenOrders(domainId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/inksoft/getOrderSummaries`,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data.data;
    return data.orderSummaries.filter(o => parseInt(o.orderId) >= 1014813).reverse();
}

export { getOpenOrders };
