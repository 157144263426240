import React from "react";
import { Alert, Box, CircularProgress, Container, Stack } from "@mui/material";
import StoreSelector from "../StoreSelector";
import { useParams, useSearchParams } from "react-router-dom";
import * as AdvanceManager from "../managers/AdvanceManager";
import ProductSyncTable from "./ProductSyncTable";
import { FieldPath, deleteField } from "firebase/firestore";
import StoreSettingsCard from "./StoreSettingsCard";

function ProductSync() {
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [products, setProducts] = React.useState([]);
    let [storeSettings, setStoreSettings] = React.useState({});
    let [serviceData, setServiceData] = React.useState({});
    let [loadingProducts, setLoadingProducts] = React.useState(false);
    let [loadingError, setLoadingError] = React.useState(null);

    const handleStoreChange = (event) => {
        let newParams = new URLSearchParams(
            searchParams.toString()
        );
        newParams.set("inksoftStoreUrl", event.target.value);
        setSearchParams(newParams);
        setProducts([]);
    }

    const getData = React.useCallback(async () => {
        try {
            let storeURL = searchParams.get("inksoftStoreUrl");
            setLoadingProducts(true);
            let newProducts = await AdvanceManager.getProductSyncInfo(domainId, "Inksoft", storeURL);
            setProducts(newProducts);
            let serviceData = await AdvanceManager.getServiceDoc(domainId);
            console.log(serviceData);
            setServiceData(serviceData);
        } catch (error) {
            setLoadingError(error.response.data["message"]);
        } finally {
            setLoadingProducts(false);
        }
        
    }, [domainId, searchParams]);

    React.useEffect(() => {
        if (searchParams.get("inksoftStoreUrl") !== null) {
            getData();
            return AdvanceManager.getStoreSettingsDoc(domainId, searchParams.get("inksoftStoreUrl"), setStoreSettings);
        }
    }, [domainId, searchParams, getData]);

    const handleStoreSettingsProductOverrideUpdate = (sku, type, color, override) => {
        if (override == null) {
            AdvanceManager.updateStoreSettingsDoc(domainId, searchParams.get("inksoftStoreUrl"), new FieldPath("productOverrides", sku, type, color), deleteField());
        } else {
            AdvanceManager.updateStoreSettingsDoc(domainId, searchParams.get("inksoftStoreUrl"), new FieldPath("productOverrides", sku, type, color), override);
        }
    }

    const handleStoreSettingsUpdate = (dotPath, value) => {
        AdvanceManager.updateStoreSettingsDoc(domainId, searchParams.get("inksoftStoreUrl"), dotPath, value);
    }

    return (
        <Container sx={{mb: 2}}>
            <Stack
                gap={1}
                direction='column'
                alignItems='center'
            >
                <StoreSelector value={searchParams.get("inksoftStoreUrl") ?? ""} handleStoreChange={handleStoreChange} />
                {
                    searchParams.get("inksoftStoreUrl") != null &&
                    <>
                        <StoreSettingsCard domainId={domainId} storeIdentifier={searchParams.get("inksoftStoreUrl")} storeSettings={storeSettings} handleStoreSettingsUpdate={handleStoreSettingsUpdate} serviceData={serviceData}/>
                        {   loadingProducts &&
                            <CircularProgress />
                        }
                        {
                            !loadingProducts && products.length > 0 &&
                            <ProductSyncTable products={products} storeSettings={storeSettings} handleOverrideUpdate={handleStoreSettingsProductOverrideUpdate} />
                        }
                    </>
                }
                {
                    loadingError &&
                    <Alert severity="error" sx={{mb: 2}}>{loadingError}</Alert>
                }
                
            </Stack>
        </Container>
    );
}

export default ProductSync;