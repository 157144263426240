import axios from "axios";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { SERVICE_URL } from "../App";
import { getAuth } from "firebase/auth";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "inksoftDecoration"));
    return serviceDoc.data();
}

function getInksoftDecorationData(domainId, storeUrl) {
    const db = getFirestore();
    let settingsDoc = getDoc(doc(db, "domains", domainId, "inksoftDecorationData", storeUrl));
    return settingsDoc;
}

async function setInksoftDecorationData(domainId, data) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    await axios.post(
        `${SERVICE_URL}/inksoft/setDecorationData`,
        data,
        {
            params: {
                "domainId": domainId
            },
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
    )
}

export { getServiceDoc, getInksoftDecorationData, setInksoftDecorationData };
