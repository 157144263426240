import { getFirestore, query, collection, onSnapshot, and, where, addDoc, serverTimestamp } from "firebase/firestore";

function getPendingTasksWithFunction(domainId, task_function, setTasks) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "tasks"), and(
        where("pending", "==", true),
        where("function", "==", task_function)
    ));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tasks = querySnapshot.docs.map((doc) => doc.data());
        setTasks(tasks);
    }, (error) => console.log(error));
    return unsubscribe;
}

function createProcessShipmentsTask(domainId, ids) {
    return createTask(
        domainId,
        "Process Shipments",
        "shipping/processShipments",
        "POST",
        "threadpath-service",
        {
            query: {
                domainId: domainId
            },
            data: {
                shipmentIds: ids
            }
        }
    );
}

async function createTask(
    domainId,
    description,
    taskFunction,
    method,
    service,
    {
        status = "new",
        pending = true,
        attempts = 1,
        query = {},
        data = {},
        taskGroupId = null,
        parentTaskId = null,
        source = "ThreadPath"
    }
) {
    const task = {
        domainId: domainId,
        description: description,
        function: taskFunction,
        method: method,
        service: service,
        status: status,
        pending: pending,
        attempts: attempts,
        source: source,
        critital: false,
        creationDate: serverTimestamp(),
        query: query,
        data: data,
        taskGroupId: taskGroupId,
        parentTaskId: parentTaskId
    };
    const db = getFirestore();
    let ref = await addDoc(collection(db, "domains", domainId, "tasks"), task);
    return ref.id;
}
export { getPendingTasksWithFunction, createTask, createProcessShipmentsTask }