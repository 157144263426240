import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Autocomplete, TextField } from '@mui/material';
import { update } from 'lodash';

function ProductRow({ product, storeSettings, handleOverrideUpdate }) {
    const [open, setOpen] = React.useState(false);
    let colorMatch = product.colors.filter(c => c.matched).length === product.colors.length;
    let colorMatchStr = `${product.colors.filter(c => c.matched).length}/${product.colors.length}`;
    let sizeMatch = product.sizes.filter(c => c.matched).length === product.sizes.length;
    let sizeMatchStr = `${product.sizes.filter(c => c.matched).length}/${product.sizes.length}`;
    let skuMatch = product.skus.filter(s => s.matched).length === product.skus.length;
    let skuMatchStr = `${product.skus.filter(s => s.matched).length}/${product.skus.length}`;
    let fullMatch = colorMatch && sizeMatch && skuMatch;

    const updateColorOverride = (sku, color, override) => handleOverrideUpdate(sku, "colors", color, override);
    const updateSizeOverride = (sku, size, override) => handleOverrideUpdate(sku, "sizes", size, override);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: fullMatch ? '' : '#ffd7db' }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{product.supplier}</TableCell>
                <TableCell>{product.matched ? "YES" : "NO"}</TableCell>
                <TableCell align="right">{colorMatchStr}</TableCell>
                <TableCell align="right">{sizeMatchStr}</TableCell>
                <TableCell align="right">{skuMatchStr}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Colors
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Inksoft Color</TableCell>
                                        <TableCell>Advance Color</TableCell>
                                        <TableCell>Match</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product.colors.map((color) => (
                                        <TableRow key={color.colorName}>
                                            <TableCell component="th" scope="row">
                                                {color.colorName}
                                            </TableCell>
                                            {(!color.matched || color.colorNameOverride) &&
                                                <TableCell>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="color-override"
                                                        size="small"
                                                        value={storeSettings?.productOverrides?.[product.sku]?.colors?.[color.colorName] ?? null}
                                                        options={color.matchOptions}
                                                        sx={{ width: 200 }}
                                                        renderInput={(params) => <TextField {...params} variant='standard' />}
                                                        onChange={(_, v) => updateColorOverride(product.sku, color.colorName, v)}
                                                    />
                                                </TableCell>            
                                            }
                                            {color.matched && !color.colorNameOverride &&
                                                <TableCell>{color.advanceColorName}</TableCell>
                                            }
                                            <TableCell align="right">{color.matchPercentage}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography variant="h6" gutterBottom component="div">
                                Sizes
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Inksoft Size</TableCell>
                                        <TableCell>Advance Size</TableCell>
                                        <TableCell>Match</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product.sizes.map((size) => (
                                        <TableRow key={size.sizeName}>
                                            <TableCell component="th" scope="row">
                                                {size.sizeName}
                                            </TableCell>
                                            {(!size.matched || size.sizeNameOverride) &&
                                                <TableCell>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="size-override"
                                                        size='small'
                                                        value={storeSettings?.productOverrides?.[product.sku]?.sizes?.[size.sizeName] ?? null}
                                                        options={size.matchOptions}
                                                        sx={{ width: 200 }}
                                                        renderInput={(params) => <TextField {...params} variant='standard' />}
                                                        onChange={(_, v) => updateSizeOverride(product.sku, size.sizeName, v)}
                                                    />
                                                </TableCell>   
                                            }
                                            {size.matched && !size.sizeNameOverride &&
                                                <TableCell>{size.advanceSizeName}</TableCell>
                                            }
                                            <TableCell align="right">{size.matchPercentage}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography variant="h6" gutterBottom component="div">
                                Skus
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Inksoft Color</TableCell>
                                        <TableCell>Advance Color</TableCell>
                                        <TableCell>Inksoft Size</TableCell>
                                        <TableCell>Advance Size</TableCell>
                                        <TableCell>Inksoft Manufacturer Sku</TableCell>
                                        <TableCell>Advance Product ID</TableCell>
                                        <TableCell>Match</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product.skus.map((skuData, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell component="th" scope="row">
                                                {skuData.colorName}
                                            </TableCell>
                                            <TableCell>{skuData.advanceColorName}</TableCell>
                                            <TableCell>{skuData.sizeName}</TableCell>
                                            <TableCell>{skuData.advanceSizeName}</TableCell>
                                            <TableCell>{skuData.manufacturerSku}</TableCell>
                                            <TableCell>{skuData.advanceProductId}</TableCell>
                                            <TableCell>{skuData.matched}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ProductSyncTable({ products, storeSettings, handleOverrideUpdate }) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Image</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Sku</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell>Product Match</TableCell>
                        <TableCell>Colors Match</TableCell>
                        <TableCell>Sizes Match</TableCell>
                        <TableCell>Skus Match</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product) => (
                        <ProductRow key={product.id} product={product} storeSettings={storeSettings} handleOverrideUpdate={handleOverrideUpdate} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}