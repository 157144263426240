import * as React from "react"
import { Card, CardContent, Stack, FormControl, Select, MenuItem, Input, InputLabel, TextField, Typography, InputAdornment, FormControlLabel, Switch, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { clearAllOverrides, isChanges, loadSettings, setAmount, setEnabled, setMode, setPercentage, setVipDiscountCommissionEnabled } from "./inksoftCommissionSlice";
import { store } from "../Store";
import * as StoreManager from "../managers/StoreManager";
import { useParams, useSearchParams } from "react-router-dom";

function StoreSettingsCard() {
    const { domainId } = useParams();
    const [searchParams, _] = useSearchParams();
    const mode = useSelector(state => state.inksoftCommission.commissionSettings.mode);
    const percentage = useSelector(state => state.inksoftCommission.commissionSettings.percentage);
    const amount = useSelector(state => state.inksoftCommission.commissionSettings.amount);
    const enabled = useSelector(state => state.inksoftCommission.commissionSettings.enabled);
    const vipDiscountCommissionEnabled = useSelector(state => state.inksoftCommission.commissionSettings.vipDiscountCommissionEnabled);
    const productOverrides = useSelector(state => state.inksoftCommission.commissionSettings.productOverrides);
    const changes = useSelector(isChanges);

    const dispatch = useDispatch();

    let [percentageStr, setPercentageStr] = React.useState("");
    let [amountStr, setAmountStr] = React.useState("");

    React.useEffect(() => {
        setPercentageStr((percentage ?? 0).toString());
        setAmountStr((amount ?? 0).toString());
    }, [percentage, amount]);

    const parseNumber = (value) => {
        let num = Number(value);
        console.log(num);
        if (num === null || isNaN(num)) {
            return 0;
        }
        return num;
    };

    const saveSettings = async () => {
        await StoreManager.setStoreCommissionSettings(domainId, searchParams.get("inksoftStoreUrl"), store.getState().inksoftCommission.commissionSettings);
        dispatch(loadSettings(store.getState().inksoftCommission.commissionSettings));
    };

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom textAlign="center" variant="h5" component="div">
                    Commission Configuration
                </Typography>
                <Stack direction={'column'} gap={2}>
                    <FormControl>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={enabled}
                                    onChange={(_, checked) => dispatch(setEnabled(checked))}
                                />
                            } 
                            label="Commission" 
                        />
                    </FormControl>
                    {
                        enabled &&
                        <FormControl>
                            <InputLabel id="mode-label">Mode</InputLabel>
                            <Select
                                labelId="mode-label"
                                id="mode"
                                value={mode ?? null}
                                label="Mode"
                                onChange={(e) => dispatch(setMode(e.target.value))}
                            >
                                <MenuItem value={'PricePercentage'}>Percentage of Price</MenuItem>
                                <MenuItem value={'AddedAmount'}>Added Amount</MenuItem>
                                {/* <MenuItem value={'BasePrice'}>From Base Price</MenuItem> */}
                            </Select>
                        </FormControl>
                    }
                    {
                        enabled &&
                        mode == "PricePercentage" &&
                        <TextField
                            labelId="percentage-label"
                            id="percentage"
                            value={percentageStr}
                            label="Percentage"
                            onChange={(e) => setPercentageStr(e.target.value)}
                            onBlur={(e) => dispatch(setPercentage(parseNumber(e.target.value)))}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    }
                    {
                        enabled &&
                        mode == "AddedAmount" &&
                        <TextField
                            labelId="amount-label"
                            id="amount"
                            value={amountStr}
                            label="Amount"
                            onChange={(e) => setAmountStr(e.target.value)}
                            onBlur={(e) => dispatch(setAmount(parseNumber(e.target.value)))}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                    }
                    {
                        enabled &&
                        <FormControl>
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        checked={vipDiscountCommissionEnabled ?? false}
                                        onChange={(_, checked) => dispatch(setVipDiscountCommissionEnabled(checked))}
                                    />
                                } 
                                label="VIP Discount Commission Enabled" 
                            />
                        </FormControl>
                    }
                    {
                        enabled &&
                        mode !== "BasePrice" &&
                        <Button variant={'outlined'} color={'error'} onClick={() => dispatch(clearAllOverrides())} disabled={Object.keys(productOverrides ?? {}).length === 0}>Clear All Overrides</Button>
                    }
                    <Button variant={'outlined'} color={'primary'} onClick={saveSettings} disabled={!changes}>Save</Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default StoreSettingsCard