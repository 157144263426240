import React from "react";
import * as DepositManager from "../managers/DepositManager";
import * as ServiceManager from "../managers/ServiceManager";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Box, Typography, CircularProgress, Link, Button, Alert, Stack } from '@mui/material';
import { useParams } from "react-router-dom";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { DataGrid, GridEditInputCell } from '@mui/x-data-grid';
import { LoadingButton } from "@mui/lab";


function DepositManagementPage() {
    let { domainId } = useParams();
    const [deposits, setDeposits] = React.useState(null);
    const [advanceServiceData, setAdvanceServiceData] = React.useState(null);
    const [inksoftServiceData, setInksoftServiceData] = React.useState(null);

    React.useEffect(() => {
        if (deposits === null) {
            DepositManager.getDeposits(domainId, setDeposits);
        }
    }, [deposits, domainId]);

    React.useEffect(() => {
        if (advanceServiceData === null) {
            ServiceManager.getServiceDocSnapshot(domainId, "advance", setAdvanceServiceData);
        }
    }, [advanceServiceData, domainId]);

    React.useEffect(() => {
        if (inksoftServiceData === null) {
            ServiceManager.getServiceDocSnapshot(domainId, "inksoft", setInksoftServiceData);
        }
    }, [inksoftServiceData, domainId]);

    if (deposits === null || advanceServiceData === null || inksoftServiceData === null) {
        return <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Container sx={{mt: 2, mb: 2}}>
            <TableContainer component={Paper}>
                <Typography variant="h5" textAlign={"center"} m={2}>Deposits</Typography>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Code</TableCell>
                            <TableCell>Gateway</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Net Amount</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {deposits.map((deposit) => (
                        <Row key={deposit.id} domainId={domainId} deposit={deposit} advanceServiceData={advanceServiceData} inksoftServiceData={inksoftServiceData} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

function Row({ domainId, deposit, advanceServiceData, inksoftServiceData }) {
    const [open, setOpen] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const handleRowUpdate = (updated, original) => {
        let newList = [...deposit.transactionList];
        newList[original.index] = {...updated};
        delete newList[original.index].index;
        DepositManager.updateDepositTransactionList(domainId, deposit.id, newList);
        return updated;
    }

    const processBulkDeposit = async () => {
        setProcessing(true);
        try {
            await DepositManager.processBulkDeposit(domainId, deposit.id);
        } catch (error) {
            console.error(error);
        } finally {
            setProcessing(false);
        }
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{deposit.id}</TableCell>
                <TableCell>{deposit.gateway}</TableCell>
                <TableCell>{deposit.timestamp.toDate().toLocaleDateString()}</TableCell>
                <TableCell align="right">{deposit.total ? `\$${deposit.total}` : "N/A"}</TableCell>
                <TableCell>{deposit.status}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ 
                            margin: 1,
                            '& .highlighted-col--cell': {
                                    backgroundColor: 'rgba(156, 39, 176, 0.08)',
                                    fontWeight: '600',
                                },
                            }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Transactions
                            </Typography>
                            {
                                deposit.status === "error" &&
                                <LoadingButton sx={{mb: 1}} loading={processing} variant="outlined" onClick={processBulkDeposit}>Process Deposit</LoadingButton>
                            }
                            {
                                <Stack gap={1} mb={1}>
                                    {
                                        deposit.issues?.map((issue) => <Alert severity="error">{issue}</Alert>)
                                    }
                                </Stack>
                            }
                            <DataGrid
                                rows={deposit.transactionList.map((t, index) => ({...t, index}))}
                                processRowUpdate={handleRowUpdate}
                                rowSelection={false}
                                getRowId={(row) => row.index}
                                columns={[
                                    { field: 'transactionId', headerName: 'Transaction ID', flex: 2 },
                                    { 
                                        field: 'advanceOrderRef',
                                        headerName: 'Advance Order',
                                        flex: 1,
                                        align: 'right',
                                        headerAlign: 'right',
                                        editable: deposit.status !== "success",
                                        cellClassName: (params) => {
                                            if (params.row.advanceOrderOverride) {
                                                return 'highlighted-col--cell';
                                            }
                                        },
                                        renderEditCell: (params) => {
                                            return <GridEditInputCell {...params} value={params.value?.no} />;
                                        },
                                        valueSetter: ({value, row}) => {
                                            if (!!value) {
                                                if (typeof value === "string") {
                                                    return {...row, advanceOrderRef: { no: value }, advanceOrderOverride: true };
                                                } else if (typeof value === "object") {
                                                    return {...row, advanceOrderRef: value, advanceOrderOverride: true };
                                                }
                                            } else {
                                                return {...row, advanceOrderRef: null, advanceOrderOverride: false };
                                            }
                                        },
                                        renderCell: (params) => {
                                            console.log(params.value);
                                            if (params.value?.id) {
                                                return <Link href={`https://${advanceServiceData.subdomain}.anterasaas.com/e-commerce/orders/${params.value.id}`} target="_blank" rel="noopener">{params.value.no}</Link>;
                                            } else if (params.value?.no) {
                                                return params.value.no;
                                            }
                                            return "";
                                        }
                                    },
                                    deposit.gateway === "Inksoft" && 
                                    { 
                                        field: 'inksoftOrderRef',
                                        headerName: 'Inksoft Order',
                                        flex: 1,
                                        align: 'right',
                                        headerAlign: 'right',
                                        renderCell: (params) => {
                                            if (params.value?.id) {
                                                return <Link href={`https://stores.inksoft.com/${inksoftServiceData.mainStoreUrl}/admin/ordermanager/order/${params.value.guid}`} target="_blank" rel="noopener">{params.value.id}</Link>;
                                            } else if (params.value?.no) {
                                                return params.value.id;
                                            }
                                            return "";
                                        }
                                    },
                                    { 
                                        field: 'amount',
                                        headerName: 'Amount',
                                        flex: 1,
                                        align: 'right',
                                        headerAlign: 'right',
                                        valueFormatter: (params) => params.value ? `$${params.value.toFixed(2)}` : ""
                                    },
                                    {
                                        field: 'qboId',
                                        headerName: 'QBO ID',
                                        flex: 1,
                                        align: 'right',
                                        headerAlign: 'right',
                                        editable: deposit.status !== "success",
                                        valueGetter: (params) => {
                                            if (params.row.qboPaymentId) {
                                                return {
                                                    type: "Payment",
                                                    id: params.row.qboPaymentId
                                                }
                                            } else if (params.row.qboExpenseId) {
                                                return {
                                                    type: "Expense",
                                                    id: params.row.qboExpenseId
                                                }
                                            } else {
                                                return null;
                                            }
                                        },
                                        cellClassName: (params) => {
                                            if (params.row.qboIdOverride) {
                                                return 'highlighted-col--cell';
                                            }
                                        },
                                        renderEditCell: (params) => {
                                            return <GridEditInputCell {...params} value={params.value?.id} />;
                                        },
                                        valueSetter: ({value, row}) => {
                                            let field = row.amount > 0 ? "qboPaymentId" : "qboExpenseId";
                                            if (!!value) {
                                                return {...row, [field]: value, qboIdOverride: true };
                                            } else {
                                                return {...row, [field]: null, qboIdOverride: false };
                                            }
                                        },
                                        renderCell: (params) => {
                                            if (params.value?.type === "Payment") {
                                                return <Link href={`https://app.qbo.intuit.com/app/recvpayment?txnId=${params.value.id}`} target="_blank" rel="noopener">{params.value.id}</Link>;
                                            } else if (params.value?.type === "Expense") {
                                                return <Link href={`https://app.qbo.intuit.com/app/expense?txnId=${params.value.id}`} target="_blank" rel="noopener">{params.value.id}</Link>;
                                            }
                                            return "";
                                        }
                                    },
                                ]}
                                autoHeight
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default DepositManagementPage;