import axios from "axios";
import { getAuth } from "firebase/auth";
import { SERVICE_URL } from "../App";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

async function getStores(domainId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/inksoft/getStores`,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data;
    console.log(data);
    return data.data.stores;
}

async function getStoreCommissionSettings(domainId, store_url) {
    const db = getFirestore();
    let serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "inksoft", "commissionSettings", store_url));
    return serviceDoc.data();
};

async function setStoreCommissionSettings(domainId, store_url, data) {
    const db = getFirestore();
    await setDoc(doc(db, "domains", domainId, "services", "inksoft", "commissionSettings", store_url), data);
};

async function updateDashboard(domainId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/inksoft/updateDashboard`,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data;
    console.log(data);
    return data.data.stores;
}

export { getStores, updateDashboard, getStoreCommissionSettings, setStoreCommissionSettings };