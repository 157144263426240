import React, { useMemo } from "react";
import { Autocomplete, Card, TextField, Stack, Typography, CardContent, Alert, FormControlLabel, Switch, InputAdornment } from "@mui/material";
import * as AdvanceManager from "./../managers/AdvanceManager";
import { debounce } from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ProductSelector from "../Components/ProductSelector";
import AccountSelector from "../Components/AccountSelector";

function StoreSettingsCard({ domainId, storeIdentifier, storeSettings, handleStoreSettingsUpdate, serviceData }) {
    let [loadingAdvanceAccounts, setLoadingAdvanceAccounts] = React.useState(false);
    let [advanceAccounts, setAdvanceAccounts] = React.useState([]);
    let [advanceAccountInputValue, setAdvanceAccountInputValue] = React.useState("");
    let [orderStatuses, setOrderStatuses] = React.useState(null);

    let [error, setError] = React.useState({});
    let [errorStr, setErrorStr] = React.useState(null);

    let [presetShippingCost, setPresetShippingCost] = React.useState("");
    let [turnaroundTime, setTurnaroundTime] = React.useState("10");

    const parseNumber = (value) => {
        console.log(value);
        if (typeof value === "string" && value.trim() === "") {
            return null;
        }
        if (typeof value === "string" && value.charAt(value.length - 1) === '.') {
            value = value.slice(0, -1);
        }
        let num = Number(value);
        if (num === null || isNaN(num)) {
            return 0;
        }
        return num;
    };

    React.useEffect(() => {
        setPresetShippingCost((current) => parseNumber(current) !== parseNumber(storeSettings.presetShippingCost ?? "") ? (storeSettings.presetShippingCost ?? "") : current);
    }, [storeSettings.presetShippingCost])

    React.useEffect(() => {
        setTurnaroundTime((current) => parseNumber(current) !== parseNumber(storeSettings.turnaroundTime ?? "10") ? (storeSettings.turnaroundTime ?? "10") : current);
    }, [storeSettings.turnaroundTime])

    React.useEffect(() => {
        let message = Object.values(error).filter(v => v !== null).join(". ");
        setErrorStr(message);
    }, [error]);

    React.useEffect(() => {
        const getOrderStatuses = async () => {
            try {
                let orderStatuses = await AdvanceManager.getOrderKanbanStatuses(domainId);
                setOrderStatuses(orderStatuses);
            } catch (e) {
                console.log(e);
            }
        };
        if (orderStatuses === null) {
            getOrderStatuses();
        }
    }, [domainId, orderStatuses]);

    const updateError = (type, message) => {
        setError((error) => {
            return {
                ...error,
                [type]: message,
            };
        })
    }

    const getAdvanceAccounts = useMemo(() => 
        debounce(
            async (name) => {
                try {
                    updateError("advanceAccount", null)
                    setLoadingAdvanceAccounts(true);
                    const accounts = await AdvanceManager.getAccounts(domainId, name);
                    setAdvanceAccounts(accounts);
                } catch (e) {
                    console.log(e);
                    updateError("advanceAccount", e.toString());
                } finally {
                    setLoadingAdvanceAccounts(false);
                }
            }, 400
        ), [domainId]
    );

    React.useEffect(() => {    
        getAdvanceAccounts(advanceAccountInputValue);
    }, [advanceAccountInputValue, getAdvanceAccounts]);
    
    return (
        <Card
            sx={{
                minWidth: 600
            }}
        >
            <CardContent>
                <Stack
                    gap={2}
                >
                    <Typography gutterBottom textAlign="center" variant="h5" component="div">
                        Store Configuration
                    </Typography>
                    <AccountSelector domainId={domainId} label={"Advance Account"} selectedAccountRef={storeSettings.account ?? null} onUpdate={(value) => handleStoreSettingsUpdate("account", value)} />
                    <Autocomplete
                        disablePortal
                        id="default-status"
                        options={["Pending", "Booked", "Billed"]}
                        value={storeSettings.defaultStatus ?? "Pending"}
                        renderInput={(params) => <TextField {...params} label="Default Status" />}
                        onChange={(_, value) => handleStoreSettingsUpdate("defaultStatus", value)}
                    />
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={storeSettings.estimateShippingCost ?? false}
                                onChange={(_, checked) => handleStoreSettingsUpdate("estimateShippingCost", checked)}
                            />
                        } 
                        label="Estimate Shipping Cost" 
                    />
                    {
                        !storeSettings.estimateShippingCost &&
                        <TextField 
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxlength: 50 }}
                            value={presetShippingCost} label="Preset Shipping Cost"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(event) => {
                                handleStoreSettingsUpdate("presetShippingCost", parseNumber(event.target.value));
                                setPresetShippingCost(event.target.value);
                            }}
                        />
                    }
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={storeSettings.autoImport ?? false}
                                onChange={(_, checked) => handleStoreSettingsUpdate("autoImport", checked)}
                            />
                        } 
                        label="Auto Import" 
                    />
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={storeSettings.noDeco ?? false}
                                onChange={(_, checked) => handleStoreSettingsUpdate("noDeco", checked)}
                            />
                        } 
                        label="No Deco" 
                    />
                    <ProductSelector domainId={domainId} label={"Single Product"} selectedProductId={storeSettings.singleProductId} onUpdate={(value) => handleStoreSettingsUpdate("singleProductId", value)} />
                    <ProductSelector domainId={domainId} label={"Commission Product"} selectedProductId={storeSettings.commissionProductId} onUpdate={(value) => handleStoreSettingsUpdate("commissionProductId", value)} />
                    <ProductSelector domainId={domainId} label={"Prepaid Cost Product"} selectedProductId={storeSettings.prepaidCostProductId} onUpdate={(value) => handleStoreSettingsUpdate("prepaidCostProductId", value)} />
                    <TextField 
                        value={storeSettings.payoutDivisionCheckoutFieldName ?? ""} label="Payout Division Checkout Field Name"
                        onChange={(event) => handleStoreSettingsUpdate("payoutDivisionCheckoutFieldName", event.target.value)}
                    />
                    <ProductSelector domainId={domainId} label={"VIP Discount Product"} selectedProductId={storeSettings.vipDiscountProductId} onUpdate={(value) => handleStoreSettingsUpdate("vipDiscountProductId", value)} />
                    <TextField 
                        value={storeSettings.billingContactId ?? ""} label="Billing Contact ID"
                        onChange={(event) => handleStoreSettingsUpdate("billingContactId", event.target.value)}
                    />
                    {
                        storeSettings.dueDate == null &&
                        <TextField 
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxlength: 50 }}
                            value={turnaroundTime} 
                            label="Turnaround Time"
                            onChange={(event) => {
                                setTurnaroundTime(event.target.value);
                            }}
                            onBlur={(event) => {
                                handleStoreSettingsUpdate("turnaroundTime", parseNumber(event.target.value));
                            }}
                        />
                    }
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            onChange={(d) => handleStoreSettingsUpdate("dueDate", d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                            value={storeSettings.dueDate != null ? dayjs(storeSettings.dueDate, "YYYY-MM-DD") : null}
                            label="Due Date"
                            slotProps={{
                                actionBar: {
                                    actions: ['clear']
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <Autocomplete
                        disablePortal
                        id="default-order-kanban-status"
                        options={orderStatuses?.map(s => s.label) ?? []}
                        value={storeSettings.defaultOrderKanbanStatus == null ? (serviceData.defaultWebstoreOrderKanbanStatus ?? "") : (storeSettings.defaultOrderKanbanStatus ?? "")}
                        renderInput={(params) => <TextField {...params} label="Default Order Kanban Status" />}
                        onChange={(_, value) => handleStoreSettingsUpdate("defaultOrderKanbanStatus", value)}
                    />
                    { errorStr &&
                        <Alert severity="error">{errorStr}</Alert>
                    }
                    
                </Stack>
            </CardContent>
            
        </Card>
    );
}

export default StoreSettingsCard;