import { Button, Card, CardContent, Grid, Stack } from "@mui/material";
import ArtworkSelector from "../Components/ArtworkSelector";
import { useParams } from "react-router-dom";
import DecorationUpdateRow from "./DecorationUpdateRow";

function DecorationUpdateList({ updateData, onUpdateData }) {
    let { domainId } = useParams();

    const handleUpdate = (index) => (data) => {
        console.log(data);
        let newData = [...updateData];
        newData[index] = data;
        console.log(newData);
        onUpdateData(newData);
    }

    const handleDelete = (index) => () => {
        let newData = [...updateData];
        newData.splice(index, 1);
        onUpdateData(newData);
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2} justifyContent={"center"}>
                {
                    updateData.map((entry, index) => (
                        <Grid key={index} item container spacing={2}>
                            <Grid item xs={12}>
                                <DecorationUpdateRow
                                    domainId={domainId}
                                    accountRef={entry.accountRef}
                                    artwork={entry.artwork}
                                    artworkVariationRef={entry.artworkVariationRef}
                                    onUpdate={handleUpdate(index)}
                                    onDelete={handleDelete(index)}
                                />
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid item>
                    <Button variant={"outlined"} onClick={() => onUpdateData([...updateData, {}])}>Add Decoration</Button>
                </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default DecorationUpdateList;