import * as React from 'react';
import NoteIcon from '@mui/icons-material/Note';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import ContactCard from './ContactCard';

function Row(props) {
    const { advanceItem, last } = props;
    const [open, setOpen] = React.useState(true);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <img src={advanceItem.quoteCustomImage[0]} style={{maxWidth: "64px", maxHeight: "64px"}} />
                </TableCell>
                <TableCell>{advanceItem.product.name}</TableCell>
                <TableCell>{advanceItem.product.no}</TableCell>
                <TableCell>{advanceItem.vendor.name}</TableCell>
            </TableRow>
            <TableRow sx={last ? { '& > *': { borderBottom: 'unset' } } : undefined}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack
                            gap={1}
                            mb={1}
                        >
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='body1'>Rows</Typography>
                                </CardContent>
                                <Table size="small" aria-label="child-lines">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>Color</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                            <TableCell align="right">Qty</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {advanceItem.rows.map((row, idx) => (
                                            <TableRow key={idx} sx={ idx === advanceItem.rows.length - 1 ? { '& > *': { border: 0 } } : undefined}>
                                                <TableCell component="th" scope="row" colSpan={2}>
                                                    {row.color}
                                                </TableCell>
                                                <TableCell>{row.size}</TableCell>
                                                <TableCell align="right">{`$${row.cost.toFixed(2)}`}</TableCell>
                                                <TableCell align="right">{`$${row.price.toFixed(2)}`}</TableCell>
                                                <TableCell align="right">{row.quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Card>
                            {
                                advanceItem.decorations.length > 0 &&
                                <Card variant='outlined'>
                                    <CardContent>
                                        <Typography variant='body1'>Decorations</Typography>
                                    </CardContent>
                                    <Table size="small" aria-label="child-lines">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2}>Image</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>No</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Location</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                                <TableCell>Notes</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {advanceItem.decorations.map((deco, idx) => (
                                                <>
                                                    <TableRow key={idx} sx={ idx === advanceItem.decorations.length - 1 ? { '& > *': { border: 0 } } : undefined}>
                                                        <TableCell component="th" scope="row" colSpan={2}>
                                                        <div style={{
                                                            backgroundImage: 'url("https://cdn.inksoft.com/FrontendApps/storefront/assets/images/block-pattern.png"',
                                                            position: "relative",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={deco.images.variation[0]} style={{maxWidth: "64px", maxHeight: "64px"}} />
                                                        </div>
                                                        </TableCell>
                                                        <TableCell>{deco.design.name}</TableCell>
                                                        <TableCell>{deco.design.no}</TableCell>
                                                        <TableCell>{deco.type}</TableCell>
                                                        <TableCell>{deco.location}</TableCell>
                                                        <TableCell align="right">{`$${deco.cost}`}</TableCell>
                                                        <TableCell>
                                                            {
                                                                deco.notes !== "" ? 
                                                                <Tooltip title={deco.notes}>
                                                                    <NoteIcon />
                                                                </Tooltip>
                                                                : <></>
                                                            }
                                                    </TableCell>
                                                    </TableRow>
                                                    {
                                                        idx !== advanceItem.rows.length - 1 && advanceItem.rows.length > 0 &&
                                                        <TableRow>
                                                            <TableCell colSpan={6} height={20} />
                                                        </TableRow>
                                                    }
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Card>
                            }
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function OrderPreviewCard(props) {
    let { orderPreview } = props;

    return (
        <Card variant='outlined'>
            <CardContent>
                <Typography variant={'h5'} textAlign={'center'} gutterBottom>{orderPreview.id}</Typography>
                <Stack
                    direction={'row'}
                    gap={1}
                >
                    <Card variant='outlined'>
                        <CardContent>
                            <Typography variant={'h6'} textAlign={'center'}>Advance Order Details</Typography>
                            <Typography variant='body1'>Order Identity: {orderPreview.advanceOrder.identity}</Typography>
                            <Typography variant='body1'>Order Date: {orderPreview.advanceOrder.orderDate}</Typography>
                            <Typography variant='body1'>Due Date: {orderPreview.advanceOrder.dueDate}</Typography>
                            <Typography variant='body1'>Sales Rep: {orderPreview.advanceOrder.salesRep.name}</Typography>
                            <Typography variant='body1'>CSR: {orderPreview.advanceOrder.csr.name}</Typography>
                            <Typography variant='body1'>Commission Group: {orderPreview.advanceOrder.commissionGroup.name}</Typography>
                        </CardContent>   
                    </Card>
                    <ContactCard advanceContact={orderPreview.advanceContact} />
                </Stack>
            </CardContent>
            <Divider />
            <CardContent>
                <Typography variant='h6'>Advance Items</Typography>
            </CardContent>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Image</TableCell>
                        <TableCell>Product Description</TableCell>
                        <TableCell>Item #</TableCell>
                        <TableCell>Vendor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderPreview.advanceItems.map((advanceItem, idx) => (
                        <Row key={idx} advanceItem={advanceItem} last={idx === advanceItem.length - 1} />
                    ))}
                </TableBody>
            </Table>
            <CardContent>
                <Typography variant='h6'>Inksoft Items</Typography>
            </CardContent>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Product Description</TableCell>
                        <TableCell>Color</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Decorations</TableCell>
                        <TableCell>Commission</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderPreview.items.map((webstoreItem, idx) => (
                        <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                                <img src={webstoreItem.imageUrl} style={{maxWidth: "64px", maxHeight: "64px"}} />
                            </TableCell>
                            <TableCell>{webstoreItem.description} ({webstoreItem.sku})</TableCell>
                            <TableCell>{webstoreItem.colorName} → {webstoreItem.advanceColorName}</TableCell>
                            <TableCell>{webstoreItem.sizeName} → {webstoreItem.advanceSizeName}</TableCell>
                            <TableCell align='right'>{webstoreItem.decorations?.length ?? 0}</TableCell>
                            {
                                webstoreItem.commissionData?.unitCommission &&
                                <TableCell align='right'>{`$${(webstoreItem.commissionData.unitCommission * webstoreItem.quantity).toFixed(2)}`}</TableCell>
                            }
                            {
                                !webstoreItem.commissionData?.unitCommission &&
                                <TableCell>N/A</TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default OrderPreviewCard;
