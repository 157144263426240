import { Button, Container, TextField, Card, CardContent, Stack, Typography, Alert, SvgIcon } from "@mui/material";
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { ReactComponent as GoogleLogo } from "./assets/google_logo.svg";

function LoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const handleEmailUpdate = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordUpdate = (event) => {
        setPassword(event.target.value);
    };

    const submit = async (event) => {
        event?.preventDefault();
        const auth = getAuth();
        try {
            var cred = await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            console.log(e);
            setError(e.toString());
        }
        console.log(cred);
    }

    const loginWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        try {            
            let result = await signInWithPopup(auth, provider);
            // This gives you a Google Access Token. You can use it to access Google APIs.
            GoogleAuthProvider.credentialFromResult(result);
    
            // The signed-in user info.
            // const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        } catch (error) {      
            console.log(error);      
            // Handle Errors here.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        }


    }

    return (
        <Container sx={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Typography gutterBottom textAlign="center" variant="h2" component="div">
                ThreadPath
            </Typography>
            <Card margin={"auto"}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography gutterBottom textAlign="center" variant="h5" component="div">
                        Login
                    </Typography>
                    <form onSubmit={submit}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{padding: "16px"}}
                        >
                            <TextField 
                                id="outlined-basic" 
                                label="Email" 
                                variant="outlined" 
                                onChange={handleEmailUpdate} 
                                autoComplete="email"
                            />
                            <TextField 
                                id="outlined-basic" 
                                label="Password" 
                                variant="outlined" 
                                onChange={handlePasswordUpdate}
                                type="password"
                                autoComplete="current-password"
                            />
                            <Button variant="contained" type="submit" onClick={submit}>Login</Button>
                        </Stack>
                    </form>
                    <Button variant="outlined" startIcon={<SvgIcon fontSize={"small"} component={GoogleLogo} />} onClick={loginWithGoogle}>Sign in with Google</Button>
                </CardContent>
            </Card>
            {error !== null &&
                <Alert sx={{margin: "16px"}} variant="outlined" severity="error">
                    {error}
                </Alert>
            }
            <Typography padding={1} textAlign="center" variant="body1" component="div">
                Created by Logotology
            </Typography>
        </Container>
    );
}

export default LoginPage;