import { Box, FormControl, Switch, InputLabel, Select, MenuItem, Card, CardContent, Stack, Button, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { AgGridReact } from "ag-grid-react";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Circle } from "@mui/icons-material";
import { round } from "lodash";

const sizeGroups = [
    {
        id: 'normal',
        label: 'XS-XL',
        matches: ["XS", "X-Small", "S", "Small", "M", "Medium", "L", "Large", "XL", "X-Large"]
    },
    {
        id: 'toddler',
        label: '2T-4T',
        matches: ["2T", "3T", "4T"]
    },
    {
        id: 'youth',
        label: 'Y2XS-YXL',
        matches: ["Y2XS", "Youth 2XS", "YXS", "Youth XS", "Youth X-Small", "YS", "Youth Small", "YM", "Youth Medium", "YL", "Youth Large", "YXL", "Youth XL", "Youth X-Large"]
    },
];

 class ProductPricingTable extends React.Component {

    constructor(props) {
        super(props);
        var product = this.props.product;
        let commissionsEnabled = false;
        let additionalCostsOn = false;
        for (const style of product.styles) {
            let decoCost = 0;
            for (const di of style.decorations || []) {
                if (di.personalization !== true) {
                    decoCost += di.runChargeCost;
                }
            }
            for (const size of style.sizes) {
                console.log(size);
                size.decoCost = decoCost;
                if (size.commissionData !== null) {
                    commissionsEnabled = true;
                }
                if ((size.additionalCosts ?? []).length > 0) {
                    additionalCostsOn = true;
                }
            }
        }
        let defaultColor = product.styles[0];
        let matchesDefaultList = [];
        for (let color of product.styles) {
            matchesDefaultList.push(this.colorsEqual(defaultColor, color));
        }
        let sizeGroupData = this.getSizeGroupData();
        let gridOptions = {
            domLayout: "autoHeight",
            rowData: [],
            columnDefs: this.getColumns(defaultColor.decorations !== null, commissionsEnabled, additionalCostsOn),
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            getRowId: params => params.data.id,
            getRowStyle: params => {
                if (sizeGroupData[params.data.groupId] !== undefined) {
                    return { background: '#dbffdc' };
                }
                return null;
            }
        };
        
        this.state = {colorIdx: 0, matchesDefault: matchesDefaultList, enableSizeGroups: props.enableSizeGroups, sizeGroupData: sizeGroupData, gridOptions: gridOptions, updateSimilarNext: false, decoCostEnabled: defaultColor.decorations !== null};
        this.onCellValueChanged = this.onCellValueChanged.bind(this);
        this.onCellContextMenu = this.onCellContextMenu.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.setAllColorsToDefault = this.setAllColorsToDefault.bind(this);
        this.getSizeGroupData = this.getSizeGroupData.bind(this);
        this.updateSync = this.updateSync.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.refreshRows = this.refreshRows.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.updateShift = this.updateShift.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getCommission = this.getCommission.bind(this);
        this.getAdditionalCosts = this.getAdditionalCosts.bind(this);
        this.getCommissionFromBasePrice = this.getCommissionFromBasePrice.bind(this);
    }

    getCommission(price, commissionData) {
        if (commissionData?.mode === "PricePercentage") {
            return round(price * commissionData.percentage / 100, 2);
        } else {
            return commissionData?.commission ?? 0;
        }
    }

    getAdditionalCosts(cost, additionalCosts) {
        let total = 0;
        for (const costConfig of additionalCosts) {
            if (costConfig.mode === "ProductCostPercentage") {
                total += cost * costConfig.percentage / 100;
            } else {
                total += costConfig.additionalCost;
            }
        }
        return round(total, 2);
    }

    getCommissionFromBasePrice(basePrice, commissionData) {
        if (commissionData?.mode === "PricePercentage") {
            return round((basePrice * 100 / (100 - commissionData.percentage)) - basePrice, 2);
        } else {
            return commissionData?.commission ?? 0;
        }
    }

    getColumns(decoCostEnabled, commissionEnabled, additionalCostsOn) {
        console.log(decoCostEnabled);
        let decoCostCol = {
            field: 'decoCost',
            // headerName: 'Supplier Cost',
            // width: 100,
            valueFormatter: `"$" + value.toFixed(2)`,
            editable: false
        };
        let commissionCol = {
            field: 'commission',
            // headerName: 'Supplier Cost',
            // width: 100,
            valueFormatter: `"$" + value.toFixed(2)`,
            editable: false,
            valueGetter: params => {
                let commissionData = params.data.commissionData;
                return this.getCommission(params.data.price, commissionData);
            },
        };
        let additionalCostsCol = {
            field: 'additionalCosts',
            // headerName: 'Supplier Cost',
            // width: 100,
            valueFormatter: `"$" + value.toFixed(2)`,
            editable: false,
            valueGetter: params => {
                let additionalCosts = params.data.additionalCosts;
                return this.getAdditionalCosts(params.data.supplierCost, additionalCosts);
            }
        };
        const columns = [
            {
                field: 'size',
                // headerName: 'Size',
                // width: 100,
                // editable: true
            },
            {
                field: 'supplierCost',
                // headerName: 'Supplier Cost',
                // width: 100,
                valueFormatter: `"$" + value.toFixed(2)`,
                valueParser: params => {
                    return parseFloat(params.newValue);
                },
                cellStyle: params => {
                    if (params.data.originalSize !== undefined && params.data.originalSize.supplierCost !== params.value) {
                        return { background: '#d4fcff' };
                    }
                    return null;
                },
                editable: true
            },
            
            {
                field: 'price',
                // headerName: 'Price',
                // width: 100,
                valueFormatter: `"$" + value.toFixed(2)`,
                valueParser: params => {
                    return parseFloat(params.newValue);
                },
                cellStyle: params => {
                    if (params.data.originalSize === undefined) {
                        return {background: "#FF0000"}
                    }
                    if (params.data.originalSize !== undefined && params.data.originalSize.price !== params.value) {
                        return { background: '#d4fcff' };
                    }
                    return null;
                },
                editable: true
            },
            {
                field: 'margin',
                // headerName: 'Supplier Cost',
                // width: 100,
                valueGetter: params => {
                    let blankCost = params.data.supplierCost;
                    if (params.data.decorationCostIncluded) {
                        blankCost -= params.data.decoCost;
                    }
                    blankCost += this.getAdditionalCosts(params.data.supplierCost, params.data.additionalCosts);
                    let commission = this.getCommission(params.data.price, params.data.commissionData);
                    return (((params.data.price - commission - (blankCost + params.data.decoCost)) / (params.data.price - commission)) * 100).toFixed(2) + "%";
                },
                editable: true
            }
        ];
        if (decoCostEnabled) {
            columns.splice(2, 0, decoCostCol);
        }
        if (commissionEnabled) {
            columns.splice(decoCostEnabled ? 3 : 2, 0, commissionCol);
        }
        if (additionalCostsOn) {
            columns.splice(2, 0, additionalCostsCol);
        }
        return columns;
    }

    onGridReady(params) {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
        this.setState({api: params.api}, this.refreshRows);
     };

    refreshRows() {
        let rows = [];
        let ignore = new Set();
        if (this.state.enableSizeGroups) {
            for (let groupId of Object.keys(this.state.sizeGroupData)) {
                let group = this.state.sizeGroupData[groupId];
                if (group.ids.length > 0) {
                    let parentSize = this.props.product.styles[this.state.colorIdx].sizes[group.ids[0]];
                    let originalColor = this.props.originalProduct.styles[this.state.colorIdx];
                    let originalSize = originalColor.sizes[group.ids[0]];
                    rows.push({
                        id: group.ids[0],
                        groupId: groupId,
                        size: group.label,
                        supplierCost: parentSize.supplierCost,
                        decoCost: parentSize.decoCost,
                        commissionData: parentSize.commissionData,
                        additionalCosts: parentSize.additionalCosts,
                        price: parentSize.price,
                        originalSize: originalSize,
                        decorationCostIncluded: this.props.product.styles[this.state.colorIdx].decorationCostIncluded
                    });
                    for (let id of group.ids) {
                        ignore.add(id);
                    }
                }
            }
        }
        let color = this.props.product.styles[this.state.colorIdx];
        let originalColor = this.props.originalProduct.styles[this.state.colorIdx];
        for (let i = 0; i < color.sizes.length; i++) {
            let size = color.sizes[i];
            let originalSize = originalColor.sizes[i];
            if (!ignore.has(i)) {
                let row = {
                    id: i,
                    size: size['name'],
                    supplierCost: size['supplierCost'],
                    decoCost: size.decoCost,
                    commissionData: size.commissionData,
                    additionalCosts: size.additionalCosts,
                    price: size['price'],
                    originalSize: originalSize,
                    decorationCostIncluded: color.decorationCostIncluded
                }
                rows.push(row);
            }
        }
        
        this.state.api.setGridOption("rowData", rows);
        // this.state.api.resetRowHeights();
    }

    getSizeGroupData() {
        let sizeGroupData = {};
        for (let group of sizeGroups) {
            let ids = [];
            for (let i = 0; i < this.props.product.styles[0].length; i++) {
                let size = this.props.product.styles[0].sizes[i];
                if (group.matches.includes(size.name)) {
                    ids.push(i);
                }
            }
            sizeGroupData[group.id] = {label: group.label, ids: ids};
        }
        return sizeGroupData;
    }

    onCellValueChanged(event) {
        console.log(event);
        console.log(this.state.updateSimilarNext);
        let color = this.props.product.styles[this.state.colorIdx];
        color.sizes[event.data.id][event.colDef.field] = Number(event.newValue);
        if (event.colDef.field === "margin") {
            let margin = Number(event.data.margin) / 100;
            let blankCost = event.data.supplierCost;
            if (event.data.decorationCostIncluded) {
                blankCost -= event.data.decoCost;
            }
            blankCost += this.getAdditionalCosts(event.data.supplierCost, event.data.additionalCosts);
            let basePrice = Number(((blankCost + event.data.decoCost) / (1 - margin)));
            console.log(basePrice);
            console.log(this.getCommissionFromBasePrice(basePrice, event.data.commissionData));
            color.sizes[event.data.id]["price"] = round(basePrice + this.getCommissionFromBasePrice(basePrice, event.data.commissionData), 2);
            this.refreshRows();
        }
        let matchesDefaultList = [];
        let defaultColor = this.props.product.styles[0];
        for (let color of this.props.product.styles) {
            matchesDefaultList.push(this.colorsEqual(defaultColor, color));
        }
        if (this.state.enableSizeGroups && event.data.groupId !== undefined && this.state.sizeGroupData[event.data.groupId] !== undefined) {
            this.updateGroup(event.data.groupId);
            this.refreshRows();
        }
        if (this.state.updateSimilarNext) {
            if (event.colDef.field === "margin") {
                for (const size of color.sizes) {
                    let margin = Number(event.data.margin) / 100;
                    let blankCost = size.supplierCost;
                    if (event.data.decorationCostIncluded) {
                        blankCost -= size.decoCost;
                    }
                    blankCost += this.getAdditionalCosts(event.data.supplierCost, event.data.additionalCosts);
                    let basePrice = Number((blankCost + size.decoCost) / (1 - margin));
                    size["price"] = round(basePrice + this.getCommissionFromBasePrice(basePrice, size.commissionData), 2);
                }
            } else {
                this.updateSimilarValue(event.colDef.field, event.oldValue, event.newValue);
            }
            this.refreshRows();
        }
        this.setState({matchesDefault: matchesDefaultList, updateSimilarNext: false});
        // this.props.onValueChanged(this.props.index, event.rowIndex, 'price', parseInt(event.newValue, 10));
    }

    onCellContextMenu(event) {

    }

    updateSimilarValue(field, oldValue, newValue) {
        console.log(newValue);
        let color = this.props.product.styles[this.state.colorIdx];
        for (let size of color.sizes) {
            if (size[field] === oldValue) {
                size[field] = newValue;
            }
        }
    }

    updateGroup(groupId) {
        let sizeIdx = this.state.sizeGroupData[groupId].ids[0];
        let size = this.props.product.styles[this.state.colorIdx].sizes[sizeIdx];
        let ids = this.state.sizeGroupData[groupId].ids;
        console.log(size);
        for (let id of ids) {
            console.log(id);
            let color = this.props.product.styles[this.state.colorIdx];
            color.sizes[id].supplierCost = parseFloat(size.supplierCost);
            color.sizes[id].price = parseFloat(size.price);
        }
    }

    colorsEqual(a, b) {
        for (let i = 0; i < a.sizes.length; i++) {
            let aSize = a.sizes[i];
            let bSize = b.sizes[i];
            if (aSize.supplierCost !== bSize.supplierCost)
                return false;
            if (aSize.price !== bSize.price)
                return false;
        }
        return true;
    }

    handleColorChange(event) {
        let colorIdx = event.target.value;
        this.setState({colorIdx: colorIdx}, this.refreshRows);
    }

    setAllColorsToDefault() {
        let defaultColor = this.props.product.styles[0];
        let newMatchesDefault = [];
        for (let color of this.props.product.styles) {
            for (let i = 0; i < color.sizes.length; i++) {
                let defaultSize = defaultColor.sizes[i];
                let size = color.sizes[i];
                size.supplierCost = defaultSize.supplierCost;
                size.price = defaultSize.price;
            }
            newMatchesDefault.push(true);
        }
        this.setState({matchesDefault: newMatchesDefault});
    }

    updateSync(event) {
        if (event.target.checked) {
            for (let groupId of Object.keys(this.state.sizeGroupData)) {
                if (this.state.sizeGroupData[groupId].ids.length > 0) {
                    this.updateGroup(groupId);
                }
            }
        }
        this.setState({enableSizeGroups: event.target.checked}, this.refreshRows);
    }

    updateShift(params) {
        if (params.event.key === "Enter" && params.event.shiftKey) {
            this.setState({updateSimilarNext: true});
            this.state.api.stopEditing();
        }
    }

    suppress(params) {
        return params.editing && params.event.key === "Enter" && params.event.shiftKey;
    };

    render() {
        let colors = this.props.product.styles;
        let color = colors[this.state.colorIdx];
        let allMatching = this.state.matchesDefault.every((e) => e);
        return (
            <Card sx={{margin: '8px', width: "850px"}}>
                <CardContent>
                    <Typography textAlign="center" variant="h5" component="div">
                        {this.props.product.name}
                    </Typography>
                    <Typography textAlign={'center'}>{this.props.product.sku}</Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Box sx={{paddingBottom: '8px', paddingRight: '8px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center'}}>
                            <img key={color.id} src={color.sides[0].imageUrl} alt={this.props.product.name} style={{maxHeight: "100px", maxWidth: "100px"}} />
                        </Box>
                        <FormControl sx={{width: '300px'}}>
                            <InputLabel id="color-label">Color</InputLabel>
                            <Select
                                labelId="color-label"
                                id="color-select"
                                value={this.state.colorIdx}
                                label="Color"
                                onChange={this.handleColorChange}
                            >
                                {colors.map((c, idx) =>
                                    <MenuItem key={idx} value={idx}>
                                        <Box sx={{display: 'flex'}}>
                                            {c.styleName + (idx === 0 ? " (Default)" : "")}
                                            {!this.state.matchesDefault[idx] &&
                                                <Circle sx={{marginLeft: '8px'}} color="warning" size='small'/>
                                            }
                                        </Box>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                            {this.state.colorIdx === 0 &&
                                <Box margin={1}>
                                    <Button variant="outlined" disabled={allMatching} onClick={this.setAllColorsToDefault}>Set All to Default</Button>
                                </Box>
                            }
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='center'
                        marginBottom={2}
                    >
                        <Box>
                            <FormControlLabel control={<Switch onChange={this.updateSync} />} label="Group Common Sizes" />
                        </Box>
                    </Stack>
                    <div className="ag-theme-alpine" style={{width: 800, marginLeft: 'auto', marginRight: 'auto'}}>
                        <AgGridReact
                            gridOptions={this.state.gridOptions}
                            onCellValueChanged={this.onCellValueChanged}
                            onGridReady={this.onGridReady}
                            onCellContextMenu={this.onCellContextMenu}
                            onCellKeyDown={this.updateShift}
                            onCellKeyUp={this.updateShift}
                            suppressKeyboardEvent={this.suppress}
                        >
                        </AgGridReact>
                    </div>
                </CardContent>
            </Card>
        )
    }
}
export default ProductPricingTable;