import * as React from 'react';
import { Card, CardContent, Typography, Stack, Button, FormControlLabel, Switch } from "@mui/material";
import StyleDialog from "./StyleDialog";
import DecorationInformationForm from './DecorationInformationForm';
import { cloneDeep, isEqual } from 'lodash';
import { red } from '@mui/material/colors';


function DecoManagementCard(props) {
    let { data, suppliers, inksoftStoreUrl, supData, handleUpdate, idx, clipboard, handleClipboardUpdate, artwork, serviceData, personalization } = props;
    const [openStyleDialog, setOpenStyleDialog] = React.useState(false);
    const handleOptionUpdate = (index, field, value) => {
        let newData = {...supData};
        newData.options[index] = {...newData.options[index], [field]: value}
        handleUpdate(idx, newData);
    };

    const handleStyleOverrideUpdate = (style, indexes) => {
        let newData = {...supData};
        if ((isEqual(indexes, [0]) && style.type !== "legacy") || (isEqual(indexes, []) && style.type === "legacy")) {
            delete newData.overrides[style.sku + "-" + style.styleName + "-" + style.side];
        } else {
            newData.overrides[style.sku + "-" + style.styleName + "-" + style.side] = indexes;
        }
        handleUpdate(idx, newData);
    };

    const handleActiveUpdate = () => {
        let newData = {...supData};
        newData.active = !newData.active;
        handleUpdate(idx, newData);
    };

    const handleDelete = (index) => {
        let newData = {...supData};
        let newOptions = [...supData.options];
        let newOverrides = {...supData.overrides};
        for (let id of Object.keys(newOverrides)) {
            newOverrides[id] = newOverrides[id].filter((v) => v !== index);
            for (let i = 0; i < newOverrides[id].length; i++) {
                if (newOverrides[id][i] > index) {
                    newOverrides[id][i] -= 1;
                }
            }
            if (newOverrides[id].length === 0) {
                newOverrides[id].push(0);
            }
        }
        newOptions.splice(index, 1);
        newData.options = newOptions;
        newData.overrides = newOverrides;
        handleUpdate(idx, newData);
    }

    const addOption = () => {
        let newData = {...supData};
        let newOptions = [...supData.options];
        let newOption = cloneDeep(supData.options[0]);
        newOptions.push(newOption);
        newData.options = newOptions;
        handleUpdate(idx, newData);
    }

    const handlePaste = () => {
        let newData = {...supData};
        let newOptions = [...supData.options.filter((o) => o.designName !== ""), ...cloneDeep(clipboard)];
        newData.options = newOptions;
        handleUpdate(idx, newData);
    }

    if (supData === undefined)
        return (<></>);

    return (
        <Card sx={{margin: '8px', width: "800px", backgroundColor: data.styles.length === 0 ? red[50] : undefined}}>
            <CardContent>
                <Stack
                    direction='column'
                    alignItems='center'
                    gap={1}
                >
                    <Typography variant="h5" textAlign="center">{data.name}</Typography>
                    {
                        data.colorwayId !== undefined && <Typography variant="body" textAlign="center">Colorway ID: {data.colorwayId}</Typography>
                    }
                    <Typography textAlign="center">Styles: {data.styles.length}</Typography>
                    {
                        data.imageUrl !== undefined &&
                        <div style={{
                            backgroundImage: 'url("https://cdn.inksoft.com/FrontendApps/storefront/assets/images/block-pattern.png"',
                            width: "300px",
                            height: "300px",
                            position: "relative",
                            textAlign: "center"
                        }}>
                            <img 
                                style={{
                                    inset: 0,
                                    margin: "auto",
                                    position: "absolute",
                                    padding: "1rem",
                                    maxHeight: "90%",
                                    maxWidth: "90%"
                                }}
                                src={data.imageUrl} alt={data.colorwayId} 
                            />
                        </div>
                    }
                    <Button variant="outlined" disabled={data.styles.length === 0} onClick={() => setOpenStyleDialog(true)}>
                        View Styles
                    </Button>
                    <FormControlLabel
                        control={
                            <Switch checked={supData.active} onChange={handleActiveUpdate} name="active" />
                        }
                        label="Enable Decoration"
                    />
                    <Stack
                        direction={"row"}
                        gap={1}
                    >
                        {supData.active && 
                            <Button variant="outlined" size="small" onClick={() => handleClipboardUpdate(supData.options)}>
                                Copy Options
                            </Button>
                        }
                        {
                            supData.active &&
                            <Button variant="outlined" size="small" onClick={handlePaste} disabled={clipboard.length === 0}>
                                Paste Options
                            </Button>
                        }
                    </Stack>
                    {supData.active && supData.options.map((di, idx) => 
                        <DecorationInformationForm 
                            key={idx}
                            suppliers={suppliers}
                            artwork={artwork}
                            decorationInfo={di}
                            handleUpdate={handleOptionUpdate}
                            handleDelete={handleDelete}
                            index={idx}
                            serviceData={serviceData}
                            personalization={personalization}
                        />)}
                    {supData.active && 
                        <Button variant="outlined" onClick={addOption}>
                            Add Option
                        </Button>
                    }
                </Stack>
                <StyleDialog
                    inksoftStoreUrl={inksoftStoreUrl}
                    open={openStyleDialog}
                    styles={data.styles}
                    onClose={() => setOpenStyleDialog(false)}
                    handleUpdate={handleStyleOverrideUpdate}
                    supData={supData}
                />
            </CardContent>
        </Card>
    );
}

export default React.memo(DecoManagementCard);