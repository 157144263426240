import { Container } from "@mui/material";
import TaskList from "../InksoftSyncoreImport/TaskList";

function TaskManagementPage({ db }) {
  return (
    <Container sx={{mt: 2, mb: 2}}>
        <TaskList db={db} />
    </Container>
  );
}

export default TaskManagementPage;