import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Alert, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as AdvanceManager from '../managers/AdvanceManager';
import OrderPreviewCard from './OrderPreviewCard';

function ImportDialog(props) {
    let { open, handleClose, orders } = props;
    let { domainId } = useParams();
    let [orderPreviews, setOrderPreviews] = React.useState([]);
    let [previewsLoading, setPreviewsLoading] = React.useState(false);
    let [error, setError] = React.useState(null);

    React.useEffect(() => {
        const getOrderPreviews = async () => {
            try {
                setPreviewsLoading(true);
                let orderPreviews = await AdvanceManager.getOrderImportPreview(domainId, "Inksoft", orders.map(o => o.guid));
                setOrderPreviews(orderPreviews);
            } catch (e) {
                console.log(e.response.data.message);
                setError(e.response.data.message);
            } finally {
                setPreviewsLoading(false);
            }
        };
        if (open) {
            getOrderPreviews();
        }
    }, [open]);

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={"md"}>
                <DialogTitle>Import Inksoft Orders</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        
                    </DialogContentText>
                    {
                        error !== null && <Alert severity="error">{error}</Alert>
                    }
                    <Stack
                        gap={1}
                        alignItems={"center"}
                    >
                        {
                            previewsLoading && <CircularProgress />
                        }
                        {
                            orderPreviews.map((op, idx) =>
                                <OrderPreviewCard key={idx} orderPreview={op} />
                            )
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>Cancel</Button>
                    <Button onClick={() => handleClose(true)}>Import</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImportDialog;