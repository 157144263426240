import { Box, Card, CardContent, CircularProgress, Container, Dialog, DialogTitle, FormControlLabel, FormGroup, Stack, Switch, Typography } from "@mui/material";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { SERVICE_URL } from "../App";
import { getStores } from "../managers/StoreManager";
import ConsolidatedPOCard from "./ConsolidatedPOCard";
import ImportStoreCard from "./ImportStoreCard";
import JobToOrdersCard from "./JobToOrdersCard";
import TaskList from "./TaskList";
import * as OrderManager from "../managers/InksoftOrderManager";

function ImportManagerPage(props) {
    const { domainId } = useParams();
    let [orders, setOrders] = React.useState([]);
    let [stores, setStores] = React.useState([]);
    let [rawStores, setRawStores] = React.useState([]);
    let [loadingOrders, setLoadingOrders] = React.useState(false);
    let [syncoreData, setSyncoreData] = React.useState(null);
    let [hideImported, setHideImported] = React.useState(false);
    let [taskLoading, setTaskLoading] = React.useState(false);

    let { db } = props;

    React.useEffect(() => {
        const getStoreData = async () => {
            const stores = await getStores(domainId);
            setRawStores(stores.sort((a, b) => a.name.localeCompare(b.name)));
        };
        const getOpenOrders = async () => {
            try {
                setLoadingOrders(true);
                const orders = await OrderManager.getOpenOrders(domainId);
                setOrders(orders);
            } catch (e) {
                console.log(e);
                // setError(e.toString());
            } finally {
                setLoadingOrders(false);
            }
        };
        if (stores.length === 0) {
            getStoreData();
            getOpenOrders();
        }
    }, [stores.length, domainId]);

    React.useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "domains", domainId, "services", "syncoreInksoft"), (doc) => {
            console.log(doc);
            setSyncoreData(doc.data());
            console.log(doc.data()["pendingOrderMap"]);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db]);

    React.useEffect(() => {
        if (rawStores.length !== 0) {
            let storeMap = {};
            for (const store of rawStores) {
                store.orders = [];
                storeMap[store.storeId] = store;
            }
            for (const order of orders) {
                order.dateCreated = new Date(order.dateCreated);
                order.selected = false;
                console.log(order.storeId);
                storeMap[order.storeId].orders.push(order);
            }
            setStores([...rawStores].filter((s) => s.orders.length > 0));
        }
    }, [orders, rawStores]);

    const createImportTask = async (storeId, jobId, clientId) => {
        try {            
            setTaskLoading(true);
            let formData = new FormData();
            if (jobId !== null) {
                formData.append("jobId", jobId)
            }
            formData.append("storeId", storeId);
            formData.append("clientId", clientId);
            formData.append("orderIds", JSON.stringify(orders.filter((o) => o.selected && o.storeId === storeId).map((o) => o.orderId)));
            formData.append("guids", JSON.stringify(orders.filter((o) => o.selected && o.storeId === storeId).map((o) => o.guid)));
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            console.log(token);
            let response = await axios.post(
                `${SERVICE_URL}/syncore/createInksoftOrdersImportTasks`,
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    params: {
                        "domainId": domainId
                    }
                }
            );
            if (response.status !== 200) {
                console.error(response.data);
            } else {
                let newOrders = [...orders];
                newOrders.filter(o => o.selected && o.storeId === storeId).forEach(o => o.selected = false);
                setOrders(newOrders);
            }
            setTaskLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setTaskLoading(false);
        }
    }

    let loading = stores.length === 0 || orders.length === 0;
    return (
        <Container>
            <Dialog open={taskLoading}>
                <DialogTitle>Creating Import Task</DialogTitle>
                <Box sx={{height: "100px", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "36px"}}>
                    <CircularProgress />
                </Box>
            </Dialog>
            {
                loading &&
                <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>   
            }
            {
                !loading &&
                <Stack
                    gap={1}
                    margin={1}
                >
                    {
                        stores.length > 0 && <TaskList db={db} stores={stores} />
                    }
                    <ConsolidatedPOCard />
                    <JobToOrdersCard />
                    <Card sx={{width: "600px", marginLeft: "auto", marginRight: "auto"}}>
                        <CardContent>
                            <Stack
                                direction={"column"}
                                gap={1}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <Typography variant="h5" sx={{marginBottom: 2}} textAlign={"center"}>Options</Typography>
                                <FormGroup>
                                    <FormControlLabel control={<Switch value={hideImported} onChange={(e) => setHideImported(e.target.checked)} />} label="Hide Imported Orders" />
                                </FormGroup>
                            </Stack>
                        </CardContent>
                    </Card>
                    {
                        stores.filter(s => !syncoreData["disabledStoreIds"].includes(s.storeId) && (!hideImported || !s.orders.every(o => syncoreData["pendingOrderMap"][o.orderId] === true))).map((s) =>    
                            <ImportStoreCard 
                                key={s.storeId}
                                db={db}
                                syncoreStoreData={syncoreData["storeDataMap"][s.storeId] || syncoreData["storeDataMap"]["default"]} 
                                store={s} 
                                onImport={createImportTask} 
                                pendingOrderMap={syncoreData["pendingOrderMap"]} 
                                hideImported={hideImported}
                            />
                        )
                    }
                </Stack>
            }
        </Container>
    );
}

export default ImportManagerPage;