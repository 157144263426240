import { AccessTime, AttachMoney, Inbox, List, Refresh } from "@mui/icons-material";
import { Card, Typography, Stack, IconButton, CardContent, Box, ThemeProvider, createTheme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { updateDashboard } from "./managers/StoreManager";

function InksoftDashboard(props) {
    const { db, domainId } = props;
    const [dashboardData, setDashboardData] = React.useState(null);

    React.useEffect(() => {
        const q = doc(db, "domains", domainId, "services", "inksoftDashboard");
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.data();
            setDashboardData(data);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db, domainId]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    let stores = dashboardData?.stores ?? [];
    stores.sort((a, b) => b.value - a.value)

    return (
        <Card
            sx={{
                position: "relative"
            }}
        >
            <Typography variant="h5" textAlign={"center"} marginTop={2}>Store Dashboard</Typography>
            <Box
                sx={{
                    position: "absolute",
                    top: 8,
                    right: 8
                }}
            >
                <IconButton onClick={() => updateDashboard(domainId)}>
                    <Refresh />
                </IconButton>
            </Box>
            <Stack
                direction={"row"}
                m={2}
                spacing={2}
                justifyContent={"center"}
            >
                <DashboardCard 
                    title={"Open Orders"}
                    stat={dashboardData?.summary?.open}
                    icon={<Inbox/>}
                />
                <DashboardCard
                    title={"Overdue Orders"}
                    stat={dashboardData?.summary?.overdue}
                    icon={<AccessTime/>}
                />
                <DashboardCard
                    title={"Open Total"}
                    stat={formatter.format(dashboardData?.summary?.value ?? 0)}
                    icon={<AttachMoney/>}
                />
                <DashboardCard
                    title={"Open Items"}
                    stat={dashboardData?.summary?.quantity}
                    icon={<List/>}
                />
            </Stack>
            <TableContainer component={Card}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Store</TableCell>
                        <TableCell align="right">Open Orders</TableCell>
                        <TableCell align="right">Overdue Orders</TableCell>
                        <TableCell align="right">Open Total</TableCell>
                        <TableCell align="right">Open Quantity</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {stores.map((row) => (
                        <TableRow
                        key={row.storeName}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.storeName}
                        </TableCell>
                        <TableCell align="right">{row.open}</TableCell>
                        <TableCell align="right">{row.overdue}</TableCell>
                        <TableCell align="right">{formatter.format(row.value)}</TableCell>
                        <TableCell align="right">{row.quantity}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
        </Card>
    );
}

function DashboardCard(props) {
    const { title, stat, icon } = props;

    const theme = createTheme({
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: "white"
                    }
                },
                defaultProps: {
                    fontSize: "small"
                }
            }
        }
    });

    return (
        <Card
            elevation={0}
            sx={{ border: '1px solid lightgrey' }}
        >
            <CardContent>
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "primary.light",
                            border: "1px black",
                            borderRadius: "12px",
                            width: "36px",
                            height: "36px",
                            m: 0.5
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            {icon}
                        </ThemeProvider>
                    </Box>
                    <Stack>                    
                        <Typography variant="body2">{title}</Typography>
                        <Typography>{stat}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}


export default InksoftDashboard;