import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEqual, set, unset } from 'lodash'

const initialState = {
  commissionSettings: null,
  originalCommissionSettings: null
}

const selectProductOverrides = state => state.commissionSettings?.productOverrides;
const selectPrepaidCostMap = state => state.commissionSettings?.prepaidCostMap ?? {};
const selectProductId = (state, productId) => productId;

const getProductOverride = createSelector(
  [selectProductOverrides, selectProductId],
  (overrides, productId) => overrides?.[`id${productId}`]
);

const getProductPrepaidCost = createSelector(
  [selectPrepaidCostMap, selectProductId],
  (prepaidCostMap, productId) => prepaidCostMap?.[`id${productId}`] ?? false
);

export const inksoftCommissionSlice = createSlice({
  name: 'inksoftCommission',
  initialState,
  reducers: {
    loadSettings: (state, action) => {
      console.log("load settings");
      state.commissionSettings = action.payload;
      state.originalCommissionSettings = action.payload;
    },
    setEnabled: (state, action) => {
      console.log("set enabled");
      state.commissionSettings.enabled = action.payload;
    },
    setAmount: (state, action) => {
      console.log("set amount");
      state.commissionSettings.amount = action.payload;
    },
    setPercentage: (state, action) => {
      console.log("set percentage");
      state.commissionSettings.percentage = action.payload;
    },
    setMode: (state, action) => {
      console.log("set mode");
      state.commissionSettings.mode = action.payload;
    },
    setVipDiscountCommissionEnabled: (state, action) => {
      state.commissionSettings.vipDiscountCommissionEnabled = action.payload;
    },
    updateOverride: (state, action) => {
      console.log("update override");
      let { product, style, size, field, value, storeValue } = action.payload;
      console.log(action.payload);
      if (value === storeValue) {
        unset(state.commissionSettings, [`productOverrides`, `id${product.id}`, `id${style.styleId}`, `id${size.id}`, field]);
      } else {
        set(state.commissionSettings, [`productOverrides`, `id${product.id}`, `id${style.styleId}`, `id${size.id}`, field], value);
      }
    },
    updatePrepaidCost: (state, action) => {
      console.log("update prepaid cost");
      let { product, value } = action.payload;
      set(state.commissionSettings, [`prepaidCostMap`, `id${product.id}`], value);
    },
    clearAllOverrides: (state) => {
      console.log("clear all overrides");
      state.commissionSettings.productOverrides = {}
    }
  },
  selectors: {
    getCommissionSettings: (state) => state.commissionSettings,
    commissionSettingsLoaded: (state) => state.commissionSettings !== null,
    getProductOverrides: (state, productId) => getProductOverride(state, productId),
    isChanges: (state) => !isEqual(state.commissionSettings, state.originalCommissionSettings),
    getPrepaidCost: (state, productId) => getProductPrepaidCost(state, productId)
  }
})

// Action creators are generated for each case reducer function
export const { actions: { loadSettings, setEnabled, setAmount, setPercentage, setMode, setVipDiscountCommissionEnabled, updateOverride, clearAllOverrides, updatePrepaidCost }, selectors: { getCommissionSettings, commissionSettingsLoaded, getProductOverrides, isChanges, getPrepaidCost } } = inksoftCommissionSlice;


export default inksoftCommissionSlice.reducer