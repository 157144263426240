import React from "react";
import { Container, Typography, Grid, CircularProgress } from "@mui/material";
import ToolCard from "./ToolCard";
import { Box } from "@mui/system";
import { AttachMoney, Inventory, Palette, Input as InputIcon, Payments, Style, LocalShipping, Settings, Payment, SettingsApplications, PriceChange, ListAlt, AccountBalance, ManageSearch } from "@mui/icons-material";
import InksoftDashboard from "./InksoftDashboard";

function HomePage(props) {
    let { user, db, domain, domainId } = props;    

    if (domainId == null) {
        return (
            <Container>
                <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }
    return (
        <Container>
            <Typography variant="h4" textAlign={"center"} m={2}>Modules</Typography>
            <Grid container
                gap={2}
                justifyContent={"center"}
                alignItems={"stretch"}
            >
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Pricing"}
                        category={"Inksoft"}
                        icon={<AttachMoney/>}
                        description={"Edit supplier cost and price for all products in Inksoft stores"}
                        link={`/domains/${domainId}/pricing`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Inventory"}
                        category={"Inksoft"}
                        icon={<Inventory/>}
                        description={"Edit product inventory and set up auto sync from suppliers for Inksoft stores"}
                        link={`/domains/${domainId}/inventory`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Decoration Mapping"}
                        category={"Inksoft"}
                        icon={<Palette/>}
                        description={"Manage Decoration Information for Inksoft stores"}
                        link={`/domains/${domainId}/inksoftdecoration`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Commission"}
                        category={"Inksoft"}
                        icon={<Payments/>}
                        description={"Manage commissions for Inksoft"}
                        link={`/domains/${domainId}/inksoftcommission`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Advance Import"}
                        category={"Advance"}
                        icon={<InputIcon/>}
                        description={"Import Inksoft orders into Advance"}
                        link={`/domains/${domainId}/inksoftadvanceorders`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Advance Settings"}
                        category={"Advance"}
                        icon={<Settings/>}
                        description={"Configure store settings and product matching between Inksoft and Advance"}
                        link={`/domains/${domainId}/inksoftadvanceproducts`}
                    />
                </Grid>
                {/* <Grid item>
                    <ToolCard 
                    title={"Inksoft Syncore Import"}
                    category={"Syncore"}
                    icon={<InputIcon/>}
                    description={"Import Inksoft orders into Syncore"}
                    link={`/domains/${domainId}/inksoftsyncoreimport`}
                    />
                </Grid> */}
                <Grid item>
                    <ToolCard 
                        title={"Shipment Manager"}
                        category={"Shipping"}
                        icon={<LocalShipping/>}
                        description={"Manage Shipments"}
                        link={`/domains/${domainId}/shipmentmanagement`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Payout Manager"}
                        category={"Payouts"}
                        icon={<PriceChange/>}
                        description={"Manage Payouts to Clients."}
                        link={`/domains/${domainId}/payoutmanagement`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Payment Terminal"}
                        category={"Payments"}
                        icon={<Payment/>}
                        description={"Take payments using the terminal"}
                        link={`/domains/${domainId}/paymentterminal`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Deposits"}
                        category={"Payments"}
                        icon={<AccountBalance/>}
                        description={"Manage Deposits"}
                        link={`/domains/${domainId}/depositmanagement`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Inksoft Store Audit"}
                        category={"Inksoft"}
                        icon={<Style/>}
                        description={"Audit Inksoft stores to find issues related to pricing, inventory and supplier info."}
                        link={`/domains/${domainId}/storeaudit`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Advance Product Tools"}
                        category={"Purchasing"}
                        icon={<Style/>}
                        description={"Tools to manipulate Advance products."}
                        link={`/domains/${domainId}/advanceproducttools`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Advance Order Bulk Actions"}
                        category={"Advance"}
                        icon={<ListAlt/>}
                        description={"Manage Advance Orders in Bulk"}
                        link={`/domains/${domainId}/advanceorderbulkactions`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Advance Settings"}
                        category={"Advance"}
                        icon={<SettingsApplications/>}
                        description={"Manage Settings for Advance"}
                        link={`/domains/${domainId}/advancesettings`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Syncore Export Data"}
                        category={"Syncore"}
                        icon={<InputIcon/>}
                        description={"View Syncore Orders"}
                        link={`/domains/${domainId}/syncoreexport`}
                    />
                </Grid>
                <Grid item>
                    <ToolCard 
                        title={"Task Manager"}
                        category={"Admin"}
                        icon={<ManageSearch/>}
                        description={"View All Tasks"}
                        link={`/domains/${domainId}/taskmanagement`}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    m: 2,
                    width: "900px",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}
            >

                <InksoftDashboard db={db} domainId={domainId} />
            </Box>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0
                }}
            >
                <Typography margin={1}>Version: 2.15.0</Typography>
            </Box>
        </Container>
    );
}

export default HomePage;