import { BrokenImage, QuestionMark } from "@mui/icons-material";
import React from "react";
import { useImage } from "react-image";

function ArtworkImage({ imageUrl, style }) {
    
    return (
        <ErrorBoundary>
            <ImageComponent imageUrl={imageUrl} style={style} />
        </ErrorBoundary>
    );
};

function ImageComponent({ imageUrl, style }) {
    let {src, isLoading, error} = useImage({
        srcList: imageUrl,
        useSuspense: false
    });
    if (error) return <BrokenImage />
    return <img src={src} style={style} />;
};

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <BrokenImage />
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

export default ArtworkImage;