import { Box, Switch, Card, CardContent, Stack, Button, FormControlLabel, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Select, MenuItem, Alert } from "@mui/material";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import { ExitToApp } from "@mui/icons-material";

class ProductInventoryTable extends React.Component {

    constructor(props) {
        super(props);
        let gridOptions = {
            domLayout: "autoHeight",
            rowData: [],
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            getRowId: params => params.data.id,
        }
        let matchSupplier = this.inventoryEqual();
        this.onCellValueChanged = this.onCellValueChanged.bind(this);
        this.onCellContextMenu = this.onCellContextMenu.bind(this);
        this.refreshRows = this.refreshRows.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.updateShift = this.updateShift.bind(this);
        this.setToSupplierInventory = this.setToSupplierInventory.bind(this);
        this.inventoryEqual = this.inventoryEqual.bind(this);
        this.onSyncChanged = this.onSyncChanged.bind(this);
        this.onStyleOverrideChange = this.onStyleOverrideChange.bind(this);
        let color = this.props.product.styles[0];
        let sizes = color.sizes;
        let columnDefs = [
            {
                colId: 'color',
                field: 'color',
                headerName: 'Color',
                // minWidth: 150,
                pinned: 'left',
                editable: false
            },
        ];
        for (let i = 0; i < sizes.length; i++) {
            let size = sizes[i];
            columnDefs.push(
                {
                    field: size.name,
                    // headerName: 'Price',
                    colId: i,
                    minWidth: 80,
                    flex: 1,
                    cellStyle: params => {
                        let value = parseFloat(params.value);
                        if (isNaN(value)) {
                            value = null;
                        }
                        if (!params.data.originalColor.sizes[i].inStock || !params.data.originalColor.sizes[i].active) {
                            return { background: '#ffc0c1' };
                        } else if (params.data.originalColor.sizes[i] !== undefined && params.data.originalColor.sizes[i].localInventory !== value) {
                            return { background: '#d4fcff' };
                        }
                        return null;
                    },
                    editable: true
                }
            );
        }

        this.state = {columnDefs: columnDefs, colorIdx: 0, matchSupplier: matchSupplier, gridOptions: gridOptions, updateSimilarNext: false};

    }

    onGridReady(params) {
        // Following line to make the currently visible columns fit the screen  
        // params.api.sizeColumnsToFit();
        this.setState({api: params.api}, this.refreshRows);
        params.api.columnApi.autoSizeColumn("color");
     };

    refreshRows() {
        let rows = [];
        for (let i = 0; i < this.props.product.styles.length; i++) {
            let color = this.props.product.styles[i];
            let originalColor = this.props.originalProduct.styles[i];
            let row = {
                id: i,
                color: color.styleName,
                originalColor: originalColor,
            }
            for (let size of color.sizes) {
                row[size.name] = size.localInventory;
            }
            rows.push(row);
        }
        this.state.api.setRowData(rows);

    }

    inventoryEqual() {
        for (let color of this.props.product.styles) {
            for (let size of color.sizes) {
                if (size.localInventory !== size.quantityAvailable) {
                    // console.log(color.name);
                    // console.log(size.name);
                    // console.log(size.localInventory);
                    // console.log(size.supplierInventory);
                    
                    return false;
                }
            }
        }
        return true;
    }

    onCellValueChanged(event) {
        console.log("row Index: " + event.rowIndex);
        console.log("col idx: " + event.colDef.colId);
        let newValue = parseFloat(event.newValue);
        if (isNaN(newValue)) {
            newValue = null;
        }
        console.log("original value: " + this.props.originalProduct.styles[event.rowIndex].sizes[event.colDef.colId].localInventory);
        console.log("new value: " + newValue);
        this.props.product.styles[event.rowIndex].sizes[event.colDef.colId].localInventory = newValue;
        let matchSupplier = this.inventoryEqual();
        console.log(matchSupplier);
        if (this.state.updateSimilarNext) {
            this.updateSimilarValue(event.colDef.field, event.oldValue, event.newValue);
            this.refreshRows();
        }
        this.setState({matchSupplier: matchSupplier, updateSimilarNext: false});
        // this.props.onValueChanged(this.props.index, event.rowIndex, 'price', parseInt(event.newValue, 10));
    }

    onCellContextMenu(event) {

    }

    onSyncChanged(value) {
        let sku = this.props.product.sku;
        if (this.props.syncData[sku] === undefined) {
            this.props.syncData[sku] = {
                'syncEnabled': false
            }
        }
        this.props.syncData[sku]['syncEnabled'] = value;
    }

    onStyleOverrideChange(inksoftName, supplierName) {
        let sku = this.props.product.sku;
        if (this.props.syncData[sku] === undefined) {
            this.props.syncData[sku] = {
                'syncEnabled': false,
            }
        }
        if (this.props.syncData[sku]["colorOverrides"] === undefined) {
            this.props.syncData[sku]["colorOverrides"] = {};
        }
        this.props.syncData[sku]["colorOverrides"][inksoftName] = supplierName;
    }

    setToSupplierInventory() {
        for (let color of this.props.product.styles) {
            for (let size of color.sizes) {
                size.localInventory = size.quantityAvailable;
            }
        }
        this.refreshRows();
        this.setState({matchSupplier: true});
    }

    updateSimilarValue(field, oldValue, newValue) {
        let color = this.props.product.styles[this.state.colorIdx];
        for (let size of color.sizes) {
            if (size[field] === oldValue) {
                size[field] = newValue;
            }
        }
    }

    updateShift(params) {
        if (params.event.key === "Enter" && params.event.shiftKey) {
            this.setState({updateSimilarNext: true});
            this.state.api.stopEditing();
        }
    }

    suppress(params) {
        return params.editing && params.event.key === "Enter" && params.event.shiftKey;
    };

    render() {
        let color = this.props.product.styles[0];
        let anyFound = false;
        let mskuIssue = false;
        for (let color of this.props.product.styles) {
            for (let size of color.sizes) {
                anyFound |= size.matchFound;
                if (size.matchError === "Error getting data from supplier") {
                    mskuIssue = true;
                }
            }
        }
        let autoSync = this.props.syncData[this.props.product.sku] !== undefined && this.props.syncData[this.props.product.sku]['syncEnabled'] === true;
        let inventorySetting = "Inventory NOT Enforced";
        if (this.props.product.enforceLocalInventory) {
            inventorySetting = "Local Inventory Enforced";
        }
        if (this.props.product.enforceSupplierInventory) {
            inventorySetting = "BAD Supplier Inventory Enforced";
        }
        let unmatchedStyles = this.props.product.styles.filter(s => s.matchFound === false || (this.props.syncData[this.props.product.sku]?.colorOverrides !== undefined && this.props.syncData[this.props.product.sku].colorOverrides[s.styleName] !== undefined));
        unmatchedStyles = unmatchedStyles.filter(us => (us.matchOptions ?? []).length > 0);
        console.log(this.props.product);
        return (
            <Card sx={{margin: '8px', width: "1000px"}}>
                <CardContent sx={{justifyContent: "center"}}>
                    <Typography textAlign="center" variant="h5" component="div">
                        {this.props.product.name}
                    </Typography>
                    <Typography textAlign={'center'}>{this.props.product.sku}</Typography>
                    <Typography textAlign={'center'}>{this.props.product.supplier}</Typography>
                    <Typography textAlign={'center'}>{inventorySetting}</Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-evenly'
                    >
                        <Box sx={{paddingBottom: '8px', paddingRight: '8px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center'}}>
                            <img key={color.id} src={color.sides[0].imageUrl} alt={this.props.product.name} style={{maxHeight: "100px", maxWidth: "100px"}} />
                        </Box>
                        {anyFound ?
                            <Box margin={1}>
                                <Button variant="outlined" disabled={this.state.matchSupplier} onClick={this.setToSupplierInventory}>Set Inventory to Supplier</Button>
                            </Box>
                        : <></>}
                        {anyFound ?
                            <FormControlLabel control={<Switch defaultChecked={autoSync} />} label="Auto Sync" onChange={(_, value) => this.onSyncChanged(value)} />
                        : <></>}
                        {
                            !this.props.product.decorated && 
                            <IconButton variant="outlined" size="small" href={`https://logotologist.com/logotology_designer/Config/ManageEditProduct?product_id=${this.props.product.id}&hdnFilters=%7C`} target="_blank">
                                <ExitToApp/>
                            </IconButton>
                        }
                        {
                            this.props.product.decorated &&
                            <IconButton variant="outlined" size="small" href={`https://logotologist.com/${this.props.storeUrl}/shop-admin/edit-product/${this.props.product.id}`} target="_blank">
                                <ExitToApp/>
                            </IconButton>
                        }
                    </Stack>
                    {anyFound ?
                        <TableContainer>
                            <Table sx={{ width: 900, marginLeft: 'auto', marginRight: 'auto', marginBottom: "32px" }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Color</TableCell>
                                    {this.props.product.styles[0].sizes.map((size) => (
                                        <TableCell key={size.id} align="right">{size.name}</TableCell>
                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.props.product.styles.map((color) => (
                                    <TableRow
                                    key={color.styleName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {color.matchName}
                                        </TableCell>
                                        {color.sizes.map((size) => (
                                            <TableCell align="right">{size.quantityAvailable}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    : <></>}
                    <div className="ag-theme-alpine" style={{width: 900, marginLeft: 'auto', marginRight: 'auto'}}>
                        <AgGridReact
                            key={this.props.product.id + "grid"}
                            columnDefs={this.state.columnDefs}
                            gridOptions={this.state.gridOptions}
                            onCellValueChanged={this.onCellValueChanged}
                            onGridReady={this.onGridReady}
                            onCellContextMenu={this.onCellContextMenu}
                            onCellKeyDown={this.updateShift}
                            onCellKeyUp={this.updateShift}
                            suppressKeyboardEvent={this.suppress}
                        >
                        </AgGridReact>
                    </div>
                    {this.props.product.matchFound && unmatchedStyles.length > 0 &&        
                        <TableContainer>
                            <Table sx={{ width: 900, marginLeft: 'auto', marginRight: 'auto', marginBottom: "32px" }}>
                                <TableHead>
                                <TableRow>
                                    <TableCell>Inksoft Color</TableCell>
                                    <TableCell>Supplier Color</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {unmatchedStyles.map((s) => (
                                    <TableRow
                                    key={s.styleId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {s.styleName}
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                value={this.props.syncData[this.props.product.sku]?.colorOverrides?.[s.styleName]}
                                                onChange={(e) => this.onStyleOverrideChange(s.styleName, e.target.value)}
                                            >
                                                {s.matchOptions?.map(mo =>
                                                    <MenuItem key={mo} value={mo}>{mo}</MenuItem>
                                                ) ?? []}
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>            
                    }
                    {mskuIssue &&
                        <Alert sx={{margin: "16px"}} severity="error">Check manufacturer skus for this product.</Alert>
                    }
                </CardContent>
            </Card>
        )
    }
}
export default ProductInventoryTable;