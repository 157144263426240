import { collection, doc, getDoc, getFirestore, onSnapshot, query, where } from "firebase/firestore";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "syncoreexport"));
    return serviceDoc.data();
}

function getSyncoreJobs(domainId, business, setSyncoreJobs) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "syncoreexport", "jobs"), where("client.business_name", "==", business));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const jobs = querySnapshot.docs.map((doc) => {return doc.data()});
        setSyncoreJobs(jobs);
    }, (error) => console.log(error));
    return unsubscribe;
}

export { getServiceDoc, getSyncoreJobs };