import axios from "axios";
import { getAuth } from "firebase/auth";
import { collection, doc, getFirestore, limit, onSnapshot, orderBy, query, updateDoc } from "firebase/firestore";
import { SERVICE_URL } from "../App";

function getDeposits(domainId, setDeposits) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "qbo", "bulkDeposits"), orderBy("timestamp", "desc"), limit(50));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const bulkDeposits = querySnapshot.docs.map((doc) => doc.data());
        console.log(bulkDeposits);
        setDeposits(bulkDeposits);
    }, (error) => console.log(error));
    return unsubscribe;
}

function updateDepositTransactionList(domainId, depositId, transactionList) {
    const db = getFirestore();
    const depositRef = doc(db, "domains", domainId, "services", "qbo", "bulkDeposits", depositId);
    updateDoc(depositRef, {
        transactionList: transactionList
    });
}

async function processBulkDeposit(domainId, depositId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("depositId", depositId);
    await axios.post(
        `${SERVICE_URL}/qbo/processBulkDeposit`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
}

export { getDeposits, updateDepositTransactionList, processBulkDeposit };