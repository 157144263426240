import { Box, Card, CardContent, CircularProgress, Container, Dialog, DialogTitle, FormControlLabel, FormGroup, Stack, Switch, Typography } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { getStores } from "../managers/StoreManager";
import ImportStoreCard from "./ImportStoreCard";
// import JobToOrdersCard from "./JobToOrdersCard";
import * as OrderManager from "../managers/InksoftOrderManager";
import * as AdvanceManager from "../managers/AdvanceManager";
import TaskList from "../InksoftSyncoreImport/TaskList";

function InksoftAdvancePage(props) {
    const { domainId } = useParams();
    let [orders, setOrders] = React.useState([]);
    let [stores, setStores] = React.useState([]);
    let [rawStores, setRawStores] = React.useState([]);
    let [loadingOrders, setLoadingOrders] = React.useState(false);
    let [inksoftAdvanceData, setInksoftAdvanceData] = React.useState(null);
    let [hideImported, setHideImported] = React.useState(false);
    let [taskLoading, setTaskLoading] = React.useState(false);

    let { db } = props;

    React.useEffect(() => {
        const getStoreData = async () => {
            const stores = await getStores(domainId);
            setRawStores(stores.sort((a, b) => a.name.localeCompare(b.name)));
        };
        const getOpenOrders = async () => {
            try {
                setLoadingOrders(true);
                const orderSummaries = await OrderManager.getOpenOrders(domainId);
                setOrders(orderSummaries);
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingOrders(false);
            }
        };
        if (stores.length === 0) {
            getStoreData();
            getOpenOrders();
        }
    }, [stores.length, domainId]);

    React.useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "domains", domainId, "services", "inksoftAdvance"), (doc) => {
            console.log(doc);
            setInksoftAdvanceData(doc.data());
        }, (error) => console.log(error));
        return () => unsubscribe();
    }, [db, domainId]);

    React.useEffect(() => {
        if (rawStores.length !== 0) {
            let storeMap = {};
            for (const store of rawStores) {
                store.orders = [];
                storeMap[store.storeId] = store;
            }
            for (const order of orders) {
                order.dateCreated = new Date(order.dateCreated);
                order.selected = false;
                console.log(order.storeId);
                storeMap[order.storeId].orders.push(order);
            }
            setStores([...rawStores].filter((s) => s.orders.length > 0));
        }
    }, [orders, rawStores]);

    const createImportTask = async (inksoftStoreId, orders) => {
        try {
            setTaskLoading(true);
            let taskGroupIds = await AdvanceManager.importWebstoreOrders(domainId, "Inksoft", orders.map((o) => o.guid));
            let newOrders = [...orders];
            newOrders.filter(o => o.selected && o.storeId === inksoftStoreId).forEach(o => o.selected = false);
            setOrders(newOrders);
        } catch (error) {
            console.error(error);
        } finally {
            setTaskLoading(false);
        }
    }

    let loading = stores.length === 0 || orders.length === 0 || loadingOrders || inksoftAdvanceData === null;
    return (
        <Container>
            <Dialog open={taskLoading}>
                <DialogTitle>Creating Import Task</DialogTitle>
                <Box sx={{height: "100px", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "36px"}}>
                    <CircularProgress />
                </Box>
            </Dialog>
            {
                loading &&
                <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>   
            }
            {
                !loading &&
                <Stack
                    gap={1}
                    margin={1}
                >
                    {
                        stores.length > 0 && <TaskList db={db} types={["createAdvanceOrder"]} />
                    }
                    {/* <JobToOrdersCard /> */}
                    <Card sx={{width: "600px", marginLeft: "auto", marginRight: "auto"}}>
                        <CardContent>
                            <Stack
                                direction={"column"}
                                gap={1}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <Typography variant="h5" sx={{marginBottom: 2}} textAlign={"center"}>Options</Typography>
                                <FormGroup>
                                    <FormControlLabel control={<Switch value={hideImported} onChange={(e) => setHideImported(e.target.checked)} />} label="Hide Imported Orders" />
                                </FormGroup>
                            </Stack>
                        </CardContent>
                    </Card>
                    {
                        stores.filter(s => !inksoftAdvanceData["disabledStoreIds"].includes(s.storeId) && (!hideImported || !s.orders.every(o => inksoftAdvanceData["pendingOrderMap"][o.orderId] === true))).map((s) =>    
                            <ImportStoreCard 
                                key={s.storeId}
                                db={db}
                                store={s}
                                allOrders={orders}
                                onImport={createImportTask} 
                                pendingOrderMap={inksoftAdvanceData["pendingOrderMap"]} 
                                hideImported={hideImported}
                            />
                        )
                    }
                </Stack>
            }
        </Container>
    );
}

export default InksoftAdvancePage;