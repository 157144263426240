import React from "react";
import * as AdvanceManager from "../managers/AdvanceManager";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";
import ArtworkImage from "../Decoration/ArtworkImage";

const ArtworkSelector = ({ domainId, accountId, selectedArtworkRef, label, onUpdate }) => {
    const [artworks, setArtworks] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    
    const getAccountArtworks = async () => {
        try {
            setLoading(true);
            setError(null);
            const artworks = await AdvanceManager.getAccountArtwork(domainId, accountId);
            setArtworks(artworks);
        } catch (e) {
            console.log(e);
            setError(e.toString());
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setArtworks(null);
    }, [domainId, accountId]);

    React.useEffect(() => {
        if (selectedArtworkRef == null) {
            setArtworks(null);
        }
    }, [selectedArtworkRef]);

    return (
        <Autocomplete
            value={selectedArtworkRef ?? null}
            getOptionLabel={(option) => `${option?.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={artworks ?? (selectedArtworkRef ? [selectedArtworkRef] : [])}
            loading={loading}
            filterOptions={(options, state) => options.filter((o) => o.name.toLowerCase().includes(state.inputValue?.toLowerCase()) || o.designNo.toLowerCase().includes(state.inputValue?.toLowerCase()))}
            onChange={(_, value) => {
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate({
                        ref: {
                            "id": value.id,
                            "designNo": value.designNo,
                            "name": value.name,
                            "thumbnailUrl": value.thumbnailUrl,
                        },
                        variations: value.variations
                    });
                }
            }}
            onFocus={() => {
                if (artworks === null)
                    getAccountArtworks();
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    <Box
                        sx={{
                            width: "128px",
                            height: "128px",
                            mr: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {option.imageUrl &&                                
                            <ArtworkImage
                                imageUrl={option.thumbnailUrl}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                        }
                    </Box>
                    {option.name + " (" + option.designNo + ")"}
                </Box>
            )}
            renderInput={(params) => {
                return (
                    <TextField 
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                    artworks !== null &&
                                    selectedArtworkRef != null &&
                                    <ArtworkImage 
                                        style={{
                                            maxWidth: "32px",
                                            maxHeight: "32px"
                                        }}
                                        imageUrl={selectedArtworkRef?.thumbnailUrl}
                                    />
                            ),
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                );
            }}
        />
    );
}

export default ArtworkSelector;