import { Card, Box, CircularProgress, Container, CardContent, Typography, TextField, Stack, Alert, Autocomplete } from "@mui/material";
import React from "react";
import * as SyncoreManager from "../managers/SyncoreManager";
import { useParams } from "react-router-dom";
import SalesOrderTable from "./SalesOrderTable";
import * as PaymentManager from "../managers/PaymentManager";
import SyncorePaymentIntentsTable from "./SyncorePaymentIntentsTable";
import { LoadingButton } from "@mui/lab";

const isAmountValid = (string) => {
    if (string === "") return false;
    return !isNaN(string);
};

function PaymentTerminalPage(props) {
    const { domainId, db } = useParams();
    let [jobId, setJobId] = React.useState("");
    let [amountString, setAmountString] = React.useState("");
    let [description, setDescription] = React.useState("");
    let [jobError, setJobError] = React.useState(null);
    let [soLoading, setSOLoading] = React.useState(false);
    let [captureError, setCaptureError] = React.useState(null);
    let [manualCaptureError, setManualCaptureError] = React.useState(null);
    let [clearError, setClearError] = React.useState(null);
    let [salesOrders, setSalesOrders] = React.useState(null);
    let [selected, setSelected] = React.useState([]);
    let [readers, setReaders] = React.useState(null);
    let [readerId, setReaderId] = React.useState(null);
    let [captureLoading, setCaptureLoading] = React.useState(false);
    let [clearLoading, setClearLoading] = React.useState(false);
    let [amountValid, setAmountValid] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            const serviceDoc = await PaymentManager.getServiceDoc(domainId);
            setReaders(serviceDoc["readers"]);
            setReaderId(serviceDoc["readers"][0].id);
        };
        getData();
    }, [db, domainId]);

    React.useEffect(() => {
        console.log(amountString);
        console.log(isAmountValid(amountString));
        setAmountValid(isAmountValid(amountString));
    }, [amountString]);

    const getSalesOrders = async () => {
        try {
            setSOLoading(true);
            const salesOrders = await SyncoreManager.getSalesOrders(domainId, jobId);
            setSalesOrders(salesOrders);
            setSelected([]);
            setJobError(null);
        } catch (error) {
            setJobError(error.toString());
        } finally {
            setSOLoading(false);
        }
    };

    const capturePayment = async () => {
        try {
            setCaptureLoading(true);
            setCaptureError(null);
            await PaymentManager.captureSyncorePayment(domainId, jobId, selected, readerId);
            setJobId("");
            setAmountString("");
            setSalesOrders(null);
            setDescription("");
            setSelected([]);
        } catch (error) {
            setCaptureError(error.toString());
        } finally {
            setCaptureLoading(false);
        }
    };

    const captureManualPayment = async () => {
        try {
            setCaptureLoading(true);
            setManualCaptureError(null);
            if (description === "") {
                description = "N/A";
            }
            await PaymentManager.captureManualPayment(domainId, parseFloat(amountString), description, readerId);
            setJobId("");
            setAmountString("");
            setSalesOrders(null);
            setDescription("");
            setSelected([]);
        } catch (error) {
            setManualCaptureError(error.toString());
        } finally {
            setCaptureLoading(false);
        }
    };

    const clearReader = async () => {
        try {
            setClearLoading(true);
            setClearError(null);
            await PaymentManager.clearStripeReader(domainId, readerId);
        } catch (error) {
            setClearError(error.toString());
        } finally {
            setClearLoading(false);
        }
    };

    if (readers === null) {
        return (
            <Container>
                <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Stack
                gap={1}
                margin={1}
                direction={"column"}
                alignItems={"center"}
                maxWidth={"800px"}
                mr={"auto"}
                ml={"auto"}
            >
                <Card sx={{ width: "100%" }}>
                <CardContent>
                        <Stack
                            gap={2}
                        >
                            <Typography variant="h6">Payment Terminal</Typography>
                            <Autocomplete
                                id="reader"
                                disableClearable
                                value={readerId !== null ? readers.filter((r) => r.id === readerId)[0] : null}
                                options={readers}
                                sx={{
                                    flexGrow: 3
                                }}
                                renderInput={(params) => {
                                    return (<TextField required {...params} label="Reader" />);
                                }}
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                onChange={(event, value) => {
                                    setReaderId(value?.id);
                                }}
                            />
                            <Stack
                                direction={"row"}
                                gap={1}
                                justifyContent={"center"}
                            >
                                <LoadingButton loading={clearLoading} variant="outlined" onClick={clearReader}>CLEAR READER</LoadingButton>
                            </Stack>
                            {clearError && <Alert severity="error">{clearError}</Alert>}
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ width: "100%" }}>
                    <CardContent>
                        <Stack
                            gap={2}
                        >
                            <Typography variant="h6">Manual Payment</Typography>
                            <Stack
                                direction={"row"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextField
                                    id="amount"
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    label="Amount"
                                    variant="outlined"
                                    value={amountString}
                                    onChange={(e) => setAmountString(e.target.value)}
                                />
                                <TextField
                                    id="description"
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    label="Description"
                                    variant="outlined"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Stack>
                            <Stack
                                direction={"row"}
                                gap={1}
                                justifyContent={"center"}
                            >
                                <LoadingButton disabled={!amountValid} loading={captureLoading} variant="contained" onClick={captureManualPayment}>CAPTURE PAYMENT</LoadingButton>
                            </Stack>
                            {manualCaptureError && <Alert severity="error">{manualCaptureError}</Alert>}
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ width: "100%" }}>
                    <CardContent>
                        <Stack
                            gap={2}
                        >
                            <Typography variant="h6">Payment Terminal</Typography>
                            <Stack
                                direction={"row"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextField
                                    id="jobId"
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    label="Job"
                                    variant="outlined"
                                    value={jobId}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            getSalesOrders();
                                            e.target.blur();
                                        }
                                    }}
                                    onChange={(e) => setJobId(e.target.value)}
                                />
                                <LoadingButton loading={soLoading} variant="contained" onClick={getSalesOrders}>LOAD JOB</LoadingButton>
                            </Stack>
                            {jobError && <Alert severity="error">{jobError}</Alert>}
                            {salesOrders !== null &&
                                <SalesOrderTable jobId={jobId} salesOrders={salesOrders} selected={selected} setSelected={setSelected} />
                            }
                            <Stack
                                direction={"row"}
                                gap={1}
                                justifyContent={"center"}
                            >
                                <LoadingButton disabled={selected.length === 0} loading={captureLoading} variant="contained" onClick={capturePayment}>CAPTURE PAYMENT</LoadingButton>
                            </Stack>
                            {captureError && <Alert severity="error">{captureError}</Alert>}
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ width: "100%" }}>
                    <SyncorePaymentIntentsTable />
                </Card>
            </Stack>
        </Container>
    )
}

export default PaymentTerminalPage;
