import React from "react";
import { Card, CardContent, CircularProgress, IconButton, ListItem, ListItemText, Tooltip, Typography, List, ListItemButton, Collapse, ListItemIcon, Divider } from "@mui/material";
import { where, query, collection, orderBy, onSnapshot, deleteDoc, doc, updateDoc } from "firebase/firestore";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TocIcon from '@mui/icons-material/Toc';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CommentOutlined, ExitToApp, Inbox } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

function TaskList(props) {
    let { db, types } = props;
    let { domainId } = useParams();
    let [taskGroups, setTaskGroups] = React.useState([]);
    let [date, setDate] = React.useState(dayjs().startOf('day'));

    React.useEffect(() => {
        console.log("get");
        let q = null;
        if (types !== undefined) {
            q = query(collection(db, "domains", domainId, "taskGroups"), where("type", "in", types), where("date", ">=", date.toDate()), where("date", "<=", date.endOf('day').toDate()), orderBy("date", "desc"));
        } else {
            q = query(collection(db, "domains", domainId, "taskGroups"), where("date", ">=", date.toDate()), where("date", "<=", date.endOf('day').toDate()), orderBy("date", "desc"));
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newTaskGroups = querySnapshot.docs.map((doc) => {return {"id": doc.id, ...doc.data()}});
            setTaskGroups(newTaskGroups);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db, date, domainId, types]);

    return (
        <Card
            sx={{
                minWidth: 600,
                marginLeft: "auto",
                marginRight: "auto"
            }}
        >
            <CardContent>
                <Typography variant="h5">Import Tasks</Typography>
            </CardContent>
            <List
                sx={{ 
                    width: '100%',
                    padding: 0
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                    {
                        taskGroups.map((tg, idx) => <TaskGroupListItem key={idx} db={db} taskGroup={tg} />)
                    }
            </List>
            <Divider/>
            <CardContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={(d) => setDate(d.startOf('day'))} value={date} />
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
}

function TaskGroupListItem(props) {
    let { db, taskGroup } = props;
    let { domainId } = useParams();
    let [open, setOpen] = React.useState(false);
    let [tasks, setTasks] = React.useState([]);

    React.useEffect(() => {
        const q = query(collection(db, "domains", domainId, "tasks"), where("taskGroupId", "==", taskGroup.id));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            console.log("snapshot!");
            console.log(querySnapshot);
            const newTasks = querySnapshot.docs.map((doc) => {return {"id": doc.id, ...doc.data()}});
            console.log(newTasks);
            newTasks.sort((a, b) => a.creationDate - b.creationDate)
            setTasks(newTasks);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [taskGroup, db, domainId]);

    React.useEffect(() => {
        setOpen(tasks.some(task => (task.status === "new" || task.status === "hold") && !task.error));
    }, [tasks]);

    const getOverallIcon = () => {
        if (tasks.length > 0 && tasks.every(task => task.status === "success")) {
            return (
                <CheckCircleIcon color="success"/>
            );
        } else if (taskGroup.error != null || tasks.some(task => task.error != null)) {
            return (<CancelIcon color="error" />);
        } else if (tasks.some(task => task.pending)) {
            return (<CircularProgress size={24} variant={"determinate"} value={(tasks.filter(task => task.status === "success").length / tasks.length) * 100} />);
        } else {
            return (<PendingIcon color="disabled" />);
        }
    };

    const getTaskInfo = (task) => {
        let icon = <TocIcon/>
        let linkText = null;
        let link = null;
        switch (task.function) {
            case "syncore/createSalesOrder":
                icon = <ReceiptOutlinedIcon/>
                linkText = task.responseData?.salesOrderId?.toString();
                link = linkText !== undefined ? `https://ateasesystems.net/SalesOrder/Details/${linkText}` : null
                break;
            case "syncore/createPurchaseOrder":
                icon = <ReceiptOutlinedIcon/>
                linkText = task.responseData?.salesOrderId?.toString();
                link = linkText !== undefined ? `https://ateasesystems.net/SalesOrder/Details/${linkText}` : null
                break;
            case "syncore/createLineItems":
                icon = <TocIcon/>
                linkText = task.responseData?.parentDescription;
                break;
            case "syncore/createPOLineItems":
                icon = <TocIcon/>
                linkText = task.responseData?.parentDescription;
                break;
            case "syncore/getContact":
                icon = <PersonOutlineIcon/>
                linkText = task.responseData?.contactId?.toString();
                link = linkText !== undefined ? `https://ateasesystems.net/Contact/Details/${linkText}` : null
                break;
            case "syncore/createJob":
                icon = <WorkOutlineIcon/>
                linkText = task.responseData?.jobId?.toString();
                link = linkText !== undefined ? `https://ateasesystems.net/Job/Details/${linkText}` : null
                break;
            case "syncore/createPayment":
                icon = <AttachMoneyIcon/>
                linkText = task.responseData?.paymentId?.toString();
                break;
            case "syncore/adjustTaxDifference":
                icon = <AttachMoneyIcon/>
                linkText = task.responseData?.offset?.toString();
                break;
            case "syncore/addInfoToInksoft":
                icon = <CommentOutlined/>
                link = `https://logotologist.com/logotology_designer/admin/ordermanager/order/${task.data.guid}`;
                break;
            case "advance/createOrder":
                icon = <Inbox/>
                if (task.responseData?.orderId !== undefined && task.responseData?.orderNo !== undefined) {
                    link = `https://logotology.anterasaas.com/e-commerce/orders/${task.responseData.orderId}`;
                    linkText = task.responseData.orderNo;
                }
                break;
            case "advance/createContact":
                icon = <PersonOutlineIcon/>
                if (task.responseData?.contactId !== undefined) {
                    link = `https://logotology.anterasaas.com/contacts/v1/${task.responseData.contactId}`;
                    linkText = task.responseData.contactId;
                }
                break;
            case "advance/attachContactToAccount":
                icon = <PersonOutlineIcon/>
                if (task.responseData?.contactId !== undefined) {
                    link = `https://logotology.anterasaas.com/contacts/v1/${task.responseData.contactId}`;
                    linkText = task.responseData.contactId;
                }
                break;
            case "advance/createOrderPayment":
                icon = <AttachMoneyIcon/>
                break;
            default:
                break;
        }
        return {
            icon: icon,
            linkText: linkText,
            link: link
        }
    }

    const deleteTaskGroupDoc = () => {
        if (taskGroup.error != null || tasks.some(task => task.error != null)) {
            deleteDoc(doc(db, "domains", domainId, "taskGroups", taskGroup.id))
        }
    };

    const getParentLink = (taskGroup) => {
        if (taskGroup?.order?.id) {
            return `https://logotology.anterasaas.com/e-commerce/orders/${taskGroup.order.id}`;
        } else if (taskGroup?.advanceOrderIds?.length === 1) {
            return `https://logotology.anterasaas.com/e-commerce/orders/${taskGroup.advanceOrderIds[0]}`;
        }
    }

    return (
        <>
            <Divider />
            <ListItem
                disablePadding
                secondaryAction={
                    <Tooltip title={taskGroup.error || ""} placement={"right"}>
                        <IconButton onAuxClick={() => deleteTaskGroupDoc()} href={getParentLink(taskGroup)} target={"_blank"}>
                            {getOverallIcon()}
                        </IconButton>
                    </Tooltip>
                }
            >
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    <ListItemText primary={taskGroup.description} />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        sx={{ pl: 4 }}
                    >
                        <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary={taskGroup.date.toDate().toLocaleString()} />
                    </ListItem>
                    <ListItem
                        sx={{ pl: 3 }}
                    >
                        <ListItemIcon>
                            <IconButton href={`https://console.firebase.google.com/u/0/project/threadpath/firestore/data/~2Fdomains~2F${domainId}~2FtaskGroups~2F${taskGroup.id}`} target="_blank" rel="noopener">
                                <PersonOutlineIcon/>
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText sx={{ pl: 1 }} primary={taskGroup?.userEmail ?? "Unknown"} />
                    </ListItem>
                    {
                        (taskGroup.orderIds !== undefined || taskGroup.webstoreOrderIds !== undefined) &&
                        <ListItem
                            sx={{ pl: 4 }}
                            secondaryAction={
                                <IconButton href={`https://logotologist.com/logotology_designer/admin/ordermanager/orders?Status=Open&Index=0&MaxResults=25&OrderByDirection=Descending&OrderBy=DateCreated&SearchText=${(taskGroup.orderIds || taskGroup.webstoreOrderIds).join(",")}`} target={"_blank"}>
                                    <ExitToApp/>
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                <FormatListBulletedOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={(taskGroup.orderIds || taskGroup.webstoreOrderIds).join(", ")} primaryTypographyProps={{"textOverflow": "ellipsis"}} />
                        </ListItem>
                    }
                    {
                        taskGroup.guid !== undefined && taskGroup.orderId !== undefined &&
                        <ListItem
                            sx={{ pl: 4 }}
                            secondaryAction={
                                <IconButton href={`https://logotologist.com/logotology_designer/admin/ordermanager/order/${taskGroup.guid}`} target={"_blank"}>
                                    <ExitToApp/>
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                <FormatListBulletedOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={taskGroup.orderId} primaryTypographyProps={{"textOverflow": "ellipsis"}} />
                        </ListItem>
                    }
                    {
                        taskGroup.advanceOrderNos !== undefined &&
                        <ListItem
                            sx={{ pl: 4 }}
                            secondaryAction={
                                taskGroup.advanceOrderNos.length === 1 ?
                                <IconButton href={`https://logotology.anterasaas.com/e-commerce/orders/${taskGroup.advanceOrderIds[0]}`} target={"_blank"}>
                                    <ExitToApp/>
                                </IconButton>
                                : null
                            }
                        >
                            <ListItemIcon>
                                <FormatListBulletedOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={taskGroup.advanceOrderNos.join(", ")} primaryTypographyProps={{"textOverflow": "ellipsis"}} />
                        </ListItem>
                    }
                    {
                        taskGroup.orderRef !== undefined &&
                        <ListItem
                            sx={{ pl: 4 }}
                            // secondaryAction={
                            //     <IconButton href={`https://logotology.anterasaas.com/e-commerce/orders/${taskGroup.orderRef.id}`} target={"_blank"}>
                            //         <ExitToApp/>
                            //     </IconButton>
                            // }
                        >
                            <ListItemIcon>
                                <FormatListBulletedOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={taskGroup.orderRef.name} primaryTypographyProps={{"textOverflow": "ellipsis"}} />
                        </ListItem>
                    }
                    {
                        tasks.map((task, idx) => {
                            let { icon, link, linkText } = getTaskInfo(task);
                            return (
                                <TaskLine 
                                    key={idx}
                                    name={task.description}
                                    processing={task.pending}
                                    complete={task.status === "success"}
                                    linkText={linkText}
                                    link={link}
                                    error={task.error}
                                    icon={icon}
                                    taskId={task.id}
                                    onAuxClick={() => {
                                        updateDoc(doc(db, "domains", domainId, "tasks", task.id), {"status": "new", "pending": true, "attempts": 1})
                                    }}
                                    domainId={domainId}
                                />
                            );
                        })
                    }
                </List>
            </Collapse>
        </>
    );
}

function TaskLine(props) {
    let { name, processing, complete, error, linkText, link, icon, progressText, taskId, onAuxClick, domainId } = props;

    let rightIcon = <PendingIcon color="disabled" />
    if (complete) {
        rightIcon = <CheckCircleIcon color="success" />
    } else if (processing) {
        rightIcon = <CircularProgress size={24} />
    } else if (error != null) {
        rightIcon = <CancelIcon color="error" />;
    }
    let tooltip = linkText || error || progressText || "";
    return (
        <ListItem
            sx={{ pl: 4 }}
            secondaryAction={
                <Tooltip title={tooltip.length > 0 ? <Typography fontSize={16}>{tooltip}</Typography> : ""} placement={"right"}>
                    <IconButton size="small" href={link} target="_blank" rel="noopener" onAuxClick={() => onAuxClick()}>
                        {rightIcon}
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemIcon>
                <IconButton href={`https://console.firebase.google.com/u/0/project/threadpath/firestore/data/~2Fdomains~2F${domainId}~2Ftasks~2F${taskId}`} target="_blank" rel="noopener">
                    {icon}
                </IconButton>
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItem>
    );
}

export default TaskList;