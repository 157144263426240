import { Box, Card, TableRow, CircularProgress, Container, Stack, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from "@mui/material";
import axios from "axios";
import { getAuth } from "firebase/auth";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import { SERVICE_URL } from "../App";
import { getProducts } from "../managers/ProductManager";

function StylePrintPage(props) {
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [styles, setStyles] = React.useState([]);
    let [products, setProducts] = React.useState([]);
    let [loading, setLoading] = React.useState(false);
    let [loadingSuppliers, setLoadingSuppliers] = React.useState(false);
    let [suppliers, setSuppliers] = React.useState([]);


    
    React.useEffect(() => {
        const getProductData = async () => {
            try {
                setProducts([]);
                setLoading(true);
                let products = await getProducts(domainId, searchParams.get("inksoftStoreUrl"), true);
                setProducts(products);
                setLoading(false);

            } catch (e) {
                console.log(e);
                // setError(e.toString());
            } finally {
                setLoading(false);
            }
        }
        if (searchParams.get("inksoftStoreUrl") !== null)
            getProductData();
    }, [domainId, searchParams]);

    React.useEffect(() => {
        const getSuppliers = async () => {
            try {                
                setLoadingSuppliers(true);
                const auth = getAuth();
                const token = await auth.currentUser.getIdToken();
                let response = await axios.get(
                    `${SERVICE_URL}/syncore/getSuppliers`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        params: {
                            "domainId": "FSmP1lqOuMPAt7IrT6ri"
                        }
                    }
                );
                let data = response.data;
                setSuppliers(data["data"]["suppliers"]);
                setLoadingSuppliers(false);
            } catch (e) {
                console.log(e);
                // setError(e.toString());
            } finally {
                setLoadingSuppliers(false);
            }
        };
        if (suppliers.length === 0) {
            getSuppliers();
        }
    }, [suppliers.length]);

    React.useEffect(() => {
        let newStyles = [];
        for (const product of products) {
            for (const style of product.styles) {
                if (style.active) {
                    style.productName = product.name;
                    style.sku = product.sku;
                    newStyles.push(style);
                }
            }
        }
        console.log(newStyles);
        setStyles(newStyles);
    }, [products]);

    return (
        <Container sx={{padding: 1}}>
                {
                    (loading || loadingSuppliers) &&
                    <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress />
                    </Box> 
                }
                <Stack
                    gap={1}
                >
                    {styles.map((s, i) => 
                        <table key={i} style={(s.decorations === null || s.decorations.length === 0) ? {backgroundColor: red[50]} : undefined}>
                            <tr>
                                <Stack
                                    direction='column'
                                    alignItems='center'
                                    gap={1}
                                >
                                    <Typography variant="h5">{s.productName}</Typography>
                                    <Typography variant="h6">{s.sku}</Typography>
                                    <Typography variant="h6">{s.styleName}</Typography>
                                    <Stack
                                        direction={'row'}
                                        gap={1}
                                    >
                                        {s.sides.map((side, idx) => 
                                            <>
                                                <Card variant="outlined">
                                                        <img key={idx} src={side.imageUrl} style={{maxHeight: "150px"}} />
                                                        <Typography variant="caption" display={"flex"} justifyContent={"center"} textAlign={'center'}>{side.side.toUpperCase()}</Typography>
                                                </Card>
                                                <Card variant="outlined">
                                                    <img key={idx} src={side.artUrl} style={{maxHeight: "150px"}} />
                                                    <Typography variant="caption" display={"flex"} justifyContent={"center"} textAlign={'center'}>{side.side.toUpperCase()}</Typography>
                                                </Card>
                                            </>
                                        )}
                                    </Stack>
                                    {
                                        (s.decorations === null || s.decorations.length === 0) && <Typography>NO DECORATION</Typography>
                                    }
                                    {
                                        s.decorations !== null && s.decorations.length !== 0 &&
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Vendor</TableCell>
                                                        <TableCell>Method</TableCell>
                                                        <TableCell>Location</TableCell>
                                                        <TableCell>Design</TableCell>
                                                        <TableCell>Color</TableCell>
                                                        <TableCell>Size</TableCell>
                                                        <TableCell>Cost</TableCell>
                                                        <TableCell>Personalization</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {s.decorations.map((deco, idx) => (
                                                        <TableRow
                                                            key={idx}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {suppliers.filter((sup) => sup.id === deco.decorationVendor)[0].name}
                                                            </TableCell>
                                                            <TableCell>{deco.decorationMethod}</TableCell>
                                                            <TableCell>{deco.decorationLocation}</TableCell>
                                                            <TableCell>{deco.designName}</TableCell>
                                                            <TableCell>{deco.decorationColor}</TableCell>
                                                            <TableCell>{deco.decorationSize}</TableCell>
                                                            <TableCell>{"$" + deco.runChargeCost.toFixed(2)}</TableCell>
                                                            <TableCell>{deco.personalization ? deco.personalizationName : ""}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Stack>
                            </tr>
                        </table>
                    )}
                </Stack>
            </Container>
      );
}

export default StylePrintPage;