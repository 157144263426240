import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import { IconButton, Stack } from '@mui/material';

function KeyValueEditor({ initialMap = {}, onSave, keyLabel = "Key", valueLabel = "Value", renderValue}) {
  const [mapData, setMapData] = useState(Array.from(Object.entries(initialMap), ([key, value]) => ({ key, value })));
  const [error, setError] = useState(false);

  const handleKeyChange = (index, newKey) => {
    setMapData(prevState => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], key: newKey };
      return newState;
    });
  };

  const handleValueChange = (index, newValue) => {
    setMapData(prevState => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], value: newValue };
      return newState;
    });
  };

  const addRow = () => {
    setMapData(prevState => {
      return [...prevState, { key: '', value: '' }];
    });
  };

  const deleteRow = (index) => {
    setMapData(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleSave = () => {
    onSave(Object.fromEntries(mapData.filter((e) => e.key !== "").map(({ key, value }) => [key, value])));
  }

  React.useEffect(() => {
    const keys = mapData.filter((r) => r.key !== "").map((r) => r.key);
    console.log(keys);
    if(new Set(keys).size !== keys.length) {
      console.log("error!");
      setError(true);
    } else {
      setError(false);
    }
  }, [mapData]);

  return (
    <Stack
      alignItems={"center"}
      gap={2}
    >
      {Object.keys(mapData).map((index) => {
        const valueComponent = renderValue ? renderValue(mapData[index].value, (value) => handleValueChange(index, value)) : (
          <TextField
            label={valueLabel}
            value={mapData[index].value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            fullWidth
          />
        );
        return (
          <Grid container spacing={2} key={index} alignItems={"center"}>
            <Grid item xs={5}>
              <TextField
                label={keyLabel}
                value={mapData[index].key}
                onChange={(e) => handleKeyChange(index, e.target.value)}
                fullWidth
                error={error} // Show error if there are duplicates
                helperText={error ? "Keys must be unique" : ""}
              />
            </Grid>
            <Grid item xs={5}>
              {valueComponent}
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => deleteRow(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      })}
      <Button variant="outlined" startIcon={<AddIcon />} onClick={addRow}>
        Add Entry
      </Button>
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    </Stack>
  );
}

export default KeyValueEditor;
