import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as AdvanceManager from "../managers/AdvanceManager";
import * as ServiceManager from "../managers/ServiceManager";
import { Alert, Card, CardContent, Container, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Select, Stack, Switch, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AccountSelector from "../Components/AccountSelector";
import { DataGrid } from "@mui/x-data-grid";
import DecorationUpdateList from "./DecorationUpdateList";
import TaskList from "../InksoftSyncoreImport/TaskList";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

function BulkActionsPage({ db }) {
    let { domainId } = useParams();
    let [accountRef, setAccountRef] = useState('');
    let [orderDateFrom, setOrderDateFrom] = useState('');
    let [orderDateTo, setOrderDateTo] = useState('');
    let [loadingOrders, setLoadingOrders] = useState(false);
    let [orders, setOrders] = useState([]);
    let [ordersError, setOrdersError] = useState(null);

    let [loadingAction, setLoadingAction] = useState(false);

    let [dueDate, setDueDate] = useState('');
    let [shipDate, setShipDate] = useState('');
    let [status, setStatus] = useState('Booked');
    let [decorationUpdates, setDecorationUpdates] = useState([]);

    let [statusEnabled, setStatusEnabled] = useState(false);
    let [dueDateEnabled, setDueDateEnabled] = useState(false);
    let [shipDateEnabled, setShipDateEnabled] = useState(false);
    let [decoUpdateEnabled, setDecoUpdateEnabled] = useState(false);

    let [removeRowPrice, setRemoveRowPrice] = useState(false);
    let [removeRowCost, setRemoveRowCost] = useState(false);
    let [removeDecoPrice, setRemoveDecoPrice] = useState(false);
    let [removeDecoCost, setRemoveDecoCost] = useState(false);

    let [advanceServiceData, setAdvanceServiceData] = useState(null);

    useEffect(() => {
        ServiceManager.getServiceDocSnapshot(domainId, "advance", setAdvanceServiceData);
    }, [domainId]);

    const handleGetOrders = async () => {
        try {
            setLoadingOrders(true);
            const orders = await AdvanceManager.getBulkActionsOrders(domainId, accountRef.name, accountRef.id, orderDateFrom, orderDateTo)
            setOrders(orders);
        } catch (error) {
            console.error(error);
            setOrdersError(error?.response?.data?.message ?? error.toString());
        } finally {
            setLoadingOrders(false);
        }
    }

    const handleBulkAction = async () => {
        try {
            setLoadingAction(true);
            const orderRefs = orders.map((o) => ({id: o.id, no: o.no}));
            const formattedDecoUpdates = decorationUpdates.map((e) => ({
                artworkId: e.artwork.ref.id,
                variationId: e.artworkVariationRef.id,
                cost: Number(e.cost)
            }));
            await AdvanceManager.createOrderBulkUpdateTasks(
                domainId,
                orderRefs,
                dueDateEnabled ? dueDate : undefined,
                shipDateEnabled ? shipDate : undefined,
                statusEnabled ? status : undefined,
                decoUpdateEnabled ? formattedDecoUpdates : [],
                removeRowPrice,
                removeRowCost,
                removeDecoPrice,
                removeDecoCost
            );
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingAction(false);
        }
    }

    const validSubmit = () => {
        return (statusEnabled || dueDateEnabled || shipDateEnabled || decoUpdateEnabled || removeRowPrice || removeRowCost || removeDecoPrice || removeDecoCost) && (!statusEnabled || !!status) && (!dueDateEnabled || !!dueDate) && (!shipDateEnabled || !!shipDate) && (!decoUpdateEnabled || decorationUpdates.length > 0);
    }

    const validGetOrders = () => {
        return !!accountRef && !!orderDateFrom && !!orderDateTo;
    }

    return (
        <Container sx={{mt: 2, mb: 2}}>
            <Stack gap={1}>
                <TaskList domainId={domainId} db={db} types={["ADVANCE_BULK_UPDATE_ORDER"]} />
                <Card>
                    <Typography variant="h6" sx={{mt: 2, ml: 2}}>Get Orders</Typography>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Alert severity="info">Filter for a set of orders and apply bulk actions to them</Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <AccountSelector
                                    domainId={domainId}
                                    selectedAccountRef={accountRef}
                                    label="Account"
                                    onUpdate={setAccountRef}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        onChange={(d) => setOrderDateFrom(d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                                        value={orderDateFrom != null ? dayjs(orderDateFrom, "YYYY-MM-DD") : null}
                                        label="Order Date From"
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(d) => setOrderDateTo(d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                                        value={orderDateTo != null ? dayjs(orderDateTo, "YYYY-MM-DD") : null}
                                        label="Order Date To"
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <LoadingButton loading={loadingOrders} variant="contained" disabled={!validGetOrders()} onClick={handleGetOrders}>Get Orders</LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card>
                    <Typography variant="h6" sx={{mt: 2, ml: 2}}>Orders</Typography>
                    <CardContent>
                        <DataGrid
                            rows={orders}
                            columns={[
                                {
                                    field: "no",
                                    headerName: "Order No",
                                    flex: 1,
                                    valueGetter: (params) => ({id: params.row.id, no: params.row.no}),
                                    renderCell: (params) => {
                                        if (advanceServiceData && params.value?.id) {
                                            return <Link href={`https://${advanceServiceData.subdomain}.anterasaas.com/e-commerce/orders/${params.value.id}`} target="_blank" rel="noopener">{params.value.no}</Link>;
                                        } else if (params.value?.no) {
                                            return params.value.no;
                                        }
                                        return "";
                                    }
                                },
                                { field: "identity", headerName: "Identity", flex: 3 },
                                { field: "orderDate", headerName: "Order Date", flex: 1, valueFormatter: (params) => dayjs(params.value)?.toDate()?.toLocaleDateString() ?? "N/A" },
                                { field: "dueDate", headerName: "Due Date", flex: 1, valueFormatter: (params) => dayjs(params.value)?.toDate()?.toLocaleDateString() ?? "N/A" },
                                { field: "shipDate", headerName: "Ship Date", flex: 1, valueFormatter: (params) => dayjs(params.value)?.toDate()?.toLocaleDateString() ?? "N/A" },
                                { field: "status", headerName: "Status", flex: 1 },
                            ]}
                            disableRowSelectionOnClick
                            autoHeight
                            pagination
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            pageSizeOptions={[10, 20, 100]}
                            loading={loadingOrders}
                            error={ordersError}
                            localeText={{ noRowsLabel: "No orders" }}
                        />
                    </CardContent>
                </Card>
                {
                    orders.length > 0 &&
                    <Card>
                        <Typography variant="h6" sx={{mt: 2, ml: 2}}>Bulk Actions</Typography>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                                
                                    <Grid item xs={12}>
                                        <FormControlLabel checked={removeRowPrice} onChange={(_, checked) => setRemoveRowPrice(checked)} control={<Switch />} label="Remove Item Price" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                                
                                    <Grid item xs={12}>
                                        <FormControlLabel checked={removeRowCost} onChange={(_, checked) => setRemoveRowCost(checked)} control={<Switch />} label="Remove Item Cost" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                                
                                    <Grid item xs={12}>
                                        <FormControlLabel checked={removeDecoPrice} onChange={(_, checked) => setRemoveDecoPrice(checked)} control={<Switch />} label="Remove Deco Price" />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                                
                                    <Grid item xs={12}>
                                        <FormControlLabel checked={removeDecoCost} onChange={(_, checked) => setRemoveDecoCost(checked)} control={<Switch />} label="Remove Deco Cost" />
                                    </Grid>
                                </Grid>

                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                                
                                    <Grid item xs={6}>
                                        <FormControlLabel checked={statusEnabled} onChange={(_, checked) => setStatusEnabled(checked)} control={<Switch />} label="Update Status" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {statusEnabled &&
                                            <FormControl fullWidth>
                                                <InputLabel id="status-label">Status</InputLabel>
                                                <Select
                                                    label="Status"
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    labelId="status-label"
                                                >
                                                    <MenuItem value="Booked">Booked</MenuItem>
                                                    <MenuItem value="Billed">Billed</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>                            
                                    <Grid item xs={6}>
                                        <FormControlLabel checked={dueDateEnabled} onChange={(_, checked) => setDueDateEnabled(checked)} control={<Switch />} label="Update Due Date" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {dueDateEnabled &&
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker 
                                                    onChange={(d) => setDueDate(d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                                                    value={dueDate != null ? dayjs(dueDate, "YYYY-MM-DD") : null}
                                                    label="Due Date"
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6} spacing={2} justifyContent={"center"} alignItems={"center"}>
                                    <Grid item xs={6}>
                                        <FormControlLabel checked={shipDateEnabled} onChange={(_, checked) => setShipDateEnabled(checked)} control={<Switch />} label="Update Ship Date" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {shipDateEnabled &&
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(d) => setShipDate(d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                                                    value={shipDate != null ? dayjs(shipDate, "YYYY-MM-DD") : null}
                                                    label="Ship Date"
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container alignItems={"center"}>
                                    <FormControlLabel checked={decoUpdateEnabled} onChange={(_, checked) => setDecoUpdateEnabled(checked)} control={<Switch />} label="Update Decorations" />
                                </Grid>
                                <Grid item xs={12}>
                                    {decoUpdateEnabled &&
                                        <DecorationUpdateList 
                                            updateData={decorationUpdates}
                                            onUpdateData={setDecorationUpdates}
                                        />
                                    }
                                </Grid>
                                <Grid item container justifyContent={"center"}>
                                    <LoadingButton loading={loadingAction} variant={"contained"} onClick={handleBulkAction} disabled={!validSubmit()} >Process Bulk Action</LoadingButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }
            </Stack>
        </Container>
    );
}

export default BulkActionsPage;