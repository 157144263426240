import { Card, CardActionArea, CardContent, createTheme, Stack, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

function ToolCard(props) {
    const { title, description, category, icon, link, disabled=false } = props;

    const theme = createTheme({
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: "white"
                    }
                }
            }
        }
    });

    return (
        <Card sx={{
                width: "400px",
                backgroundColor: disabled ? "lightgray" : undefined
            }}
            elevation={disabled ? 0 : undefined}
        >
            <CardActionArea LinkComponent={Link} to={link} disabled={disabled}>
                <CardContent>
                    <Stack
                        direction={"row"}
                        spacing={1}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "primary.light",
                                border: "1px black",
                                borderRadius: "16px",
                                width: "48px",
                                height: "48px",
                                m: 0.5
                            }}
                        >
                            <ThemeProvider theme={theme}>
                                {icon}
                            </ThemeProvider>
                        </Box>
                        <Stack
                            direction={"column"}
                        >
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {category}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ToolCard;