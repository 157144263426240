import React from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import * as AdvanceManager from "../managers/AdvanceManager";

function FixSanmarImagesCard({ domainId }) {
    let [productUrl, setProductUrl] = React.useState("");
    let [error, setError] = React.useState(null);
    let [loading, setLoading] = React.useState(false);
    let [success, setSuccess] = React.useState(false);

    const handleFixSanmarProductImages = async () => {
        try {
            setLoading(true);
            setError(null);
            setSuccess(null);
            const id = await AdvanceManager.fixSanmarProductImages(domainId, productUrl);
            setSuccess(`Product with id ${id} fixed successfully.`);
        } catch (error) {
            setError(error.toString());
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardContent>
                    <Stack
                        gap={2}
                    >
                        <Typography variant="h6">Fix Sanmar Product Images</Typography>
                        <TextField
                            required
                            id="productUrl"
                            sx={{
                                flexGrow: 1
                            }}
                            label="Product URL"
                            variant="outlined"
                            value={productUrl}
                            onChange={(e) => setProductUrl(e.target.value)}
                        />
                        <Stack
                            direction={"row"}
                            gap={1}
                            justifyContent={"center"}
                        >
                            <LoadingButton loading={loading} variant="outlined" onClick={handleFixSanmarProductImages}>Fix Images</LoadingButton>
                        </Stack>
                        {success && <Alert severity="success">{success}</Alert>}
                        {error && <Alert severity="error">{error}</Alert>}
                    </Stack>
                </CardContent>
            </Card>
    );
};

export default FixSanmarImagesCard;