import { Box, Card, TableRow, CardContent, CircularProgress, Container, Dialog, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, Typography, AppBar, Toolbar, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useSearchParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import { getProducts } from "../managers/ProductManager";
import ArtworkImage from "./ArtworkImage";

function DecoStyleTable(props) {
    let { domainId } = useParams();
    let { suppliers, open, handleClose, artwork, showSyncoreFields } = props;
    let [searchParams, setSearchParams] = useSearchParams();
    let [styles, setStyles] = React.useState([]);
    let [products, setProducts] = React.useState([]);
    let [loading, setLoading] = React.useState(false);

    const getArtwork = (artworkId) => {
        for (const art of artwork) {
            if (art.id === artworkId)
                return art;
        }
        return null;
    };

    const getArtworkImage = (deco) => {
        console.log(deco);
        let artwork = getArtwork(deco.advanceArtworkId);
        if (deco.advanceArtworkVariationId !== null) {
            let variation = artwork.variations.filter((v) => v.id === deco.advanceArtworkVariationId)[0];
            return variation.thumbnailUrls[0];
        } else {
            return artwork.thumbnailUrl;
        }
    }

    React.useEffect(() => {
        console.log("update");
        const getProductData = async () => {
            try {
                setProducts([]);
                setLoading(true);
                let products = await getProducts(domainId, searchParams.get("inksoftStoreUrl"), true);
                setProducts(products);
                setLoading(false);

            } catch (e) {
                console.log(e);
                // setError(e.toString());
            } finally {
                setLoading(false);
            }
        }
        if (searchParams.get("inksoftStoreUrl") !== null && open)
            getProductData();
    }, [domainId, searchParams, open]);

    React.useEffect(() => {
        let newStyles = [];
        for (const product of products) {
            for (const style of product.styles) {
                if (style.active) {
                    style.productName = product.name;
                    style.sku = product.sku;
                    newStyles.push(style);
                }
            }
        }
        console.log(newStyles);
        setStyles(newStyles);
    }, [products]);

    return (
        <Dialog fullScreen open={open} PaperComponent={Paper} PaperProps={{sx: {backgroundColor: "rgb(235, 235, 235)"}}}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    All Styles
                    </Typography>
                </Toolbar>
            </AppBar>
            {
                loading &&
                <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box> 
            }
            <Container sx={{padding: 1}}>
                <Stack
                    gap={1}
                >
                    {styles.map((s, i) => 
                        <Card key={i} sx={(s.decorations === null || s.decorations.length === 0) ? {backgroundColor: red[50]} : undefined}>
                            <CardContent>
                                <Stack
                                    direction='column'
                                    alignItems='center'
                                    gap={1}
                                >
                                    <Typography variant="h5">{s.productName}</Typography>
                                    <Typography variant="h6">{s.sku}</Typography>
                                    <Typography variant="h6">{s.styleName}</Typography>
                                    <Stack
                                        direction={'row'}
                                        gap={1}
                                    >
                                        {s.sides.map((side, idx) => 
                                            <Card variant="outlined">
                                                    <img key={idx} src={side.imageUrl} style={{maxHeight: "150px"}} />
                                                    <Typography variant="caption" display={"flex"} justifyContent={"center"} textAlign={'center'}>{side.side.toUpperCase()}</Typography>
                                            </Card>
                                        )}
                                    </Stack>
                                    {
                                        (s.decorations === null || s.decorations.length === 0) && <Typography>NO DECORATION</Typography>
                                    }
                                    {
                                        s.decorations !== null && s.decorations.length !== 0 &&
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        { !showSyncoreFields && <TableCell>Design</TableCell>}
                                                        { !showSyncoreFields && <TableCell>Design Name</TableCell>}
                                                        <TableCell>Vendor</TableCell>
                                                        <TableCell>Method</TableCell>
                                                        <TableCell>Location</TableCell>
                                                        <TableCell>Design</TableCell>
                                                        {showSyncoreFields && <TableCell>Color</TableCell>}
                                                        {showSyncoreFields && <TableCell>Size</TableCell>}
                                                        <TableCell>Cost</TableCell>
                                                        <TableCell>Personalization</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {s.decorations.map((deco, idx) => (
                                                        <TableRow
                                                            key={idx}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            {
                                                                !showSyncoreFields &&
                                                                <TableCell>
                                                                    <ArtworkImage
                                                                        imageUrl={getArtworkImage(deco)}
                                                                        style={{
                                                                            maxWidth: "64px",
                                                                            maxHeight: "64px"
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            }
                                                            {!showSyncoreFields && <TableCell>{getArtwork(deco.advanceArtworkId)?.name}</TableCell>}
                                                            <TableCell>
                                                                {showSyncoreFields ? suppliers.filter((sup) => sup.id === deco.decorationVendor)[0].name : getArtwork(deco.advanceArtworkId)?.defaultVendor?.name}
                                                            </TableCell>
                                                            <TableCell>{showSyncoreFields ? deco.decorationMethod : getArtwork(deco.advanceArtworkId)?.artworkType}</TableCell>
                                                            <TableCell>{showSyncoreFields ? deco.decorationLocation : getArtwork(deco.advanceArtworkId)?.artworkLocation}</TableCell>
                                                            <TableCell>{deco.designName}</TableCell>
                                                            { showSyncoreFields && <TableCell>{deco.decorationColor}</TableCell>}
                                                            { showSyncoreFields && <TableCell>{deco.decorationSize}</TableCell>}
                                                            <TableCell>{"$" + deco.runChargeCost.toFixed(2)}</TableCell>
                                                            <TableCell>{deco.personalization ? deco.personalizationName : ""}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                    )}
                </Stack>
            </Container>
        </Dialog>
      );
}

export default DecoStyleTable;