import axios from "axios";
import { addDoc, collection, doc, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { SERVICE_URL } from "../App";
import { getAuth } from "firebase/auth";

function getServiceDocSnapshot(domainId, setServiceDoc) {
    const db = getFirestore();
    let serviceRef = doc(db, "domains", domainId, "services", "payouts");
    const unsubscribe = onSnapshot(serviceRef, (doc) => {
        setServiceDoc(doc?.data() ?? {});
    }, (error) => console.log(error));
    return unsubscribe;
}

async function getPayoutPreviews(domainId, recipientIds, endDate) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/payouts/getPayoutPreviews`,
        {
            params: {
                "domainId": domainId,
                "recipientIds": JSON.stringify(recipientIds),
                "endDate": endDate
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return response.data.data.payouts;
}

async function createPayouts(domainId, recipientIds, endDate) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("recipientIds", JSON.stringify(recipientIds));
    formData.append("endDate", endDate);
    let response = await axios.post(
        `${SERVICE_URL}/payouts/createPayouts`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return response.data.data.payoutResults;
}

function updateProductMapping(domainId, productId, mapping) {
    const db = getFirestore();
    const recipientRef = doc(db, "domains", domainId, "services", "payouts");
    setDoc(recipientRef, {mapping: {[productId]: mapping}}, {merge: true});
}

function updateRecipient(domainId, receipientId, data) {
    const db = getFirestore();
    const recipientRef = doc(db, "domains", domainId, "services", "payouts", "recipients", receipientId);
    updateDoc(recipientRef, data);
}

function createRecipient(domainId, data) {
    const db = getFirestore();
    const recipientRef = collection(db, "domains", domainId, "services", "payouts", "recipients");
    addDoc(recipientRef, data);
}

function getRecipients(domainId, setRecipients) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "payouts", "recipients"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const recipients = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        setRecipients(recipients);
    }, (error) => console.log(error));
    return unsubscribe;
}

function getRecipientPayouts(domainId, recipientId, setPayouts) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "payouts", "recipients", recipientId, "recipientPayouts"), orderBy("dateCreated", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const payouts = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        setPayouts(payouts);
    }, (error) => console.log(error));
    return unsubscribe;
}

function getRecipient(domainId, recipientId, setRecipient) {
    const db = getFirestore();
    const q = doc(db, "domains", domainId, "services", "payouts", "recipients", recipientId);
    const unsubscribe = onSnapshot(q, (doc) => {
        console.log(doc.data());
        const recipient = {...doc.data(), id: doc.id};
        setRecipient(recipient);
    }, (error) => console.log(error));
    return unsubscribe;
}

export { getRecipients, getServiceDocSnapshot, updateRecipient, createRecipient, updateProductMapping, getPayoutPreviews, createPayouts, getRecipient, getRecipientPayouts };