import { Container } from "@mui/material";

function PrivacyPolicy() {
    return (
        <Container>
            <h1>Privacy Policy</h1>
            <p>This privacy policy has been compiled to better serve those who are concerned with how their &#39;Personally Identifiable Information&#39; (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>

            <p><strong>What personal information do we collect from the people that visit our blog, website or app?</strong></p>

            <p>When registering for your app we may collection your name, email address, and credentials for various applications we integrate with.</p>

            <p><strong>When do we collect information?</strong></p>

            <p>We collect information from you when you register on our site.</p>

            <p><strong>How do we use your information?</strong></p>

            <p>We use your information to identify you with your business and to do the following</p>

            <ul>
                <li>Import/export order data from different applications</li>
                <li>Update e-commerce sites with updated product data</li>
                <li>Update accounting software with accurate information</li>
                <li>Update shipping software with order details</li>
            </ul>

            <p><strong>How do we protect your information?</strong></p>

            <p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p>

            <p>We use regular Malware Scanning.</p>

            <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>

            <p>We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.</p>

            <p><strong>Do we use &#39;cookies&#39;?</strong></p>

            <p>Yes. Cookies are small files that a site or its service provider transfers to your computer&#39;s hard drive through your Web browser (if you allow) that enables the site&#39;s or service provider&#39;s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>

            <p><strong>We use cookies for:</strong></p>

            <ul>
                <li>Authentication</li>
                <li>Analytics</li>
            </ul>

            <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser&#39;s Help Menu to learn the correct way to modify your cookies.</p>

            <p><strong>If users disable cookies in their browser:</strong></p>

            <p>If you turn cookies off, some features will be disabled. Some of the features that make your site experience more efficient and may not function properly.</p>

            <p>However, you will still be able to place orders over the telephone by contacting customer service.</p>

            <p><strong>Third-party disclosure</strong></p>

            <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>

            <p><strong>Third-party links</strong></p>

            <p>We have links to services you connect with for easy access to information.</p>

            <p><strong>You will be notified of any Privacy Policy changes:</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; On our Privacy Policy Page</p>

            <p><strong>Can change your personal information:</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; By emailing us</p>

            <p><strong>Does our site allow third-party behavioral tracking?</strong></p>

            <p>It&#39;s also important to note that we allow third-party behavioral tracking</p>

            <p><strong>COPPA (Children Online Privacy Protection Act)</strong></p>

            <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children&#39;s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States&#39; consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children&#39;s privacy and safety online.</p>

            <p><strong>We do not allow users under the age of 13 to use our site.</strong></p>

            <p><strong>Fair Information Practices</strong></p>

            <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>

            <p><strong>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</strong></p>

            <p>We will notify you via email</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Within 7 business days</p>

            <p><strong>We will notify the users via in-site notification</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Within 7 business days</p>

            <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>

            <p><strong>CAN SPAM Act</strong></p>

            <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

            <p><strong>We collect your email address in order to:</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Send information, respond to inquiries, and/or other requests or questions</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Process orders and to send information and updates pertaining to orders.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Send you additional information related to your product and/or service</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</p>

            <p><strong>To be in accordance with CANSPAM, we agree to the following:</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Not use false or misleading subjects or email addresses.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Identify the message as an advertisement in some reasonable way.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Include the physical address of our business or site headquarters.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Monitor third-party email marketing services for compliance, if one is used.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Honor opt-out/unsubscribe requests quickly.</p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Allow users to unsubscribe by using the link at the bottom of each email.</p>

            <p><strong>If at any time you would like to unsubscribe from receiving future emails, you can email us by:</strong></p>

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &bull; Follow the instructions at the bottom of each email and we will promptly remove you from ALL correspondence.</p>

            <p><strong>Contacting Us:</strong></p>

            <p>If there are any questions regarding this privacy policy, you may contact:</p>

            <p>Cole Weinman</p>

            <p><a href="mailto:cole@logotology.com">cole@logotology.com</a></p>

            <p>1750 Alma Rd, Suite 122, Richardson TX 75081</p>

        </Container>
    )
}

export default PrivacyPolicy;