import { Button, Card, CardContent, Divider, Typography } from "@mui/material";
import { LinesInternalTable } from "../InksoftSyncoreImport/LinesTable";
import * as React from 'react';

function SOWrapper({ salesOrder }) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Typography>SO {salesOrder.job_number}-{salesOrder.number}</Typography>
                <Typography>Shipping: ${salesOrder.freight_value}</Typography>
                <LinesInternalTable nestedLines={salesOrder.nestedLines} />
            </CardContent> 
        </Card>
    )
}

function POWrapper({ purchaseOrder }) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Typography>PO {purchaseOrder.job_number}-{purchaseOrder.number}</Typography>
                <Typography>Supplier: {purchaseOrder.supplier.name}</Typography>
                <Typography>CSR Instructions: {purchaseOrder.csr_instructions_from_so}</Typography>
                <Typography>Shipping: ${purchaseOrder.freight_value}</Typography>
                <LinesInternalTable nestedLines={purchaseOrder.nestedLines} />
            </CardContent>
        </Card>
    )
}

function JobCard({ job }) {
    let [showSalesOrders, setShowSalesOrders] = React.useState(false);
    let [showPurchaseOrders, setShowPurchaseOrders] = React.useState(false);
    let salesOrders = [];
    let purchaseOrders = [];

    for (let so of job.sales_orders ?? []) {
        for (let line of so.line_items) {
            line["children"] = so.line_items.filter(l => l.parent_id === line.line_id);
        }
        so.nestedLines = so.line_items.filter(l => l.parent_id === 0);
        salesOrders.push(so);
    }

    for (let po of job.purchase_orders ?? []) {
        for (let line of po.line_items) {
            line["children"] = po.line_items.filter(l => l.parent_id === line.line_id);
        }
        po.nestedLines = po.line_items.filter(l => l.parent_id === 0);
        purchaseOrders.push(po);
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="body1">ID: {job.id.toString()}</Typography>
                <Typography variant="body1">Description: {job.description}</Typography>
                <Typography variant="body1">Business Name: {job.client?.business_name ?? "N/A"}</Typography>
                <Typography variant="body1">Name: {job.client?.name ?? "N/A"}</Typography>
                <Typography variant="body1">Email: {job.client?.email ?? "N/A"}</Typography>
                <Typography variant="body1">Commission Code: {job.commission_code?.name ?? "N/A"}</Typography>
                <Typography variant="body1">Primary Rep: {job.primary_rep?.name ?? "N/A"}</Typography>
                <Typography variant="body1">Secondary Rep: {job.secondary_rep?.name ?? "N/A"}</Typography>
                <Divider />
                <Typography>Sales Orders</Typography>
                <Button onClick={() => setShowSalesOrders(!showSalesOrders)}>Toggle Sales Orders</Button>
                {
                    showSalesOrders &&
                    salesOrders.map(so => <SOWrapper salesOrder={so} />)
                }
                <Divider />
                <Typography>Purchase Orders</Typography>
                <Button onClick={() => setShowPurchaseOrders(!showPurchaseOrders)}>Toggle Purchase Orders</Button>
                {
                    showPurchaseOrders &&
                    purchaseOrders.map(po => <POWrapper purchaseOrder={po} />)
                }
            </CardContent>
        </Card>
    );
}

export default JobCard;
