import React from "react";
import FixSanmarImagesCard from "./FixSanmarImagesCard";
import { Container, Stack } from "@mui/material";

function AdvanceProductToolsPage({ domainId }) {
    

    return (
        <Container>
            <Stack
                gap={1}
                margin={1}
                direction={"column"}
                alignItems={"center"}
                maxWidth={"800px"}
                mr={"auto"}
                ml={"auto"}
            >
                <FixSanmarImagesCard domainId={domainId} />
            </Stack>
        </Container>
    );

};

export default AdvanceProductToolsPage;