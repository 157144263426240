import { TableContainer, TableBody, Table, TableHead, TableRow, TableCell, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { getSyncorePaymentIntents } from "../managers/PaymentManager";

function SyncorePaymentIntentsTable(props) {
    const { domainId } = useParams();
    let [syncorePaymentIntents, setSyncorePaymentIntents] = React.useState([]);

    React.useEffect(() => {
        return getSyncorePaymentIntents(domainId, setSyncorePaymentIntents);
    }, [domainId]);

    if (syncorePaymentIntents.length === 0) {
        return <Box></Box>;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>SO Nums</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {syncorePaymentIntents.map((spi, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {spi.userName}
                            </TableCell>
                            <TableCell>{spi.description ?? spi.syncoreJobId}</TableCell>
                            <TableCell>{spi?.syncoreSONums?.map(soNum => `${spi.syncoreJobId}-${soNum}`).join(", ") ?? "N/A"}</TableCell>
                            <TableCell align="right">${spi.amount.toFixed(2)}</TableCell>
                            <TableCell>{spi.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SyncorePaymentIntentsTable;
