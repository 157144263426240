import { Stack, TextField, Autocomplete, Card, CardContent, Typography, Button, InputAdornment, Box, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import ArtworkImage from "./ArtworkImage";

const decoMethods = ["Embroidery", "Heat Transfer", "Laser", "UV", "Screen Print"];
const decoLocations = ["Left Chest", "Right Chest", "Full Front", "Full Back", "Left Sleeve", "Right Sleeve"];
const decoSize = ['2"', '2.5"', '4"', '10"', '12"'];


function DecorationInformationForm({ decorationInfo, handleUpdate, index, suppliers, handleDelete, artwork, serviceData, personalization }) {
    let [runCostStr, setRunCostStr] = React.useState(decorationInfo.runChargeCost.toString());
    let [rcError, setRCError] = React.useState(false);
    let [selectedAdvanceArtwork, setSelectedAdvanceArtwork] = React.useState(null);
    let [selectedAdvanceArtworkVariation, setSelectedAdvanceArtworkVariation] = React.useState(null);

    const updateRunCost = (value) => {
        setRunCostStr(value);
        if (isNaN(parseFloat(value))) {
            setRCError(true);
        } else {
            setRCError(false);
            handleUpdate(index, "runChargeCost", parseFloat(value));
        }
    };

    
    const getSelectedAdvanceArtwork = () => {
        return artwork?.filter(a => a.id === decorationInfo.advanceArtworkId)?.[0];
    };
    const getSelectedAdvanceArtworkVariation = () => {
        return getSelectedAdvanceArtwork()?.variations?.filter(v => v.id === decorationInfo.advanceArtworkVariationId)?.[0];
    };

    React.useEffect(() => {
        if (artwork === null) {
            selectedAdvanceArtwork = null;
            selectedAdvanceArtworkVariation = null;
        } else {
            setSelectedAdvanceArtwork(getSelectedAdvanceArtwork());
            setSelectedAdvanceArtworkVariation(getSelectedAdvanceArtworkVariation());
        }
    }, [artwork]);

    React.useEffect(() => {
        setSelectedAdvanceArtwork(getSelectedAdvanceArtwork());
        setSelectedAdvanceArtworkVariation(getSelectedAdvanceArtworkVariation());
    }, [decorationInfo.advanceArtworkId, decorationInfo.advanceArtworkVariationId]);

    return (
        <Card variant="outlined" sx={{width: "500px"}}>
            <CardContent>
                <Stack
                    gap={2}
                >
                    <Typography variant="h5" textAlign={"center"}>Option {index + 1}</Typography>
                    <Autocomplete
                        id="advance-artwork"
                        value={selectedAdvanceArtwork?.id ?? null}
                        options={artwork ?? []}
                        getOptionLabel={(option) => option?.name ?? artwork?.filter(a => a.id === option)?.[0]?.name ?? option}
                        isOptionEqualToValue={(option, value) => option.id === value}
                        onChange={(event, value) => {
                            if (value === null) {
                                handleUpdate(index, "advanceArtworkVariationId", null);
                            } else {
                                handleUpdate(index, "advanceArtworkVariationId", value.variations[0].id);
                            }
                            handleUpdate(index, "advanceArtworkId", value?.id ?? null);
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                                <Box
                                    sx={{
                                        width: "128px",
                                        height: "128px",
                                        mr: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    {option.imageUrl &&                                
                                        <ArtworkImage
                                            imageUrl={option.thumbnailUrl}
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%"
                                            }}
                                        />
                                    }
                                </Box>
                                {option.name + " (" + option.designNo + ")"}
                            </Box>
                        )}
                        renderInput={(params) => {
                            return (
                                <>
                                    <TextField 
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment:
                                                    artwork !== null &&
                                                    selectedAdvanceArtwork != null &&
                                                    <ArtworkImage 
                                                        style={{
                                                            maxWidth: "32px",
                                                            maxHeight: "32px"
                                                        }}
                                                        imageUrl={selectedAdvanceArtwork?.thumbnailUrl}
                                                    />
                                        }}
                                        label="Advance Artwork" 
                                    />
                                </>
                            );
                        }}
                    />
                    <Autocomplete
                        id="advance-artwork-variation"
                        disableClearable
                        value={selectedAdvanceArtworkVariation?.id ?? null}
                        options={selectedAdvanceArtwork?.variations ?? []}
                        getOptionLabel={(option) => option?.name ?? selectedAdvanceArtworkVariation?.name ?? option}
                        isOptionEqualToValue={(option, value) => option.id === value}
                        onChange={(event, value) => {
                            handleUpdate(index, "advanceArtworkVariationId", value.id);
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Box
                                    sx={{
                                        width: "128px",
                                        height: "128px",
                                        mr: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    {option.imageUrls[0] &&                                
                                        <ArtworkImage
                                            imageUrl={option.thumbnailUrls[0]}
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%"
                                            }}
                                        />
                                    }
                                </Box>
                                {option.name}
                            </Box>
                        )}
                        renderInput={(params) => {
                            return (
                                <>
                                    <TextField 
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: 
                                                    selectedAdvanceArtworkVariation != null && 
                                                    <ArtworkImage 
                                                        style={{
                                                            maxWidth: "32px",
                                                            maxHeight: "32px"
                                                        }}
                                                        imageUrl={selectedAdvanceArtworkVariation?.thumbnailUrls?.[0]} 
                                                    />
                                        }}
                                        label="Advance Artwork Variation" 
                                    />
                                </>
                            );
                        }}
                    />
                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxlength: 50 }}
                        required
                        error={rcError}
                        value={runCostStr} label="Decoration Cost"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        onChange={(event) => updateRunCost(event.target.value)}
                    />
                    {
                        !serviceData.syncoreFields &&
                        <>
                            <TextField 
                                disabled
                                required
                                error={selectedAdvanceArtwork?.artworkType === ""}
                                value={selectedAdvanceArtwork?.artworkType ?? ""} 
                                label="Advance Artwork Type"
                            />
                            <TextField 
                                disabled
                                required
                                error={selectedAdvanceArtwork?.artworkLocation === ""}
                                value={selectedAdvanceArtwork?.artworkLocation ?? ""} 
                                label="Advance Artwork Location"
                            />
                        </>
                    }
                    {   serviceData.syncoreFields &&
                        <>
                            <Autocomplete
                                id="decoration-vendor"
                                disableClearable
                                value={suppliers.filter((s) => s.id === decorationInfo.decorationVendor)[0]}
                                options={suppliers.sort((a, b) => a.class.localeCompare(b.class)).map((s) => s)}
                                groupBy={(option) => option.class}
                                renderInput={(params) => {
                                    params.inputProps.maxlength = 50;
                                    return (<TextField required {...params} label="Decoration Vendor" />);
                                }}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    handleUpdate(index, "decorationVendor", value.id);
                                }}
                            />
                            <Autocomplete
                                id="decoration-method"
                                freeSolo
                                value={decorationInfo.decorationMethod}
                                options={decoMethods.map((option) => option)}
                                renderInput={(params) => {
                                    params.inputProps.maxlength = 50;
                                    return (<TextField error={decorationInfo.decorationMethod === ""} required {...params} label="Decoration Method" />);
                                }}
                                onInputChange={(event, value) => handleUpdate(index, "decorationMethod", value)}

                            />
                            <Autocomplete
                                id="decoration-location"
                                freeSolo
                                value={decorationInfo.decorationLocation}
                                options={decoLocations.map((option) => option)}
                                renderInput={(params) => {
                                    params.inputProps.maxlength = 50;
                                    return (<TextField error={decorationInfo.decorationLocation === ""} required {...params} label="Decoration Location" />);
                                }}
                                onInputChange={(event, value) => {
                                    handleUpdate(index, "decorationLocation", value);
                                }}
                            />
                            <TextField 
                                id="design-name"
                                label="Design Name"
                                variant="outlined"
                                required
                                error={decorationInfo.designName === ""}
                                value={decorationInfo.designName}
                                onChange={(event) => handleUpdate(index, "designName", event.target.value)}
                                inputProps={{maxlength: 50}}
                            />
                            <TextField 
                                id="decoration-color"
                                label="Decoration Color"
                                variant="outlined"
                                value={decorationInfo.decorationColor}
                                onChange={(event) => handleUpdate(index, "decorationColor", event.target.value)}
                                inputProps={{maxlength: 50}}
                            />
                            <Autocomplete
                                id="decoration-size"
                                freeSolo
                                value={decorationInfo.decorationSize}
                                options={decoSize.map((option) => option)}
                                renderInput={(params) => {
                                    params.inputProps.maxlength = 50;
                                    return (<TextField {...params} label="Decoration Size" />);
                                }}
                                onInputChange={(event, value) => handleUpdate(index, "decorationSize", value)}
                            />
                            {decorationInfo.decorationMethod === "Embroidery" && <TextField value={decorationInfo.stitchCount || ""} label="Stitch Count" onChange={(event) => handleUpdate(index, "stitchCount", isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value))} />}
                        </>
                    }
                    {personalization &&
                        <>
                            <FormControlLabel control={<Switch checked={decorationInfo.replaceLocation ?? false} />} label="Replace Artwork with Same Location"
                                onChange={(_, checked) => handleUpdate(index, "replaceLocation", checked)}
                            />
                        </>
                    }
                    <TextField
                        id="comment1"
                        label="Comment 1"
                        variant="outlined"
                        value={decorationInfo.comment1}
                        onChange={(event) => handleUpdate(index, "comment1", event.target.value)}
                        inputProps={{maxlength: 50}}
                    />
                    {decorationInfo.comment1.length > 0 && 
                        <TextField 
                            id="comment2"
                            label="Comment 2"
                            variant="outlined"
                            value={decorationInfo.comment2}
                            onChange={(event) => handleUpdate(index, "comment2", event.target.value)}
                            inputProps={{maxlength: 50}}
                        />
                    }
                    {decorationInfo.comment2.length > 0 && 
                        <TextField
                            id="comment3"
                            label="Comment 3"
                            variant="outlined"
                            value={decorationInfo.comment3}
                            onChange={(event) => handleUpdate(index, "comment3", event.target.value)}
                            inputProps={{maxlength: 50}}
                        />
                    }
                    {index > 0 &&
                        <Button variant="text" onClick={() => handleDelete(index)}>
                            Delete
                        </Button>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
}

export default DecorationInformationForm;