import React from 'react';
import * as PayoutManager from '../managers/PayoutManager';
import * as AdvanceManager from '../managers/AdvanceManager';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography, TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import { set } from 'lodash';

const ProductDialog = ({ domainId, serviceData, open, onClose, productId, recipients, mapping }) => {
    let [mappingState, setMappingState] = React.useState(null);
    let [product, setProduct] = React.useState(null);

    React.useEffect(() => {
        setMappingState(mapping);
    }, [mapping]);

    React.useEffect(() => {
        if (productId) {
            AdvanceManager.getProduct(domainId, productId).then(setProduct);
        } else {
            setProduct(null);
            setMappingState(null);
        }
    }, [domainId, productId]);

    const handleSave = () => {
        for (let color in product.colors) {
            if (mappingState[color]) {
                if (!mappingState[color]["type"]) {
                    mappingState[color]["type"] = serviceData["types"]?.[0]?.name;
                }
            }
        }
        PayoutManager.updateProductMapping(domainId, product.id, mappingState);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
            <DialogTitle>Product Mapping</DialogTitle>
            <DialogContent>
                    <Stack gap={2} mt={1}>
                        {
                            !product && <Box sx={{display: "flex", justifyContent: "center"}}>
                                <CircularProgress />
                            </Box>
                        }
                        {
                            product?.colors.sort()?.map((color) => (
                                <Stack key={color} direction={"row"} gap={2} sx={{alignItems: "center"}}>
                                    <Typography sx={{flexGrow: 1}}>{color}</Typography>
                                    <Autocomplete
                                        sx={{flexBasis: "28%"}}
                                        value={recipients.find((r) => r.id === mappingState[color]?.["recipientId"]) ?? null}
                                        options={recipients}
                                        getOptionLabel={(option) => `${option.recipientName} (${option.contactRef.name})`}
                                        onChange={(_, value) => {
                                            const newMapping = {...mappingState};
                                            set(newMapping, [color, "recipientId"], value?.id ?? null);
                                            setMappingState(newMapping);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Recipient" />}
                                        filterOptions={(options, state) => options.filter((o) => o.recipientName?.toLowerCase()?.includes(state.inputValue?.toLowerCase()) || o.contactRef?.name?.toLowerCase()?.includes(state.inputValue?.toLowerCase()))}
                                    />
                                    <Autocomplete
                                        sx={{flexBasis: "28%"}}
                                        value={mappingState[color]?.["label"] ?? "Commission"}
                                        options={["Commission", "Prepaid Cost Reimbursement"]}
                                        freeSolo
                                        autoSelect
                                        onChange={(_, value) => {
                                            const newMapping = {...mappingState};
                                            set(newMapping, [color, "label"], value);
                                            setMappingState(newMapping);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Label" />}
                                    />
                                    <Autocomplete
                                        sx={{flexBasis: "28%"}}
                                        value={(serviceData["types"] ?? []).find((t) => t.name === mappingState[color]?.["type"]) ?? (serviceData["types"] ?? [])?.[0] ?? null}
                                        getOptionLabel={(option) => option.name}
                                        options={serviceData["types"] ?? []}
                                        onChange={(_, value) => {
                                            const newMapping = {...mappingState};
                                            set(newMapping, [color, "type"], value.name);
                                            setMappingState(newMapping);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Type" />}
                                    />
                                </Stack>
                            ))
                        }
                    </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">CANCEL</Button>
                <Button onClick={handleSave} variant="contained">SAVE</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductDialog;