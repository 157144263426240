import { Card, CardContent, CircularProgress, Container, Stack, Typography } from "@mui/material";
import React from "react";
import AdvanceVendorSettingsCard from "./AdvanceVendorSettingsCard";
import * as AdvanceManager from "../managers/AdvanceManager";
import KeyValueEditor from "./KeyValueEditor";
import GeneralAdvanceSettingsCard from "./GeneralAdvanceSettingsCard";
import ProductSelector from "../Components/ProductSelector";

const { useParams } = require("react-router-dom");

function AdvanceSettingsPage({ db }) {
    let { domainId } = useParams();
    let [serviceData, setServiceData] = React.useState(null);

    React.useEffect(() => {
        return AdvanceManager.getServiceDocSnapshot(domainId, setServiceData);
    }, [db, domainId]);

    const onUpdateDiscountProductMap = (newMap) => {
        AdvanceManager.updateServiceDataByPath(domainId, "discountProductMap", newMap);
    }

    const onUpdatePaymentMethodMap = (newMap) => {
        AdvanceManager.updateServiceDataByPath(domainId, "paymentMethodMap", newMap);
    }

    return (
        <Container>
            {
                serviceData === null &&
                <CircularProgress />
            }
            {
                serviceData !== null &&
                <Stack
                    mt={2}
                    mb={2}
                    alignItems={"center"}
                    gap={2}
                >
                    <GeneralAdvanceSettingsCard advanceSettings={serviceData ?? {}} onSave={(value) => AdvanceManager.updateServiceData(domainId, value)} />
                    <AdvanceVendorSettingsCard db={db} vendorSettings={serviceData.vendorSettings ?? []} />
                    <Card sx={{width: "800px"}}>
                        <CardContent>
                            <Typography variant="h6" sx={{mb: 2}}>Discount Product Map</Typography>
                            <KeyValueEditor 
                                initialMap={serviceData.discountProductMap ?? {}}
                                onSave={onUpdateDiscountProductMap}
                                keyLabel={"Discount Name"}
                                renderValue={(value, onUpdate) => <ProductSelector domainId={domainId} label={"Discount Product"} selectedProductId={value} onUpdate={onUpdate} />}
                            />
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" sx={{mb: 2}}>Webstore Payment Method Map</Typography>
                            <KeyValueEditor initialMap={serviceData.paymentMethodMap ?? {}} onSave={onUpdatePaymentMethodMap} keyLabel={"Webstore Payment Method"} valueLabel={"Advance Payment Method"} />
                        </CardContent>
                    </Card>
                </Stack>
            }
        </Container>
    );
}

export default AdvanceSettingsPage;