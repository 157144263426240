import * as React from 'react';
import { Box, Button, Card, CardContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Circle } from '@mui/icons-material';
import { round, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getProductOverrides, updateOverride, updatePrepaidCost, getPrepaidCost } from './inksoftCommissionSlice';
import { GridEditInputCell } from '@mui/x-data-grid/components/cell/GridEditInputCell';

// const productOverride = {}
// const commissionMode = "PricePercentage"
// const commissionEnabled = true
// const commissionPercentage = 1
// const commissionAmount = 1
function ProductCard({ product }) {
    const productOverride = useSelector(state => getProductOverrides(state, product.id));
    const prepaidCost = useSelector(state => getPrepaidCost(state, product.id));
    const commissionMode = useSelector(state => state.inksoftCommission.commissionSettings.mode);
    const commissionEnabled = useSelector(state => state.inksoftCommission.commissionSettings.enabled);
    const commissionPercentage = useSelector(state => state.inksoftCommission.commissionSettings.percentage);
    const commissionAmount = useSelector(state => state.inksoftCommission.commissionSettings.amount);
    console.log("rebuild");

    const [matchesDefault, setMatchesDefault] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [style, setStyle] = React.useState(product.styles[0]);
    const [shift, setShift] = React.useState(false);
    const gridRef = useGridApiRef();
    const dispatch = useDispatch();

    const setAllColorsToDefault = () => {
        for (let style of product.styles) {
            for (let size of style.sizes) {
                let defaultSizeRow = [...gridRef.current.getRowModels().values()].filter(r => r.size === size.name)[0];
                if (commissionMode === "PricePercentage") {
                    dispatch(updateOverride({ product, style, size, field: 'percentage', value: defaultSizeRow.percentage, storeValue: commissionPercentage }));
                } else if (commissionMode === "AddedAmount") {
                    dispatch(updateOverride({ product, style, size, field: 'amount', value: defaultSizeRow.commission, storeValue: commissionAmount }));
                } else if (commissionMode === "BasePrice") {
                    dispatch(updateOverride({ product, style, size, field: 'amount', value: round(size.price - defaultSizeRow.basePrice, 2), storeValue: 0 }));
                }
            }
        }

    };

    React.useEffect(() => {
        console.log("rebuild matches default");
        const stylesEqual = (a, b) => {
            for (let i = 0; i < a.sizes.length; i++) {
                let aSize = a.sizes[i];
                let bSize = b.sizes[i];
                if (getRate(a, aSize, productOverride, commissionEnabled, commissionMode, commissionPercentage, commissionAmount) !== getRate(b, bSize, productOverride, commissionEnabled, commissionMode, commissionPercentage, commissionAmount))
                    return false;
            }
            return true;
        }

        // Update matches default
        let newMatchesDefault = [];
        for (let style of product.styles) {
            newMatchesDefault.push(stylesEqual(style, product.styles[0]));
        }
        setMatchesDefault(newMatchesDefault);
    }, [product, productOverride, commissionEnabled, commissionMode, commissionPercentage, commissionAmount]);

    React.useEffect(() => {
        console.log("rebuild columns");
        let newColumns = [
            {
                field: 'size',
                headerName: 'Size',
                width: 100,
                flex: 1,
            },
            {
                field: 'basePrice',
                headerName: 'Base Price',
                type: 'number',
                width: 100,
                editable: commissionMode === "BasePrice",
                valueFormatter: (params) => `$${params.value}`,
                cellClassName: (_) => {
                    if (commissionMode === "BasePrice") {
                        return 'highlighted-col--cell';
                    }
                }
            },
            {
                field: 'commission',
                headerName: 'Commission',
                type: 'number',
                width: 100,
                editable: commissionMode === "AddedAmount",
                renderEditCell: (params) => (
                    <GridEditInputCell {...params} onFocus={(e) => {
                        e.target.type = "text";
                        e.target.setSelectionRange(0, e.target.value.length);
                        e.target.type = "number";
                    }} />
                ),
                valueFormatter: (params) => `$${params.value}`,
                cellClassName: (params) => {
                    if (commissionMode === "AddedAmount") {
                        return params.row.storeCommissionAmount === params.value ? 'highlighted-col--cell' : 'highlighted-diff-col--cell';
                    }
                }
            },
            {
                field: 'price',
                headerName: 'Price',
                type: 'number',
                width: 100,
                valueFormatter: (params) => `$${params.value}`
            },
        ];
        if (commissionMode === "PricePercentage") {
            newColumns.splice(2, 0, {
                field: 'percentage',
                headerName: 'Percentage',
                type: 'number',
                width: 100,
                editable: commissionMode === "PricePercentage",
                valueFormatter: (params) => `${params.value}%`,
                cellClassName: (params) => {
                    if (commissionMode === "PricePercentage") {
                        return params.row.storeCommissionPercentage === params.value ? 'highlighted-col--cell' : 'highlighted-diff-col--cell';
                    }
                }
            });
        }
        setColumns(newColumns);
    }, [commissionMode]);

    React.useEffect(() => {
        console.log("rebuild rows");
        if (style === null) return;

        let newRows = [];
        for (let size of style.sizes) {
            let rate = getRate(style, size, productOverride, commissionEnabled, commissionMode, commissionPercentage, commissionAmount);
            let commission = calculateCommission(size, rate, commissionEnabled, commissionMode);
            newRows.push({
                id: size.id,
                basePrice: round(size.price - commission, 2),
                size: size.name,
                price: size.price,
                percentage: rate,
                commission: commission,
                storeCommissionPercentage: commissionPercentage,
                storeCommissionAmount: commissionAmount,
            });
        }
        setRows(newRows);

        
    }, [product, style, commissionMode, commissionEnabled, commissionPercentage, commissionAmount, productOverride]);

    const handleCellUpdate = (updatedRow, _) => {
        console.log("cell update");
        let shiftOn = shift;
        setShift(false);

        let sizes = style.sizes.filter(s => shiftOn ? true : s.id === updatedRow.id);
        for (let size of sizes) {
            if (commissionMode === "PricePercentage") {
                dispatch(updateOverride({ product, style, size, field: 'percentage', value: updatedRow.percentage, storeValue: commissionPercentage }));
            } else if (commissionMode === "AddedAmount") {
                dispatch(updateOverride({ product, style, size, field: 'amount', value: updatedRow.commission, storeValue: commissionAmount }));
            } else if (commissionMode === "BasePrice") {
                dispatch(updateOverride({ product, style, size, field: 'amount', value: round(size.price - updatedRow.basePrice, 2), storeValue: 0 }));
            }
        }
        return updatedRow;
    };

    const handleCellClick = (params, event) => {
        if (params.isEditable && gridRef.current.getCellMode(params.id, params.field) === "view") {
            gridRef.current.startCellEditMode({ id: params.row.id, field: params.field });
        }
    };

    const handleEditStop = (params, event) => {
        if (event.shiftKey) {
            setShift(true);
        }
    };

    if (style === null) {
        return (<></>);
    }

    return (
        <Card sx={{ width: 800 }}>
            <CardContent>
                <Stack>
                    <Typography textAlign="center" variant="h5" component="div">
                        {product.name}
                    </Typography>
                    <Typography textAlign="center" variant="body1" component="div">
                        {product.sku}
                    </Typography>
                    <Stack
                        direction={"row"}
                        mt={2}
                    >
                        <Box sx={{ paddingRight: '16px', width: '200px', display: 'flex', justifyContent: 'center' }}>
                            <Stack
                                gap={1}
                            >
                                {
                                    style.sides.map((side, idx) =>
                                        <img key={idx} src={side.imageUrl} alt={product.name} style={{ maxHeight: "200px", maxWidth: "200px" }} />
                                    )
                                }
                            </Stack>
                        </Box>
                        <Stack
                            direction={"column"}
                            flexGrow={1}
                        >
                            <Stack
                                direction={"row"}
                            >
                                <FormControl sx={{ width: '300px' }}>
                                    <InputLabel id="color-label">Color</InputLabel>
                                    <Select
                                        labelId="color-label"
                                        id="color-select"
                                        value={product.styles.indexOf(style)}
                                        label="Color"
                                        onChange={(event) => setStyle(product.styles[event.target.value])}
                                    >
                                        {product.styles.map((c, idx) =>
                                            <MenuItem key={idx} value={idx}>
                                                <Box sx={{ display: 'flex' }}>
                                                    {c.styleName + (idx === 0 ? " (Default)" : "")}
                                                    {!matchesDefault[idx] &&
                                                        <Circle sx={{ marginLeft: '8px' }} color="warning" size='small' />
                                                    }
                                                </Box>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {product.styles.indexOf(style) === 0 &&
                                    <Box margin={1}>
                                        <Button variant="outlined" disabled={matchesDefault.every(v => v)} onClick={setAllColorsToDefault}>COPY TO ALL COLORS</Button>
                                    </Box>
                                }
                            </Stack>
                            <Box
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    '& .highlighted-col--cell': {
                                        backgroundColor: 'rgba(156, 39, 176, 0.08)',
                                        fontWeight: '600',
                                    },
                                    '& .highlighted-diff-col--cell': {
                                        backgroundColor: 'rgba(156, 39, 176, 0.3)',
                                        fontWeight: '600',
                                    },
                                }}
                            >
                                <DataGrid
                                    apiRef={gridRef}
                                    autoHeight
                                    experimentalFeatures={{ 'newEditingApi': true }}
                                    rows={rows}
                                    columns={columns}
                                    hideFooter
                                    onProcessRowUpdateError={(error) => console.error(error)}
                                    processRowUpdate={handleCellUpdate}
                                    onCellClick={handleCellClick}
                                    onCellEditStop={handleEditStop}
                                />
                            </Box>
                            <FormControlLabel
                                control={
                                    <Switch checked={prepaidCost} onChange={(_, checked) => dispatch(updatePrepaidCost({product, value: checked}))} name="active" />
                                }
                                label="Prepaid Cost"
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

const calculateCommission = (size, rate, commissionEnabled, commissionMode) => {
    if (!commissionEnabled) return 0;

    if (commissionMode === "PricePercentage") {
        return round((size.price * rate) / 100, 2);
    } else if (commissionMode === "AddedAmount" || commissionMode === "BasePrice") {
        return rate;
    } else {
        return 0;
    }
};

const getRate = (style, size, productOverride, commissionEnabled, commissionMode, commissionPercentage, commissionAmount) => {
    if (!commissionEnabled) return 0;

    if (commissionMode === "PricePercentage") {
        return get(productOverride, [`id${style.styleId}`, `id${size.id}`, 'percentage'], commissionPercentage);
    } else if (commissionMode === "AddedAmount" || commissionMode === "BasePrice") {
        return get(productOverride, [`id${style.styleId}`, `id${size.id}`, 'amount'], commissionAmount);
    } else {
        return 0;
    }
}

export default ProductCard;