import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";

async function getServiceDoc(domainId, service) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", service));
    return serviceDoc.data();
}

function getServiceDocSnapshot(domainId, service, setServiceDoc) {
    const db = getFirestore();
    let serviceRef = doc(db, "domains", domainId, "services", service);
    const unsubscribe = onSnapshot(serviceRef, (doc) => {
        setServiceDoc(doc?.data() ?? {});
    }, (error) => console.log(error));
    return unsubscribe;
}

export { getServiceDoc, getServiceDocSnapshot}