import React from "react";
import * as SyncoreExportManager from "../managers/SyncoreExportManager";
import { Autocomplete, Container, Card, TextField, Stack, CircularProgress, CardContent, Typography, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import JobCard from "./JobCard";

function SyncoreExportPage() {
    const { domainId } = useParams();
    let [serviceData, setServiceData] = React.useState(null);
    let [jobs, setJobs] = React.useState(null);
    let [businessName, setBusinessName] = React.useState("MaryJean Kidd");

    React.useEffect(() => {
        setJobs(null);
        const getServiceData = async () => {
            return setServiceData(await SyncoreExportManager.getServiceDoc(domainId));
        };
        getServiceData()
        return SyncoreExportManager.getSyncoreJobs(domainId, businessName, setJobs);
    }, [domainId, businessName]);
    
    if (serviceData === null || jobs === null)
        return (<CircularProgress />)

    return (
        <Container>
            <Card sx={{mb: 1, mt: 1}}>
                <CardContent>
                    <Typography sx={{mb: 2}} variant="h5">Filter</Typography>
                    <Autocomplete
                        id="business-name"
                        disableClearable
                        value={businessName}
                        options={serviceData.businesses}
                        getOptionLabel={(o) => o}
                        renderInput={(params) => {
                            return (<TextField required {...params} label="Business Name" />);
                        }}
                        onChange={(event, value) => {
                            setBusinessName(value);
                        }}
                    />
                    <Alert sx={{mt: 1}} severity="info">Additional data can be made available upon request.</Alert>
                </CardContent>
            </Card>
            <Stack
                gap={1}
            >
                {jobs.map(j => <JobCard job={j} />)}
            </Stack>
        </Container>
    );
}

export default SyncoreExportPage;