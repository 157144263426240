import React, { useMemo } from "react";
import * as AdvanceManager from "../managers/AdvanceManager";
import { debounce } from "lodash";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const AccountSelector = ({ domainId, selectedAccountRef, label, onUpdate }) => {
    const [accounts, setAccounts] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    
    const getAdvanceAccounts = useMemo(() => 
        debounce(
            async (input) => {
                try {
                    setLoading(true);
                    setError(null);
                    const accounts = await AdvanceManager.getAccounts(domainId, input);
                    setAccounts(accounts);
                } catch (e) {
                    console.log(e);
                    setError(e.toString());
                } finally {
                    setLoading(false);
                }
            }, 400
        ), []
    );

    React.useEffect(() => {
        if (selectedAccountRef && (!accounts || !accounts.find((p) => p.id === selectedAccountRef?.id))) {
            setAccounts([{...selectedAccountRef}]);
        }
    }, [selectedAccountRef, accounts]);

    React.useEffect(() => {
        if (selectedAccountRef == null) {
            setAccounts(null);
        }
    }, [selectedAccountRef]);

    return (
        <Autocomplete
            value={accounts?.find((p) => p.id === selectedAccountRef?.id) ?? null}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={accounts ?? []}
            loading={loading}
            filterOptions={(x) => x}
            onChange={(_, value) => {
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate({
                        "id": value.id,
                        "name": value.name
                    });
                }
            }}
            onInputChange={(_, newInputValue, reason) => {
                if (reason === "input" && newInputValue.length > 2) {
                    getAdvanceAccounts(newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default AccountSelector;