import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Card, CardContent } from '@mui/material';

function Row(props) {
    const { nestedLine, last } = props;
    const [open, setOpen] = React.useState(true);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {nestedLine.description}
                </TableCell>
                <TableCell>{nestedLine.taxability}</TableCell>
                <TableCell>{nestedLine.sku}</TableCell>
                <TableCell>{nestedLine.supplier?.name ?? "N/A"}</TableCell>
            </TableRow>
            {
                nestedLine.children.length > 0 &&
                <TableRow sx={last ? { '& > *': { borderBottom: 'unset' } } : undefined}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="child-lines">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                            <TableCell align="right">Qty</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {nestedLine.children.map((line, idx) => (
                                            <>
                                                <TableRow key={idx}>
                                                    <TableCell component="th" scope="row" colSpan={2}>
                                                        {line.type}
                                                    </TableCell>
                                                    <TableCell>{line.description}</TableCell>
                                                    <TableCell align="right">{(line.cost_value ?? 0) > 0 ? `$${line.cost_value?.toFixed(2)}` : ""}</TableCell>
                                                    <TableCell align="right">{(line.price_value ?? 0) > 0 ? `$${line.price_value?.toFixed(2)}` : ""}</TableCell>
                                                    <TableCell align="right">{line.quantity}</TableCell>
                                                </TableRow>
                                                {
                                                    line.children.map((subLine, j) => (
                                                        <TableRow key={j}>
                                                            { j === 0 && <TableCell rowSpan={line.children.length + 1} />}
                                                            {/* <TableCell /> */}
                                                            <TableCell component="th" scope="row">
                                                                {subLine.type}
                                                            </TableCell>
                                                            <TableCell>{subLine.description}</TableCell>
                                                            <TableCell align="right">{(subLine.cost_value ?? 0) > 0 ? `$${subLine.cost_value?.toFixed(2)}` : ""}</TableCell>
                                                            <TableCell align="right">{(subLine.price_value ?? 0) > 0 ? `$${subLine.price_value?.toFixed(2)}` : ""}</TableCell>
                                                            <TableCell align="right">{(subLine.quantity ?? 0) > 0 ? subLine.quantity : ""}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                {
                                                    idx !== nestedLine.children.length - 1 && line.children.length > 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={6} height={20} />
                                                    </TableRow>
                                                }
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment>
    );
}

function LinesInternalTable({ nestedLines }) {
    return (
        <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>Description</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Sku</TableCell>
                    <TableCell>Vendor</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {nestedLines.map((nestedLine, idx) => (
                    <Row key={idx} nestedLine={nestedLine} last={idx === nestedLines.length - 1} />
                ))}
            </TableBody>
        </Table>
    );
}

function LinesTable(props) {
    let { orderId, nestedLines } = props;

    return (
        <Card variant='outlined'>
            <CardContent>
                <Typography variant={'h6'} textAlign={'center'}>{orderId}</Typography>
            </CardContent>
            <LinesInternalTable nestedLines={nestedLines} />
        </Card>
    );
}

export { LinesTable, LinesInternalTable };