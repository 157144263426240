import React from "react";
import { Alert, Button, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { SERVICE_URL } from "../App";
import { useParams } from "react-router-dom";

function ConsolidatedPOCard(props) {
    let { domainId } = useParams();
    let [jobValue, setJobValue] = React.useState("");
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState(null);
    
    const createCPOTask = async (jobId) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append("jobId", jobId);
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            let response = await axios.post(
                `${SERVICE_URL}/syncore/createConsolidatedPOTask`, 
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    params: {
                        "domainId": domainId
                    }
                }
            );
            if (response.status !== 200) {
                console.error(response.data);
            }
            setError(null);
        } catch(e) {
            setError(e.toString())
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card sx={{width: "600px", marginLeft: "auto", marginRight: "auto"}}>
            <CardContent>
                <Stack
                    direction={"column"}
                    gap={1}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Typography variant="h5" sx={{marginBottom: 2}} textAlign={"center"}>Create Consolidated PO</Typography>
                    <TextField
                        id="outlined-number"
                        label="Job Number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={jobValue}
                        onChange={(event) => setJobValue(event.target.value)}
                    />
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        onClick={() => {createCPOTask(jobValue); setJobValue("")}}
                    >
                        Create
                    </LoadingButton>
                    {error !== null &&
                        <Alert sx={{margin: "16px"}} severity="error">{error}</Alert>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
}

export default ConsolidatedPOCard;