import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";
import React from "react";


const PayoutCard = ({ payout }) => {
    const handleViewBill = () => {
        let url = `https://app.qbo.intuit.com/app/bill?txnId=${payout.qboBillId}`;
        window.open(url, "_blank");
    }

    return (
        <Stack sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
            <Stack gap={2} direction={"row"}>
                <Card variant="outlined" sx={{flexGrow: 1}}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Recipient</Typography>
                        <Typography variant="body1">Account: {payout.recipient.accountRef.name}</Typography>
                        <Typography variant="body1">Contact Name: {payout.recipient.contactRef.name}</Typography>
                        <Typography variant="body1">Contact Email: {payout.recipient.contactRef.email}</Typography>
                        <Typography variant="body1">Payment Type: {payout.recipient.paymentType}</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{flexGrow: 1}}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Recipient Billing Address</Typography>
                        <Typography variant="body1">{payout.recipient.billingAddress.address1}</Typography>
                        {
                            !!!payout.recipient.billingAddress.address2 && <Typography variant="body1">{payout.recipient.billingAddress.address2}</Typography>
                        }
                        <Typography variant="body1">{payout.recipient.billingAddress.city}, {payout.recipient.billingAddress.state} {payout.recipient.billingAddress.postalCode}</Typography>
                        <Typography variant="body1">{payout.recipient.billingAddress.country}</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{flexGrow: 1}}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Summary</Typography>
                        {
                            payout.labels.map((label) => {
                                return <Typography key={label} variant="body1">{label} - ${payout.totals[label].toFixed(2)}</Typography>;
                            })
                        }
                        <Typography variant="body1">Total - ${payout.totals["total"].toFixed(2)}</Typography>
                        {
                            payout.recipient.paymentType === "Check" && payout.qboBillId &&
                            <Button variant="outlined" onClick={handleViewBill} sx={{mt: 1}}>View Bill</Button>
                        }
                        {
                            payout.recipient.paymentType === "Inksoft Gift Card" &&
                            <Typography variant="body1">Gift Code: {payout.inksoftGiftCardCode}</Typography>
                        }
                    </CardContent>
                </Card>
            </Stack>
            <TableContainer component={Paper} variant="outlined">
                <Typography variant="h6" m={2} mb={1}>Products</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item No</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Divisions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payout.details.map((item, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{item.itemNo}</TableCell>
                                <TableCell>{dayjs(item.startDate).format("MM/DD/YYYY")}</TableCell>
                                <TableCell>{dayjs(item.endDate).format("MM/DD/YYYY")}</TableCell>
                                <TableCell>{item.divisions.join(", ")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} variant="outlined">
                <Typography variant="h6" m={2} mb={1}>Divisions</Typography>
                <Table> 
                    <TableHead>
                        <TableRow>
                            <TableCell>Division</TableCell>
                            {
                                payout.labels.map((label) => <TableCell>{label}</TableCell>)
                            }
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payout.divisionTotals.map((divisionData, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{divisionData.division}</TableCell>
                                {
                                    payout.labels.map((label) => <TableCell>${(divisionData[label] ?? 0).toFixed(2)}</TableCell>)
                                }
                                <TableCell>${divisionData.total.toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <LineItemsGrid payout={payout} />
        </Stack>
    );
}

const createCSV = (payout) => {
    let csv = "Order No,Description,Order Date,Division";
    payout.labels.forEach((label) => {
        csv += `,${label}`;
    });
    csv += ",Total\n";
    payout.lineItems.forEach((item) => {
        csv += `${item.no},${item.description},${dayjs(item.orderDate).format("MM/DD/YYYY")},${item.division}`;
        payout.labels.forEach((label) => {
            csv += `,${item[label]}`;
        });
        csv += `,${item.total}\n`;
    });
    return csv;
}

const downloadCSV = (payout) => {
    let csv = createCSV(payout);
    let blob = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `payout-${payout.id}.csv`);
    link.click();
    link.remove();
}

const LineItemsGrid = ({ payout }) => {
    const columns = [
        { field: 'no', headerName: 'Order No', width: 85, align: 'right', headerAlign: 'right' },
        { field: 'description', headerName: 'Description', width: 300 },
        // { field: 'customer', headerName: 'Customer', width: 200 },
        {
            field: 'orderDate',
            headerName: 'Order Date',
            width: 150,
            valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY")
        },
        { field: 'division', headerName: 'Division', width: 150 },
        ...payout.labels.map((label) => ({
            field: label,
            headerName: label,
            width: 150,
            align: 'right',
            headerAlign: 'right',
            valueGetter: (params) => params.row[label],
            valueFormatter: (params) => `$${(params.value ?? 0).toFixed(2)}`
        })),
        { 
            field: 'total',
            headerName: 'Total',
            width: 150,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => `$${(params.value ?? 0).toFixed(2)}`
        },
    ];

    const rows = payout.lineItems.map((item, index) => {
        return {
            id: index,
            ...item
        };
    });

    return (
        <TableContainer component={Paper} variant="outlined">
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h6" m={2} mb={1}>Line Items</Typography>
                <Button variant="text" onClick={() => downloadCSV(payout)} sx={{mr: 1}}>Download CSV</Button>
            </Stack>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                sx={{border: 0}}
                localeText={{ noRowsLabel: "No line items" }}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    },
                }}
                pageSizeOptions={[10, 20, 100]}
            />
        </TableContainer>
    );
}

export default PayoutCard;
