import axios from "axios";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getFirestore, onSnapshot, or, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { SERVICE_URL } from "../App";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "shipstation"));
    return serviceDoc.data();
}

async function importSyncoreSO(domainId, jobId, soNum) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("soNum", soNum);
    let response = await axios.post(
        `${SERVICE_URL}/shipping/importSyncoreSO`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.order.id;
}

async function createLabel(domainId, orderId, carrierCode, serviceCode, pounds, length, width, height, printerId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("orderId", orderId);
    formData.append("carrierCode", carrierCode);
    formData.append("serviceCode", serviceCode);
    formData.append("pounds", pounds);
    formData.append("length", length);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("print", true);
    formData.append("printerId", printerId);
    console.log(formData.toString());
    await axios.post(
        `${SERVICE_URL }/shipping/createLabel`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}

function getPendingShipments(domainId, setShipments) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "shipping", "shipments"), or(
        where("processed", "==", false),
        where("processedVoid", "==", false)
    ));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const shipments = querySnapshot.docs.map((doc) => doc.data());
        setShipments(shipments);
    }, (error) => console.log(error));
    return unsubscribe;
}

function updateShipment(domainId, id, data) {
    const db = getFirestore();
    updateDoc(doc(db, "domains", domainId, "services", "shipping", "shipments", id), data);
}

async function updateShipments(domainId, ids, data) {
    const db = getFirestore();
    const batch = writeBatch(db);
    ids.forEach((id) => {
        const shipmentRef = doc(db, "domains", domainId, "services", "shipping", "shipments", id);
        batch.update(shipmentRef, data);
    });
    await batch.commit();
}

export { getServiceDoc, importSyncoreSO, createLabel, getPendingShipments, updateShipment, updateShipments };