import { Card, CardContent, Typography } from "@mui/material";

function ContactCard({ advanceContact }) {
    return (
        <Card sx={{backgroundColor: advanceContact.id ? "#ccffcc" : "#ffcfcc"}} variant="outlined">
            <CardContent>
                <Typography variant={'h6'} textAlign={'center'}>Contact</Typography>
                <Typography variant="body1" textAlign={"center"}>{advanceContact.firstName} {advanceContact.lastName}</Typography>
                <Typography variant="body1" textAlign={"center"}>{advanceContact.email}</Typography>
                <Typography variant="body1" textAlign={"center"}>{advanceContact.email}</Typography>
                <Typography variant="body1" textAlign={"center"}>Sales Rep: {advanceContact.salesRep.name}</Typography>
            </CardContent>
        </Card>
    )
}

export default ContactCard;
