import React from "react";
import { Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { SERVICE_URL } from "../App";
import { useParams } from "react-router-dom";

function JobToOrdersCard(props) {
    const { domainId } = useParams();
    let [jobValue, setJobValue] = React.useState("");
    let [loading, setLoading] = React.useState(false);

    const onClick = async (id) => {
        setLoading(true);
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        let response = await axios.get(
            `${SERVICE_URL}/syncore/getSalesOrders`, 
            {
                params: {
                    "domainId": domainId,
                    "jobId": id
                },
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            }
        );
        let sos = response.data.data.salesOrders;
        let orderIds = sos.filter(so => so.total_value > 0).map(so => so.customer_order_number);
        let url = `https://logotologist.com/logotology_designer/admin/ordermanager/orders?Status=Open&Index=0&MaxResults=25&OrderByDirection=Descending&OrderBy=DateCreated&SearchText=${orderIds.join(" ")}`;
        window.open(url, "_blank");
        if (response.status !== 200) {
            console.error(response.data);
        } else {
            setJobValue("");
        }
        setLoading(false);
    }

    return (
        <Card sx={{width: "600px", marginLeft: "auto", marginRight: "auto"}}>
            <CardContent>
                <Stack
                    direction={"column"}
                    gap={1}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Typography variant="h5" sx={{marginBottom: 2}} textAlign={"center"}>Syncore Job to Inksoft Orders</Typography>
                    <TextField
                        id="outlined-number"
                        label="Job Number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={jobValue}
                        onChange={(event) => setJobValue(event.target.value)}
                    />
                    <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<ExitToApp />}
                        variant="contained"
                        onClick={() => onClick(jobValue)}
                    >
                        Go
                    </LoadingButton>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default JobToOrdersCard;