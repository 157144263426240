import { ExitToApp } from "@mui/icons-material";
import { Card, CardContent, Typography, Table, TableHead, TableRow, TableCell, Checkbox, TableContainer, TableBody, Toolbar, alpha, Button, IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import ImportDialog from "./ImportDialog";

function ImportStoreCard(props) {
    let { store, onImport, pendingOrderMap, syncoreStoreData, hideImported } = props; 
    let [orders, setOrders] = React.useState(store.orders);
    let [dialogOpen, setDialogOpen] = React.useState(false);

    const onSelect = (orderId) => {
        let order = store.orders.find(o => o.orderId === orderId);
        order.selected = !order.selected;
        setOrders([...orders]);
    };

    const areAllSelected = () => {
        return store.orders.filter((o) => o.selected).length === (hideImported ? store.orders.filter((o) => pendingOrderMap[o.orderId] !== true).length : store.orders.length);
    }

    const onSelectAll = () => {
        let selected = areAllSelected();
        for (let order of orders) {
            if (!hideImported || pendingOrderMap[order.orderId] !== true) {
                order.selected = !selected;
            }
        }
        setOrders([...orders]);
    };

    const handleClose = (submit, jobId, clientId) => {
        setDialogOpen(false);
        if (submit) {
            onImport(store.storeId, jobId, clientId);
        }
    }

    React.useEffect(() => {
        for (let order of orders) {
            order.selected = false;
        }
        setOrders([...orders]);
    }, [hideImported, orders]);

    let selected = orders.filter((o) => o.selected).length;

    return (
        <>
            {
                dialogOpen &&
                <ImportDialog open={dialogOpen} handleClose={handleClose} syncoreStoreData={syncoreStoreData} orders={orders.filter(o => o.selected)} />
            }
            <Card key={store.storeId}>
                <CardContent>
                    <Typography variant="h5" textAlign={"center"}>{store.name}</Typography>
                    <Typography variant="body1" textAlign={"center"}>{store.storeId}</Typography>
                </CardContent>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        ...(selected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                        }),
                    }}
                    >
                    {selected > 0 ? (
                        <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                        >
                        {selected} selected
                        </Typography>
                    ) : (
                        <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        >
                        Orders
                        </Typography>
                    )}
                    {selected > 0 && (
                        <Button variant="contained" onClick={() => setDialogOpen(true)}>
                            Import
                        </Button>
                    )}
                </Toolbar>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={areAllSelected()}
                                    onChange={onSelectAll}
                                    inputProps={{
                                        'aria-label': 'select all orders',
                                    }}
                                />
                            </TableCell>
                                <TableCell>Order ID</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell>View Order</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.filter(o => !hideImported || pendingOrderMap[o.orderId] !== true).map((o) => (
                                <TableRow
                                    key={o.orderId}
                                    hover
                                    onClick={(event) => onSelect(o.orderId)}
                                    role="checkbox"
                                    selected={o.selected}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: pendingOrderMap[o.orderId] === true ? red[50] : undefined }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={o.selected}
                                            inputProps={{
                                                // 'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {o.orderId}
                                    </TableCell>
                                    <TableCell>{o.firstName}</TableCell>
                                    <TableCell>{o.lastName}</TableCell>
                                    <TableCell>{o.dateCreated.toLocaleString()}</TableCell>
                                    <TableCell align="right">{o.totalQuantity}</TableCell>
                                    <TableCell align="right">{"$" + o.grandTotal.toFixed(2)}</TableCell>
                                    <TableCell>
                                        <IconButton href={`https://logotologist.com/logotology_designer/admin/ordermanager/order/${o.guid}`} target="_blank">
                                            <ExitToApp/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    );
}

export default ImportStoreCard;