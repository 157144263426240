import axios from "axios";
import { getAuth } from "firebase/auth";
import { SERVICE_URL } from "../App";

async function getProducts(domainId, storeUrl, addDecorationInfo=false, addInventory=false, addCommission=false, addAdditionalCosts=false) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/inksoft/getProducts`,
        {
            params: {
                "domainId": domainId,
                "storeUrl": storeUrl,
                "addDecorationInfo": addDecorationInfo,
                "addInventory": addInventory,
                "addCommission": addCommission,
                "addAdditionalCosts": addAdditionalCosts
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    let data = response.data;
    return data.data.products;
}

export { getProducts };


