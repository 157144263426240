import React, { useMemo } from "react";
import * as AdvanceManager from "../managers/AdvanceManager";
import { debounce } from "lodash";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const ProductSelector = ({ domainId, selectedProductId, label, onUpdate }) => {
    const [products, setProducts] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingSingleProduct, setLoadingSingleProduct] = React.useState(false);
    const [error, setError] = React.useState(null);
    
    const getAdvanceProducts = useMemo(() => 
        debounce(
            async (input) => {
                try {
                    setLoading(true);
                    setError(null);
                    const products = await AdvanceManager.getProducts(domainId, input);
                    setProducts(products);
                } catch (e) {
                    console.log(e);
                    setError(e.toString());
                } finally {
                    setLoading(false);
                }
            }, 400
        ), []
    );

    React.useEffect(() => {
        if (selectedProductId && (!products || !products.find((p) => p.id === selectedProductId))) {
            setLoadingSingleProduct(true);
            AdvanceManager.getProduct(domainId, selectedProductId).then((product) => {
                setError(null);
                setProducts([product]);
                setLoadingSingleProduct(false);
            }).catch((e) => {
                console.log(e);
                setError(e.toString());
                setLoadingSingleProduct(false);
            });
        }
    }, [selectedProductId, products]);

    React.useEffect(() => {
        if (selectedProductId == null) {
            setProducts(null);
        }
    }, [selectedProductId]);

    return (
        <Autocomplete
            value={products?.find((p) => p.id === selectedProductId) ?? null}
            getOptionLabel={(option) => `${option?.name} (${option?.itemNo})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={products ?? []}
            loading={loading}
            filterOptions={(x) => x}
            onChange={(_, value) => {
                console.log("New Value: ", value);
                if (value == null) {
                    onUpdate(null);
                } else {
                    onUpdate(value.id);
                }
            }}
            onInputChange={(_, newInputValue, reason) => {
                if (reason === "input" && newInputValue.length > 2) {
                    getAdvanceProducts(newInputValue);
                }
            }}
            disabled={loadingSingleProduct}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        startAdornment: (
                            <React.Fragment>
                                {loadingSingleProduct ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default ProductSelector;